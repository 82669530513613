import { useEffect, useRef } from "react";

export default function OutsideClickHandler({
  children,
  coverOrigin = true,
  onClickOutside = () => {},
  ...props
}) {
  const wrapperRef = useRef(null);
  const latestOnClickOutside = useRef(onClickOutside);
  useEffect(() => {
    latestOnClickOutside.current = onClickOutside;
  }, [onClickOutside]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (latestOnClickOutside.current) {
        latestOnClickOutside.current();
      }
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      {coverOrigin ? (
        <div className="absolute top-0 left-0 h-full w-full"></div>
      ) : (
        ""
      )}
      <div ref={wrapperRef} {...props}>
        {children}
      </div>
    </>
  );
}
