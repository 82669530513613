import React from "react";
import ReactDOM from "react-dom";

const BlockLoader = ({ open = false, borderColor = "white" }) => {
  const loader = (
    <div className="absolute top-0 left-0 z-10 h-full w-full flex items-center justify-center bg-slate-600 bg-opacity-50">
      <span
        className="loader"
        style={{
          borderTopColor: borderColor,
        }}
      ></span>
    </div>
  );

  return open ? loader : null;
};

export default BlockLoader;
