import React from "react";

const ChevronDownIcon = ({ fill, size, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 16}
      height={size || 16}
      fill={fill || "#fff"}
      className={className}
      viewBox="0 0 16 16" strokeWidth={2} stroke={fill || '#fff'}>
      <path
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};

export default ChevronDownIcon;
