export default function BoostIcon({ width = 28, height = 28 }) {
  return (
    <svg
      id="star"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g id="Group" transform="translate(7.024 4.74)">
        <path
          id="Vector"
          d="M10.931,1.339l1.645,3.29a2.053,2.053,0,0,0,1.318.98l2.975.49c1.9.315,2.345,1.692.98,3.068l-2.322,2.322a2.014,2.014,0,0,0-.478,1.692l.665,2.87c.525,2.263-.688,3.15-2.683,1.96l-2.788-1.657a1.981,1.981,0,0,0-1.843,0L5.611,18.01c-2,1.178-3.208.3-2.683-1.96l.665-2.87a2.036,2.036,0,0,0-.478-1.692L.793,9.167C-.572,7.8-.129,6.425,1.773,6.1l2.975-.49a2.015,2.015,0,0,0,1.318-.98l1.645-3.29C8.586-.446,10.033-.446,10.931,1.339Z"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
      <path
        id="Vector-2"
        data-name="Vector"
        d="M7,0H0"
        transform="translate(2.333 5.833)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M3.5,0H0"
        transform="translate(2.333 22.167)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M1.167,0H0"
        transform="translate(2.333 14)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M0,0H28V28H0Z"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}
