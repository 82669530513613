import { twMerge } from "tailwind-merge";

export default function Divider({
  orientation = "horizontal",
  className = "",
}) {
  return (
    <div
      className={twMerge(`bg-theme-gray ${
        orientation === "horizontal" ? "w-full h-1" : "w-1 h-full"
      }`, className)}
    />
  );
}
