import { twJoin } from "tailwind-merge";
import { useState } from "react";
import OutsideClickHandler from "./OutsideClickHandler";

export default function Tooltip({ message, children, tooltip = true }) {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div
      className="relative flex justify-center items-start cursor-pointer"
      onClick={() => (tooltip ? setShowTooltip(true) : null)}
    >
      {children}
      <OutsideClickHandler
        onClickOutside={() => setShowTooltip(false)}
        className={twJoin(
          "absolute bottom-8 w-max flex justify-center transition-all cursor-default ease-in-out origin-bottom rounded bg-theme-blue shadow-md p-2 text-xs text-white border-2 border-[#F29423] max-w-[230px]",
          showTooltip ? "scale-100" : "scale-0"
        )}
      >
        <div className="absolute top-full z-10">
          <svg
            width="20"
            height="10"
            viewBox="0 0 20 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0L10 10L20 0H0Z" fill="#F29423" />
            <path d="M3 0L10 7L17 0H3Z" fill="#004E99" />
          </svg>
        </div>
        {message}
      </OutsideClickHandler>

      {showTooltip && (
        <div className="absolute top-0 left-0 h-full w-full"></div>
      )}
    </div>
  );
}
