import { useEffect } from "react";
import { addQna, setQna, updateSingleQna } from "redux/reducers/qna";
import { useUtilities, axios } from "helper";
import { useMutation, useQuery } from "@tanstack/react-query";

async function getQna() {
  return axios
    .get("qna")
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetQna() {
  return useQuery(["qna"], getQna);
}

export function useQna() {
  const { data, isLoading, isError, error } = useGetQna();
  const {
    dispatch,
    qna: { qna, loaded },
  } = useUtilities();

  useEffect(() => {
    if (data && !loaded) {
      dispatch(setQna(data.data));
    }
  }, [data]);

  return { data: qna, isLoading: isLoading || !loaded, isError, error };
}

async function createQna({ data, callback }) {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  return await axios
    .post("qna", data)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useAddQna() {
  const { notification, dispatch } = useUtilities();
  return useMutation(createQna, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addQna(data.data));
        notification({
          message: "Q&A added successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to add Q&A",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to add Q&A",
        type: "error",
      });
    },
  });
}

async function updateQna(data) {
  const qnaId = data.id;
  delete data.id;
  return await axios
    .put("qna/" + qnaId, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateQna() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateQna, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleQna(data.data));
        notification({
          message: "Q&A updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to update Q&A",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to update Q&A",
        type: "error",
      });
    },
  });
}
