import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    majorAcademy: [],
    loaded: false,
};

const majorAcademySlice = createSlice({
    name: "majorAcademy",
    initialState,
    reducers: {
        setMajorAcademy(state, action) {
            state.majorAcademy = action.payload;
            state.loaded = true;
        },
        addMajorAcademy(state, action) {
            state.majorAcademy.push(action.payload);
        },
        updateSingleMajorAcademy(state, action) {
            state.majorAcademy = state.majorAcademy.map((majorAcademy) =>
                majorAcademy._id === action.payload._id ? action.payload : majorAcademy
            );
        },
        deleteMajorAcademy(state, action) {
            state.majorAcademy = state.majorAcademy.filter(
                (majorAcademy) => majorAcademy._id !== action.payload
            );
        },
    },
});

export const {
    setMajorAcademy,
    addMajorAcademy,
    updateSingleMajorAcademy,
    deleteMajorAcademy,
} = majorAcademySlice.actions;

export default majorAcademySlice.reducer;