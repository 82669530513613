import { useMajors } from "api/major";
import MajorItem from "../allMajors/MajorItem";
import { ChevronRightIcon } from "assets/icons";
import { Link } from "react-router-dom";
import { SearchInput } from "common/Input";
import { useEffect, useState } from "react";

export default function ArchivedMajors() {
  const { data: major, isLoading: majorsLoading } = useMajors();
  const [search, setSearch] = useState("");
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchQueryResults, setSearchQueryResults] = useState([]);

  useEffect(() => {
    if (major.length > 0) {
      setSearchQueryResults(
        major.filter(
          (item) =>
            item.title.toLowerCase().includes(search.toLowerCase()) &&
            item.is_archived
        )
      );
    }
  }, [search]);

  return (
    <>
      <div className="flex items-center justify-between">
        <Link
          to="/majors"
          className="flex items-center gap-4 text-theme-blue text-xl font-bold"
        >
          <ChevronRightIcon />
          Back
        </Link>
        <div className="relative">
          <SearchInput
            placeholder="Search Major"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => setTimeout(() => setSearchFocused(false), 400)}
          />
          {searchFocused && search.trim() && (
            <div className="absolute top-12 left-0 w-full bg-white shadow rounded-xl z-10 max-h-72 custom-scroll">
              {searchQueryResults.length > 0 ? (
                searchQueryResults.map((item) => (
                  <Link
                    to={`/majors/${item._id}`}
                    className="flex items-center justify-between px-4 py-2 border-b border-[#E4E4E4] cursor-pointer hover:bg-[#F7F7F7]"
                  >
                    <p className="text-base">{item.title}</p>
                    <p className="text-xs">
                      {item.related_categories.length > 1
                        ? item.related_categories[0] + " +2 others"
                        : item.related_categories[0]}
                    </p>
                  </Link>
                ))
              ) : (
                <div className="flex items-center justify-between px-4 py-2 border-b border-[#E4E4E4] cursor-pointer hover:bg-[#F7F7F7]">
                  <p className="text-base">No results found</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="mt-10 flex flex-col gap-5">
        {major
          .filter((_) => _.is_archived)
          .map((item) => (
            <MajorItem
              key={item._id}
              id={item._id}
              title={item.title}
              active={item.active || !item.is_archived}
              applications={item.applications}
              views={item.views}
              createdAt={item.createdAt}
              is_deleted={item.is_deleted}
              quota={item.maximum_quota}
              related_categories={item.related_categories}
              cover_image={item.cover_image}
              is_archived={item.is_archived}
            />
          ))}
      </div>
    </>
  );
}
