import { AddCircleIcon } from "assets/icons";
import { FilledButton } from "common/Button";
import SearchFilter from "common/SearchFilter";
import SingleInstitute from "./SingleInstitute";
import { Link } from "react-router-dom";
import { useInstitutions } from "api/institution";
import { setSelectedInstitution } from "redux/reducers/institutions";
import { useUtilities } from "helper";
import SkeletonInstitute from "./SkeletonInstitute";

export default function Institutions() {
  const {
    dispatch,
    institutions: { selectedInstitution },
  } = useUtilities();
  const { data: institutions, isLoading } = useInstitutions();

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Link to="/institutions/add">
            <FilledButton className={"px-4"}>
              <AddCircleIcon fill="white" />
              Add Institution
            </FilledButton>
          </Link>
          {selectedInstitution && (
            <p
              className="text-theme-yellow underline text-[21px] font-medium cursor-pointer"
              onClick={() => dispatch(setSelectedInstitution(null))}
            >
              Switch back to Nexme
            </p>
          )}
        </div>
        <SearchFilter />
      </div>
      <div className="flex gap-8 flex-wrap my-10">
        {isLoading ? (
          <>
            <SkeletonInstitute />
            <SkeletonInstitute />
          </>
        ) : institutions.length === 0 ? (
          <p className="text-gray-500 text-sm text-center w-full">
            No Institution Found, <Link to="/institutions/add" className="text-theme-blue underline">Please Add One</Link>
          </p>
        ) : (
          institutions?.map((institution) => (
            <SingleInstitute
              institution={institution}
              onClick={() => dispatch(setSelectedInstitution(institution))}
              active={selectedInstitution?._id === institution._id}
            />
          ))
        )}
      </div>
    </>
  );
}
