export default function SimpleKeyIcon({
  height = "25",
  width = "24",
  stroke = "1.5",
  fill = "#292D32",
}) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.7897 15.1147C18.8132 16.0888 17.5893 16.7777 16.2499 17.1073C14.9106 17.4368 13.5067 17.3944 12.1897 16.9847L7.47971 21.6847C7.27931 21.8658 7.04321 22.003 6.78658 22.0874C6.52996 22.1718 6.25851 22.2015 5.98971 22.1747L3.80971 21.8747C3.4382 21.8037 3.09658 21.6227 2.82912 21.3553C2.56167 21.0878 2.38072 20.7462 2.30971 20.3747L2.00971 18.1917C1.98763 17.9232 2.01962 17.653 2.10378 17.397C2.18794 17.1411 2.32257 16.9047 2.49971 16.7017L7.19971 12.0017C6.80306 10.7206 6.75381 9.35715 7.05699 8.05079C7.36017 6.74443 8.00491 5.54205 8.92526 4.56662C9.84561 3.5912 11.0085 2.87774 12.2951 2.49923C13.5816 2.12071 14.9457 2.09073 16.2476 2.41234C17.5495 2.73394 18.7427 3.3956 19.705 4.32964C20.6674 5.26368 21.3643 6.43657 21.7246 7.72834C22.0849 9.02011 22.0956 10.3844 21.7557 11.6817C21.4157 12.979 20.7372 14.1626 19.7897 15.1117V15.1147Z"
          stroke={fill}
          stroke-width={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.88965 17.6738L9.18965 19.9738"
          stroke={fill}
          stroke-width={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 9.68359C16 9.98027 15.912 10.2703 15.7472 10.5169C15.5824 10.7636 15.3481 10.9559 15.074 11.0694C14.7999 11.1829 14.4983 11.2126 14.2074 11.1548C13.9164 11.0969 13.6491 10.954 13.4393 10.7443C13.2296 10.5345 13.0867 10.2672 13.0288 9.97623C12.9709 9.68526 13.0006 9.38366 13.1142 9.10957C13.2277 8.83548 13.42 8.60121 13.6666 8.43639C13.9133 8.27157 14.2033 8.18359 14.5 8.18359C14.8978 8.18359 15.2794 8.34163 15.5607 8.62293C15.842 8.90424 16 9.28577 16 9.68359Z"
          stroke={fill}
          stroke-width={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
