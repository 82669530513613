import Label from "common/Label";
import QNASection from "./QNASection";
import { MediaInput } from "common/Input";
import MediaPreview from "common/Input/MediaPreview";
import { useUpdateMajor } from "api/major";
import { useParams } from "react-router-dom";
import {useUploadFiles} from "api/uploadFiles";
import { RotateIcon } from "assets/icons";
import { getMediaName, useUtilities } from "helper";

export default function MediaSection({ majorData }) {
  const { majorId } = useParams();
  const { mutateAsync: uploadFiles } = useUploadFiles();
  const { mutate: updateMajor, isLoading: isUpdatingMajor } =
    useUpdateMajor(majorId);
  const { notification } = useUtilities();
  return (
    <>
      <div className="my-4 flex gap-3 w-full">
        <div className="flex-[1.5_1_0]">
          <Label
            label="Images"
            tooltip="Upload images you think best describe this Major/Department/University"
          />
          <div className="flex gap-3">
            <div className="flex-1">
              {majorData?.cover_image && (
                <div
                  className="min-h-[160px] border border-theme-gray rounded-lg relative max-h-[180px] h-full mt-4 bg-cover bg-no-repeat bg-center"
                  style={{
                    backgroundImage: `url(${majorData.cover_image})`,
                  }}
                >
                  <label
                    htmlFor="cover-images"
                    className="absolute -top-2 -left-2 border border-black p-[3px] rounded-full cursor-pointer bg-white"
                  >
                    <RotateIcon width="9" height="9" />
                  </label>
                </div>
              )}
              <MediaInput
                id="cover-images"
                allowedTypes={["jpg", "png", "jpeg"]}
                multiple={false}
                text="Choose Cover Image"
                onChange={async (file) => {
                  uploadFiles({
                    data: file,
                  }).then((res) => {
                    if (res.statusCode === 201) {
                      updateMajor({
                        cover_image: res.data[0],
                      });
                    }
                  });
                }}
                className={`shadow-md ${
                  majorData?.cover_image ? "hidden" : ""
                }`}
              />
            </div>
            <div className="flex-1">
              <MediaInput
                id="profile-images"
                allowedTypes={["jpg", "png", "jpeg"]}
                multiple
                onChange={async (files) => {
                  if (majorData?.profile_image.length >= 6 || files.length > 6 || files.length + majorData?.profile_image.length > 6) {
                    notification({
                      type: "error",
                      message: "Maximum 6 pictures allowed"
                    })
                    return;
                  }
                  uploadFiles({
                    data: files,
                  }).then((res) => {
                    if (res.statusCode === 201) {
                      updateMajor({
                        profile_image: [
                          ...majorData.profile_image.map((ele) => getMediaName(ele)),
                          ...res.data,
                        ],
                      });
                    }
                  });
                }}
                className="shadow-md"
              />
            </div>
          </div>
          <div className="flex flex-1 flex-wrap gap-3 items-start my-4">
            {majorData?.profile_image.length > 0 &&
              majorData.profile_image.map((image, index) => {
                return (
                  <MediaPreview
                    url={image}
                    alt=""
                    onRemove={() => {
                      updateMajor({
                        profile_image: majorData.profile_image
                          .filter((e, idx) => index != idx)
                          .map((ele) => ele.split("?")[0].split("/").pop()),
                      });
                    }}
                  />
                );
              })}
          </div>
        </div>
        <div className="flex-1">
          <QNASection majorData={majorData} />
        </div>
      </div>
    </>
  );
}
