import SearchIcon from "assets/icons/SearchIcon";
import { CustomRadio, Input } from "common/Input";
import Label from "common/Label";
import Select from "common/Select";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateMajor } from "api/major";
import { DeleteIcon } from "assets/icons";
import Modal from "common/Modal";

const applyMinimumCriteria = [
  {
    label: "SAT score",
    value: "sat_score",
  },
  {
    label: "Engineering score",
    value: "engineering_score",
  },
  {
    label: "Quantitative score",
    value: "quantitative_score",
  },
  {
    label: "Psychometric score",
    value: "psychometric_score",
  },
  {
    label: "High school score",
    value: "high_school_score",
  },
];

export default function ApplyTab({ majorData }) {
  const { majorId } = useParams();
  const { mutate: updateMajor, isLoading: isUpdatingMajor } =
    useUpdateMajor(majorId);

  const [selectedOption, setSelectedOption] = useState("not_fit");
  const [addCustomCriteria, setAddCustomCriteria] = useState(false);
  const [customCriteria, setCustomCriteria] = useState("");
  const [rejectionMessage, setRejectionMessage] = useState({
    message:
      "We regret to inform you that your application to our university was not successful. We appreciate your interest and effort. Remember, this doesn't define your worth or future success. Other opportunities await, and you can still achieve your goals through different paths. Keep moving forward.",
    message_type: "default",
  });
  const [appAcceptanceMessage, setAppAcceptanceMessage] = useState({
    message: "",
    message_type: "default",
  });
  const [maximumQuota, setMaximumQuota] = useState(0);

  const [minimumCriteria, setMinimumCriteria] = useState({});

  useEffect(() => {
    if (!majorData) return;
    setSelectedOption(majorData.rejection_type);
    majorData.rejection_message &&
      setRejectionMessage(majorData.rejection_message);
    majorData.application_acceptance_message &&
      setAppAcceptanceMessage(majorData.application_acceptance_message);
    setMaximumQuota(majorData.maximum_quota);
    if (!majorData.minimum_criteria) return;
    let criteria = {};
    Object.keys(majorData.minimum_criteria).forEach((key) => {
      if (majorData.minimum_criteria[key]) {
        criteria[key] = majorData.minimum_criteria[key];
      }
    });
    setMinimumCriteria(criteria);
  }, [
    majorData.rejection_type,
    majorData.maximum_quota,
    majorData.rejection_message,
    majorData.application_acceptance_message,
    majorData.minimum_criteria,
  ]);

  const handleRejectionMessageChange = (event) => {
    setRejectionMessage({
      ...rejectionMessage,
      message_type: event.target.value,
    });
    if (event.target.value !== majorData.rejection_message.message_type) {
      updateMajor({
        rejection_message: {
          ...rejectionMessage,
          message_type: event.target.value,
        },
      });
    }
  };

  const handleAppAcceptanceMessageChange = (event) => {
    setAppAcceptanceMessage({
      ...appAcceptanceMessage,
      message_type: event.target.value,
    });
    if (event.target.value !== majorData.application_acceptance_message) {
      updateMajor({
        application_acceptance_message: {
          ...appAcceptanceMessage,
          message_type: event.target.value,
        },
      });
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value !== majorData.rejection_type) {
      updateMajor({
        rejection_type: event.target.value,
      });
    }
  };

  return (
    <>
      <Modal
        open={addCustomCriteria}
        onConfirm={() => {
          setAddCustomCriteria(false);
          setMinimumCriteria({
            ...minimumCriteria,
            [customCriteria]: 0,
          });
          setCustomCriteria("");
        }}
        confirmText="Add Criteria"
        heading="Add Custom Criteria"
        className="max-w-[500px]"
        onCancel={() => setAddCustomCriteria(false)}
      >
        <div className="flex flex-col gap-4">
          <Input
            placeholder="Enter Criteria"
            value={customCriteria}
            onChange={(e) => setCustomCriteria(e.target.value)}
          />
        </div>
      </Modal>
      <div className="flex">
        <div className="flex-1 border-r border-theme-gray pr-4">
          <div className="flex gap-4">
            <div className="flex-1">
              <Label
                label="Minimum Criteria"
                tooltip="Select one or more minimum Criteria that candidates must have in order to be accepted"
              />
              <Select
                className="min-w-[150px] max-w-[200px]"
                optionClass="max-w-[200px]"
                icon={<SearchIcon fill="#F29423" />}
                options={[
                  {
                    label: (
                      <p>
                        Add{" "}
                        <span className="underline text-theme-blue">
                          Custom
                        </span>
                      </p>
                    ),
                    value: "",
                  },
                  ...applyMinimumCriteria.filter(
                    (ele) => !Object.keys(minimumCriteria).includes(ele.value)
                  ),
                ]}
                onChange={(value) => {
                  if (!value) {
                    setAddCustomCriteria(true);
                    return;
                  }
                  setMinimumCriteria({
                    ...minimumCriteria,
                    [value]: 0,
                  });
                }}
              ></Select>
              <div className="my-4">
                {Object.keys(minimumCriteria).map((key) => (
                  <div className="">
                    <Label label={key.split("_").join(" ")} />
                    <div className="flex items-center gap-4 my-2">
                      <Input
                        type="number"
                        placeholder="Minimum criteria val"
                        min={0}
                        max={999}
                        className="shadow-none max-w-[150px]"
                        value={minimumCriteria[key]}
                        onChange={(e) => {
                          if (e.target.value < 0 || e.target.value > 999)
                            return;
                          setMinimumCriteria({
                            ...minimumCriteria,
                            [key]: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          if (e.target.value !== majorData.minimum_criteria) {
                            updateMajor({
                              minimum_criteria: {
                                ...minimumCriteria,
                                [key]: e.target.value,
                              },
                            });
                          }
                        }}
                      />
                      <button
                        className="flex items-center justify-center"
                        onClick={() => {
                          let criteria = {};
                          Object.keys(minimumCriteria).forEach((key) => {
                            if (key !== key) {
                              criteria[key] = minimumCriteria[key];
                            }
                          });
                          updateMajor({
                            minimum_criteria: {
                              ...minimumCriteria,
                              [key]: 0,
                            },
                          });
                          setMinimumCriteria(criteria);
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-1">
              <Label
                label="Maximum Quota"
                tooltip="Define how many candidates can be accepted this year"
              />
              <Input
                type="number"
                placeholder="Maximum Quota val"
                min={0}
                max={999}
                className="shadow-none max-w-[150px]"
                value={maximumQuota}
                onChange={(e) => {
                  if (e.target.value < 0 || e.target.value > 999) return;
                  setMaximumQuota(e.target.value);
                }}
                onBlur={(e) => {
                  if (e.target.value !== majorData.maximum_quota) {
                    updateMajor({
                      maximum_quota: maximumQuota,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="my-4 max-w-md">
            <Label
              label="Message"
              tooltip="Write a message to show candidates that got accepted."
            />
            <div className="flex items-center gap-6 my-3 flex-wrap">
              <CustomRadio
                id="defeault-acceptance-message"
                name="acceptance-message"
                label="Default"
                value="default"
                checked={appAcceptanceMessage.message_type === "default"}
                onChange={handleAppAcceptanceMessageChange}
              />
              <CustomRadio
                id="custom-acceptance-message"
                name="acceptance-message"
                label="Custom"
                value="custom"
                checked={appAcceptanceMessage.message_type === "custom"}
                onChange={handleAppAcceptanceMessageChange}
              />
            </div>
            <Input
              type="textarea"
              className="shadow-none mt-2"
              rows={7}
              col={20}
              disabled={appAcceptanceMessage.message_type === "default"}
              value={appAcceptanceMessage.message}
              onChange={(e) => {
                if (appAcceptanceMessage.message_type === "default") return;
                if (e.target.value.length > 1000) return;
                setAppAcceptanceMessage({
                  ...appAcceptanceMessage,
                  message: e.target.value,
                });
              }}
              onBlur={(e) => {
                if (appAcceptanceMessage.message_type === "default") return;
                if (
                  e.target.value !== majorData.application_acceptance_message
                ) {
                  updateMajor({
                    application_acceptance_message: {
                      ...appAcceptanceMessage,
                      message: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="flex-1 pl-8">
          <div>
            <Label
              label="Rejection"
              tooltip={
                selectedOption === "not_fit"
                  ? "non applicable"
                  : selectedOption === "could_fit"
                  ? "close to threshold applicants"
                  : selectedOption === "fit_but_too_many_applicants"
                  ? "close to threshold applicants"
                  : ""
              }
            />
            <div className="flex items-center gap-6 my-4 flex-wrap">
              <CustomRadio
                id="not_fit"
                name="option"
                label="Not fit"
                value="not_fit"
                checked={selectedOption === "not_fit"}
                onChange={handleOptionChange}
              />
              <CustomRadio
                id="could_fit"
                name="option"
                label="Could fit"
                value="could_fit"
                checked={selectedOption === "could_fit"}
                onChange={handleOptionChange}
              />
              <CustomRadio
                id="fit_but_too_many_applicants"
                name="option"
                label="Fit but too many applicants"
                value="fit_but_too_many_applicants"
                checked={selectedOption === "fit_but_too_many_applicants"}
                onChange={handleOptionChange}
              />
            </div>
          </div>
          <div className="max-w-md">
            <Label
              label="Message"
              tooltip="Write a message to show candidates that got rejected."
            />
            <div className="flex items-center gap-6 my-3 flex-wrap">
              <CustomRadio
                id="defeault-rejection-message"
                name="rejection-message"
                label="Default"
                value="default"
                checked={rejectionMessage?.message_type === "default"}
                onChange={handleRejectionMessageChange}
              />
              <CustomRadio
                id="custom-rejection-message"
                name="rejection-message"
                label="Custom"
                value="custom"
                checked={rejectionMessage?.message_type === "custom"}
                onChange={handleRejectionMessageChange}
              />
            </div>
            <Input
              type="textarea"
              className="shadow-none mt-2 disabled:cursor-not-allowed"
              rows={7}
              col={20}
              disabled={rejectionMessage?.message_type === "default"}
              value={rejectionMessage.message}
              onChange={(e) => {
                if (rejectionMessage.message_type === "default") return;
                if (e.target.value.length > 1000) return;
                setRejectionMessage({
                  ...rejectionMessage,
                  message: e.target.value,
                });
              }}
              onBlur={(e) => {
                if (rejectionMessage.message_type === "default") return;
                if (e.target.value !== majorData.rejection_message.message) {
                  updateMajor({
                    rejection_message: {
                      ...rejectionMessage,
                      message: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
