import { axios, useUtilities } from "helper";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { addCourse, setCourses } from "redux/reducers/courses";
import { updateBatch } from "redux/reducers/batches";

async function getCourses() {
  return await axios
    .get("/courses")
    .then((res) => res.data)
    .catch((err) => err);
}

function useGetCourses() {
  return useQuery(["courses"], getCourses);
}

export function useCourses() {
  const { data, isLoading, error } = useGetCourses();
  const {
    dispatch,
    courses: { courses, loaded },
  } = useUtilities();

  useEffect(() => {
    if (loaded) return;
    if (data?.data) {
      dispatch(setCourses(data.data));
    }
  }, [data, loaded]);

  return {
    data: courses,
    isLoading: isLoading || !loaded,
    error,
  };
}

async function createCourse({ data, callback }) {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  return await axios
    .post("/courses", data)
    .then((res) => {
      callback && callback(res.data);
      return res.data;
    })
    .catch((err) => err);
}

export function useCreateCourse() {
  const { dispatch, notification } = useUtilities();
  return useMutation(createCourse, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addCourse(data.data));
        notification({
          type: "success",
          message: "Course created successfully",
        });
      } else {
        notification({
          type: "error",
          message: "Something went wrong",
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });
}

async function updateCourseApi({ data, callback }) {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  return await axios
    .put(`/courses/${data._id}`, data)
    .then((res) => {
      callback && callback(res.data);
      return res.data;
    })
    .catch((err) => err);
}


export function useUpdateCourse() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateCourseApi, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateBatch(data.data));
        notification({
          type: "success",
          message: "Course updated successfully",
        });
      } else {
        notification({
          type: "error",
          message: "Something went wrong",
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });
}