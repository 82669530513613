export default function KeyIcon({ height = "20", width = "20" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
    >
      <path
        id="keys_icon"
        data-name="keys icon"
        d="M13,5a2,2,0,0,1,2,2m4,0a6,6,0,0,1-7.743,5.743L9,15H7v2H5v2H2a1,1,0,0,1-1-1V15.414a1,1,0,0,1,.293-.707L7.257,8.743A6,6,0,1,1,19,7Z"
        fill="none"
        stroke="#004e99"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
}
