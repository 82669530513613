import ChevronLeftIcon from "assets/icons/ChevronLeftIcon";
import { Link } from "react-router-dom";

export default function BreadCrumbs({ data }) {
  return (
    <>
      <div className="flex gap-4 my-3">
        {data.map(({ label, link, style }, index) => (
          <>
            <Link
              to={link ?? ""}
              key={index}
              className="flex gap-4 capitalize font-medium text-theme-blue truncate"
              style={style}
            >
              {label}
            </Link>
            {index < data.length - 1 && (
              <ChevronLeftIcon className="self-center" fill="#292D32" />
            )}
          </>
        ))}
      </div>
    </>
  );
}
