import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

import "./styles.css";

// import required modules
import { EffectFade, Autoplay } from "swiper/modules";

export default function ImageSwiper() {
  return (
    <>
      <Swiper
        spaceBetween={0}
        effect={"fade"}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        speed={1500}
        loop={true}
        modules={[EffectFade, Autoplay]}
        className="mySwiper"
      >
        {new Array(40).fill("").map((item, index) => (
          <SwiperSlide key={index}>
            <img src={`/images/random${index + 1}.jpg`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
