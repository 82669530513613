export default function UpSideDownIcon({
  width = "10",
  height = "15",
  fill = "#292D32",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3002 8.72852L5.58353 11.4452C5.2627 11.766 4.7377 11.766 4.41686 11.4452L1.7002 8.72852"
        stroke={fill}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.3002 6.27175L5.58353 3.55508C5.2627 3.23424 4.7377 3.23424 4.41686 3.55508L1.7002 6.27175"
        stroke={fill}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
