import SearchIcon from "assets/icons/SearchIcon";
import { twMerge } from "tailwind-merge";

export default function SearchInput({
  className,
  containerClass = "",
  ...props
}) {
  return (
    <>
      <div
        className={twMerge(
          "flex gap-2 items-center border border-[#CCD3F8] rounded-full bg-white px-3 py-2 w-fit",
          containerClass
        )}
      >
        <SearchIcon fill="#F29423" />
        <input
          {...props}
          className={twMerge(
            "bg-transparent min-w-[300px] placeholder:text-theme-blue focus-within:outline-none",
            className
          )}
        />
      </div>
    </>
  );
}
