import { Input } from "common/Input";
import Label from "common/Label";
import Select from "common/Select";
import React, { useEffect, useState } from "react";
import Divider from "common/Divider";
import CourseSection from "./Courses";
import SkillsSection from "./SkillsSection";
import MediaSection from "./MediaSection";
import { useGetMajorStaticData, useMajors, useUpdateMajor } from "api/major";
import { useParams } from "react-router-dom";
import Categories from "./Categories";

const durationTypeOptions = [
  { label: "years", value: "years" },
  { label: "months", value: "months" },
  { label: "weeks", value: "weeks" },
];

export default function MajorTab() {
  const { majorId } = useParams();
  const { mutate: updateMajor, isLoading: isUpdatingMajor } =
    useUpdateMajor(majorId);
  const { data: majors } = useMajors();

  const [inputValue, setInputValue] = useState({
    degreeType: 0,
    duration: "",
    durationType: "",
    description: "",
  });
  const [majorData, setMajorData] = useState(null);

  const { data: degreeType, isLoading: loadingStaticData } =
    useGetMajorStaticData();

  useEffect(() => {
    if (majors) {
      const major = majors.find((major) => major._id === majorId);
      setMajorData(major);
      setInputValue({
        description: major?.description,
        degreeType: major?.major_type_kpi?.type,
        duration: major?.duration?.value,
        durationType: major?.duration?.type,
      });
    }
  }, [majors]);

  return (
    <>
      <div className="flex flex-row">
        <div className="flex-1  pr-4">
          <div className="flex gap-4">
            <Input
              label="Description"
              placeholder="Enter Description"
              type="textarea"
              className="shadow-none rounded-none flex-[1.5_1_0]"
              cols={35}
              rows={5}
              value={inputValue.description}
              onChange={(e) =>
                setInputValue({ ...inputValue, description: e.target.value })
              }
              onBlur={() =>
                updateMajor({ description: inputValue.description })
              }
              disabled={isUpdatingMajor}
            />
            <div className="flex flex-col gap-4 flex-1">
              <Select
                tittle="Degree type"
                options={Object.values(degreeType?.degree_type || {}).map(
                  (item) => ({
                    label: item,
                    value: item,
                  })
                )}
                value={inputValue.degreeType}
                className="w-full rounded-none"
                listOptionClass="px-3"
                onChange={(value, index) => {
                  if (index === -1) return;
                  if (index === inputValue.degreeType) return;
                  setInputValue({
                    ...inputValue,
                    degreeType: index,
                  });
                  updateMajor({
                    major_type_kpi: {
                      type: value,
                      description: "",
                    },
                  });
                }}
                disabled={loadingStaticData}
              />
              <div>
                <Label label="Duration" />
                <div className="flex items-center gap-2 w-full">
                  <Input
                    placeholder="Enter Duration"
                    className="shadow-none rounded-none py-1"
                    type="number"
                    value={inputValue.duration}
                    onChange={(e) => {
                      setInputValue({
                        ...inputValue,
                        duration: Number(e.target.value),
                      });
                      updateMajor({
                        duration: {
                          value: Number(e.target.value),
                          type: inputValue.durationType,
                        },
                      });
                    }}
                  />
                  <Select
                    value={inputValue.durationType}
                    className="rounded-none"
                    listOptionClass="px-3"
                    options={durationTypeOptions}
                    onChange={(value, index) => {
                      if (index === -1) return;
                      if (index === inputValue.durationType) return;
                      setInputValue({
                        ...inputValue,
                        durationType: index,
                      });
                      updateMajor({
                        duration: {
                          value: inputValue.duration,
                          type: value,
                        },
                      });
                    }}
                  ></Select>
                </div>
              </div>
            </div>
          </div>
          <Divider className="bg-[#BEBEC0] h-[1px]" />
          <SkillsSection majorData={majorData} />
          <Divider className="bg-[#BEBEC0] h-[1px]" />
          <MediaSection majorData={majorData} />
        </div>
        <div className="flex-1 border-l border-l-[#BEBEC0] pl-4">
          <Categories majorData={majorData} />
          <Divider className="bg-[#BEBEC0] h-[1px] mt-5" />
          <CourseSection majorData={majorData} />
        </div>
      </div>
    </>
  );
}
