import { useAddSkill, useSkills, useUpdateSkill } from "api/skills";
import { CircleCloseIcon, Delete, EditIcon } from "assets/icons";
import ChevronDownIcon from "assets/icons/ChevronDownIcon";
import { Input, SearchInput } from "common/Input";
import Label from "common/Label";
import Modal from "common/Modal";
import Progress from "common/Progress";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateMajor } from "api/major";
import { useUtilities } from "helper";
import TechnicalSkills from "./TechnicalSkills";
import { twJoin } from "tailwind-merge";

export function GeneralSkill({ title, score, description, onEdit, onDelete }) {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <>
      <div className=" bg-white relative border border-theme-gray rounded-lg px-3 py-2 h-fit transition-all duration-300 ease-in-out"
      onClick={onEdit}
      >
        <div className="absolute -top-2 -left-2 cursor-pointer" onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}>
          <CircleCloseIcon />
        </div>
        <div className="flex items-center justify-between">
          <h3 className="font-normal text-sm">{title}</h3>
          <div className="flex items-center gap-2 w-1/3">
            <Progress
              color={"#5A70E8"}
              value={score}
              max={100}
              className="max-w-[90px] w-full"
            />
            <div
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setShowDescription(!showDescription);
              }}
            >
              <ChevronDownIcon
                fill="#5A70E8"
                className={`transition-all duration-300 ease-in-out ${
                  showDescription ? "rotate-180" : ""
                }`}
              />
            </div>
          </div>
        </div>
        <p
          className={twJoin(
            "text-sm text-start origin-top transition-all duration-300 ease-in-out block",
            showDescription ? "h-fit scale-y-100" : "h-0 scale-y-0"
          )}
        >
          {description}
        </p>
      </div>
    </>
  );
}

export default function SkillsSection({ majorData }) {
  const { data: skills } = useSkills();
  const { notification } = useUtilities();
  const { majorId } = useParams();
  const { mutate: updateMajor, isLoading: isUpdatingMajor } =
    useUpdateMajor(majorId);
  const { mutate: addSkill, isLoading: addingSkill } = useAddSkill();
  const { mutate: updateSkill, isLoading: updatingSkill } = useUpdateSkill();
  const [generalSkillSearch, setGeneralSkillSearch] = useState("");
  const [isGeneralSkillFocused, setIsGeneralSkillFocused] = useState(false);
  const [generalSkillFilter, setGeneralSkillFilter] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const generalSkills = skills?.filter(
    (item) =>
      majorData?.general_skills?.includes(item._id) && item.type === "general"
  );
  const [generalSkillValue, setGeneralSkillValue] = useState({
    title: "",
    score: 30,
    description: "",
  });

  useEffect(() => {
    if (skills.length === 0) return;
    setGeneralSkillFilter(
      skills
        .filter((item) =>
          item.title.toLowerCase().includes(generalSkillSearch.toLowerCase()) &&
          item.type === "general" &&
          generalSkills?.every((skill) => skill._id !== item._id)
        )
        .map((item, index) => (
          <p
            className=" px-3 hover:bg-slate-200 py-1 cursor-pointer"
            onClick={() => {
              if (majorData.general_skills.length >= 6) {
                notification({
                  type: "error",
                  message: "Maximum 6 skills allowed",
                });
                return;
              }
              updateMajor({
                general_skills: [...majorData.general_skills, item._id],
              });
              setGeneralSkillSearch("");
            }}
          >
            {item.title}
          </p>
        ))
    );
  }, [skills, generalSkillSearch]);

  const handleConfirm = () => {
    if (!generalSkillValue.title) {
      notification({ type: "error", message: "Please enter title" });
      return;
    }
    if (!generalSkillValue.score) {
      notification({ type: "error", message: "Please enter score" });
      return;
    }
    if (generalSkillValue.score < 0 || generalSkillValue.score > 100) {
      notification({
        type: "error",
        message: "Score must be between 0 and 100",
      });
      return;
    }
    if (!generalSkillValue.description) {
      notification({
        type: "error",
        message: "Please enter description",
      });

      return;
    }

    if (generalSkillValue.id) {
      updateSkill(generalSkillValue);
      setShowModal(false);
      setGeneralSkillValue({
        title: "",
        score: 0,
        description: "",
      });
      return;
    }
    addSkill({
      data: {
        title: generalSkillValue.title,
        score: generalSkillValue.score,
        description: generalSkillValue.description,
        type: "general",
      },
      callback: (skillId) => {
        updateMajor({
          general_skills: [...majorData.general_skills, skillId],
        });
      },
    });

    setShowModal(false);
    setGeneralSkillValue({
      title: "",
      score: 0,
      description: "",
    });
    setGeneralSkillSearch("");
  };

  return (
    <>
      <Modal
        open={showModal}
        heading={`${generalSkillValue.id ? "Edit" : "Add"} General skill`}
        onCancel={() => {
          setShowModal(false);
          setGeneralSkillValue({
            title: "",
            score: 0,
            description: "",
          });
        }}
        onConfirm={handleConfirm}
        confirmText="Save"
      >
        <div className="flex flex-col gap-3">
          <Input
            value={generalSkillValue.title}
            onChange={(e) =>
              setGeneralSkillValue({
                ...generalSkillValue,
                title: e.target.value,
              })
            }
            placeholder="Enter Title here"
            className="shadow-none w-full"
          />
          <input
            value={generalSkillValue.score}
            onChange={(e) =>
              setGeneralSkillValue({
                ...generalSkillValue,
                score: e.target.value,
              })
            }
            min={0}
            max={100}
            step={1}

            placeholder="Enter Score here"
            type="range"
            className="shadow-none w-full"
          />
          <Input
            type="textarea"
            className="shadow-none w-full"
            placeholder="Enter Description here"
            value={generalSkillValue.description}
            onChange={(e) =>
              setGeneralSkillValue({
                ...generalSkillValue,
                description: e.target.value,
              })
            }
          />
        </div>
      </Modal>
      <div className="flex gap-3 my-4">
        <div className="flex-1">
          <Label
            label="General Skills"
            tooltip="Select skills that students will acquire or should have when considering this Life Choice."
          />
          <div className="relative">
            <SearchInput
              placeholder="Search or add new"
              className="min-w-[200px] w-min"
              value={generalSkillSearch}
              onChange={(e) => setGeneralSkillSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  if (majorData.general_skills.length >= 6) {
                    notification({
                      type: "error",
                      message: "Maximum 6 skills allowed",
                    });
                    return;
                  }
                  setShowModal(true);
                  setGeneralSkillValue({
                    ...generalSkillValue,
                    title: generalSkillSearch,
                  });
                }
              }}
              onFocus={() => setIsGeneralSkillFocused(true)}
              onBlur={() => {
                setTimeout(() => {
                  setIsGeneralSkillFocused(false);
                }, 200);
              }}
            />
            {isGeneralSkillFocused && generalSkillSearch && (
              <div className="absolute top-full left-0 w-full bg-white rounded-lg shadow-lg py-3 z-10">
                <div className="flex flex-col gap-2">
                  <p
                    className=" px-3 hover:bg-slate-200 py-1 cursor-pointer"
                    onClick={() => {
                      if (majorData.general_skills.length >= 6) {
                        notification({
                          type: "error",
                          message: "Maximum 6 skills allowed",
                        });
                        return;
                      }
                      setShowModal(true);
                      setGeneralSkillValue({
                        ...generalSkillValue,
                        title: generalSkillSearch,
                      });
                    }}
                  >
                    Add&nbsp;
                    <span className="text-theme-blue underline">
                      {generalSkillSearch}
                    </span>
                  </p>
                  {generalSkillFilter}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col my-3 gap-2">
            {generalSkills.map((item, index) => (
              <GeneralSkill
                title={item.title}
                score={item.score}
                description={item.description}
                onEdit={() => {
                  setShowModal(true);
                  setGeneralSkillValue({
                    title: item.title,
                    score: item.score,
                    description: item.description,
                    id: item._id,
                  });
                }}
                onDelete={() =>
                  updateMajor({
                    general_skills: majorData.general_skills.filter(
                      (skill) => skill !== item._id
                    ),
                  })
                }
              />
            ))}
          </div>
        </div>
        <div className="flex-1">
          <TechnicalSkills majorData={majorData} />
        </div>
      </div>
    </>
  );
}
