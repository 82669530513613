import SocketProvider from "context/socket";
import Layout from "./Layout";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

export default function Home() {
  return (
    <SocketProvider>
      <Layout>
        <LeftPanel />
        <RightPanel />
      </Layout>
    </SocketProvider>
  );
}
