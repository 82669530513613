export default function ApplicantsIcon({ width = "23", height = "21.778" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "23"}
      height={height || "21.778"}
      viewBox="0 0 23 21.778"
    >
      <path
        id="applicants_icon"
        data-name="applicants icon"
        d="M18.486,17.264v2.444a2.444,2.444,0,0,1-2.444,2.444H3.819a2.444,2.444,0,0,1-2.444-2.444V11.153A2.444,2.444,0,0,1,3.819,8.708H6.264M9.931,3.819H8.708A2.444,2.444,0,0,0,6.264,6.264v8.555a2.444,2.444,0,0,0,2.444,2.445H20.931a2.444,2.444,0,0,0,2.444-2.445V6.264a2.444,2.444,0,0,0-2.444-2.444H19.708M18.486,8.708l-3.667,3.667m0,0L11.153,8.708m3.667,3.667v-11"
        transform="translate(-0.875 -0.875)"
        fill="none"
        stroke="#333"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  );
}
