import { useEffect } from "react";
import { addSkill, setSkills, updateSingleSkill } from "redux/reducers/skills";
import { useUtilities, axios } from "helper";
import { useMutation, useQuery } from "@tanstack/react-query";

async function getSkills() {
  return axios
    .get("skills")
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetSkills() {
  return useQuery(["skills"], getSkills);
}

export function useSkills() {
  const { data, isLoading, isError, error } = useGetSkills();
  const {
    dispatch,
    skills: { skills, loaded },
  } = useUtilities();

  useEffect(() => {
    if (data && !loaded) {
      dispatch(setSkills(data.data));
    }
  }, [data]);

  return { data: skills, isLoading: isLoading || !loaded, isError, error };
}

async function createSkill({ data, callback }) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  return await axios
    .post("skills", data)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useAddSkill() {
  const { notification, dispatch } = useUtilities();
  return useMutation(createSkill, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addSkill(data.data));
        notification({
          message: "Skill added successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to add Skill",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to add Skill",
        type: "error",
      });
    },
  });
}

async function updateSkill(data) {
  const skillId = data.id;
  delete data.id;
  return await axios
    .put("skills/" + skillId, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateSkill() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateSkill, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleSkill(data.data));
        notification({
          message: "Skill updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to update Skill",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to update Skill",
        type: "error",
      });
    },
  });
}
