import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    major: [],
    institutionId: "",
};

const majorSlice = createSlice({
    name: "major",
    initialState,
    reducers: {
        setMajor: (state, action) => {
            const { institutionId, major } = action.payload;
            state.major = major;
            state.institutionId = institutionId;
        },
        addMajor: (state, action) => {
            state.major.push(action.payload);
        },
        updateSingleMajor: (state, action) => {
            const index = state.major.findIndex((major) => major._id === action.payload._id);
            if (index !== -1) {
                state.major[index] = action.payload;
            }
        },
        deleteMajor: (state, action) => {
            state.major = state.major.filter((major) => major._id !== action.payload);
        },
    },
});

export const { setMajor, addMajor, updateSingleMajor, deleteMajor } = majorSlice.actions;

export default majorSlice.reducer;