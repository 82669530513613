import { axios } from "helper";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useUtilities } from "helper";
import { useEffect } from "react";
import { addBatch, setBatches } from "redux/reducers/batches";
import { useUserInstitute } from "./user";
import {
  setBatchDataStaled,
  setBatcheDetailsAction,
} from "redux/reducers/batchDetails";
import { queryClient } from "client";
import { useSelectedInstitution } from "hooks/useInstitute";

export async function createBatch(data, tenantId) {
  return await axios
    .post("batches", data, {
      headers: {
        "x-tenant": tenantId,
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useCreateBatch() {
  const { notification, dispatch } = useUtilities();
  const selectedInstitution = useSelectedInstitution();

  return useMutation(
    (args) => createBatch(args, selectedInstitution.institution_initials),
    {
      onSuccess: (data) => {
        if (data.statusCode === 201) {
          dispatch(addBatch(data.data));
          notification({
            message: "Batch added successfully",
            type: "success",
          });
        } else {
          notification({
            message: "Failed to add Batch",
            type: "error",
          });
        }
      },
      onError: (error) => {
        notification({
          message: "Failed to add Batch",
          type: "error",
        });
      },
    }
  );
}

export async function getBatches(instituteId, tenantId) {
  return axios
    .get("batches", {
      params: {
        instituteId,
      },
      headers: {
        "x-tenant": tenantId,
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetBatches(instituteId) {
  const selectedInstitution = useSelectedInstitution();
  return useQuery(
    ["batches", instituteId],
    () => getBatches(instituteId, selectedInstitution?.institution_initials),
    {
      enabled: !!instituteId,
    }
  );
}

export function useBatches() {
  const institutionId = useUserInstitute();
  const { data, isLoading, isError, error, ...rest } =
    useGetBatches(institutionId);
  const {
    dispatch,
    batches: {
      batches,
      institution,
      loaded,
      hasNextPage: nextPageExist,
      totalDocs: total,
      totalPages: pages,
    },
  } = useUtilities();

  useEffect(() => {
    if (data && institution !== institutionId) {
      const { limit, nextPage, hasNextPage, totalDocs, totalPages, results } =
        data?.data;
      dispatch(
        setBatches({
          institution: institutionId,
          batches: results,
          limit,
          nextPage,
          hasNextPage,
          totalDocs,
          totalPages,
        })
      );
    }
  }, [data]);

  return {
    data: batches,
    isLoading: isLoading || !loaded || institution !== institutionId,
    isError,
    error,
    hasNextPage: nextPageExist,
    total,
    pages,
    ...rest,
  };
}

export async function getKeysByBatch(data, tenantId) {
  return axios
    .get(`keys`, {
      params: {
        batchId: data.id,
        limit: 10,
        page: data.currentPage,
      },
      headers: {
        "x-tenant": tenantId,
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetKeysByBatch(data) {
  const selectedInstitution = useSelectedInstitution();
  return useQuery(
    ["keysByBatch", data.id],
    () => getKeysByBatch(data, selectedInstitution?.institution_initials),
    {
      enabled: !!data.id && !!selectedInstitution?.institution_initials,
    }
  );
}

export function useKeysByBatch(id, currentPage = 1) {
  const { data, isLoading, isError, error, ...rest } = useGetKeysByBatch({
    id,
    currentPage,
  });
  const {
    dispatch,
    batchDetails: {
      batchDetails,
      batch,
      loaded,
      hasNextPage: haveMore,
      totalDocs: pages,
      staled,
    },
  } = useUtilities();
  useEffect(() => {
    if (data && (batch !== id || staled)) {
      const {
        limit,
        nextPage,
        hasNextPage,
        totalDocs,
        totalPages,
        results,
        currentPage,
      } = data?.data;
      dispatch(
        setBatcheDetailsAction({
          batch: id,
          batchDetails: results,
          limit,
          nextPage,
          hasNextPage,
          totalDocs,
          totalPages,
          currentPage,
        })
      );
    }
  }, [data, id]);
  return {
    data: batchDetails || [],
    isLoading: isLoading || !loaded || batch !== id,
    isError,
    error,
    hasNextPage: haveMore,
    totalPages: pages,
    ...rest,
  };
}

const updateKey = async ({ id, ...data }, tenantId) => {
  return await axios
    .put("keys/" + id, data, {
      headers: {
        "x-tenant": tenantId,
      },
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => {
      throw new Error(err);
    });
};

export function useUpdateKey() {
  const { dispatch, notification } = useUtilities();
  const selectedInstitution = useSelectedInstitution();

  return useMutation(
    (args) => updateKey(args, selectedInstitution.institution_initials),
    {
      onSuccess: (data) => {
        if (data.statusCode === 200) {
          notification({
            message: "Key updated successfully",
            type: "success",
          });
          queryClient.invalidateQueries(["keysByBatch"]);
          dispatch(setBatchDataStaled(true));
        } else {
          notification({
            message: data?.message || "Key not updated successfully",
            type: "error",
          });
        }
      },
      onError: (error) => {
        console.log(error);
        notification({
          message:
            error?.response?.data?.message || "Key not updated successfully",
          type: "error",
        });
      },
    }
  );
}
