import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ImageSwiper from "common/ImageSwiper";
import Login from "./Login";
import SignUp from "./SignUp";
import { Link } from "react-router-dom";
import ForgetPassword from "./ForgetPassword";
import VerifyEmail from "./VerifyEmail";
import AddKey from "./AddKey";
import NewPassword from "./NewPassword";

export default function UserAuth() {
  return (
    <>
      <div className="h-[100dvh] w-[100dvw] flex items-center bg-white">
        <div className="w-full lg:w-[40vw]  flex flex-col items-center justify-between h-[100dvh] absolute z-10 bg-white bg-opacity-50 backdrop-blur-sm lg:relative">
          <Link to="/">
            <img
              src={require("../../assets/logo/nexme_logo.png")}
              alt="Nexme"
              className="h-32"
            />
          </Link>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/signup" element={<SignUp />} /> */}
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route
              path="/forget-password/verify"
              element={<VerifyEmail forgetPassword />}
            />
            <Route path="/verify-email" element={<VerifyEmail />} />
            {/* <Route path="/add-key" element={<AddKey />} /> */}
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/*" element={<Navigate to="/user-auth/login" />} />
          </Routes>
          <Link to="terms-and-contract" className="pb-6 font-medium">
            Terms + Contract
          </Link>
        </div>
        <div className="w-full lg:w-[60vw] h-[100dvh] flex-1">
          <ImageSwiper />
        </div>
      </div>
    </>
  );
}
