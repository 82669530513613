export default function InsightsIcon({ width = "20", height = "20" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
    >
      <path
        id="insights_icon"
        data-name="insights icon"
        d="M7.664,15h4.673M10,1V2m6.364,1.636-.707.707M19,10H18M2,10H1M4.343,4.343l-.707-.707m2.828,9.9a5,5,0,1,1,7.071,0l-.547.547A3.375,3.375,0,0,0,12,16.469V17a2,2,0,1,1-4,0v-.531a3.374,3.374,0,0,0-.988-2.386Z"
        transform="translate(0)"
        fill="none"
        stroke="#004e99"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
}
