import React from "react";

const ChevronLeftIcon = ({ fill, width, height, className = "" }) => {
  return (
    <svg
      width={width ?? "6"}
      height={height ?? "12"}
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.826172 10.29L4.35784 6.75829C4.77492 6.34121 4.77492 5.65871 4.35784 5.24163L0.826172 1.70996"
        stroke={fill ?? "#292D32"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChevronLeftIcon;
