import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { FilledButton, OutlinedButton } from "./Button";
import { twMerge } from "tailwind-merge";
import Buttonloader from "./Buttonloader";
import { CrossIcon } from "assets/icons";

export default function Modal({
  children,
  open,
  onClose,
  heading = "",
  onCancel,
  onConfirm,
  confirmText = "Confirm",
  cancelText = "Cancel",
  showCancel = true,
  showConfirm = true,
  isLoading = false,
  confirmTextClassName = "",
  cancelTextClassName = "",
  confirmLoading = false,
  disableConfirm = false,
  childrenSectionClass = "",
  childrenMaxheight = true,
  showFooter = true,
  showCrossIcon = true,
  modalClass = "",
}) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);
  return open
    ? ReactDOM.createPortal(
        <div
          className="z-50 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#6F7070] bg-opacity-70"
          onClick={onClose}
        >
          <div
            className={twMerge(
              "bg-white py-5 rounded-md max-w-md w-full",
              modalClass
            )}
            onClick={(e) => e.stopPropagation()}
          >
            {!showFooter && showCrossIcon ? (
              <div className="px-4 cursor-pointer" onClick={onClose}>
                <CrossIcon fill="#292D32" width={13} height={13} />
              </div>
            ) : (
              ""
            )}
            {heading ? (
              <div className="border-b border-b-[#B7B7B7] px-5 pb-3">
                <h1 className="text-2xl font-semibold">{heading}</h1>
              </div>
            ) : (
              ""
            )}
            <div className={twMerge("px-5 py-5  h-full", childrenSectionClass)}>
              {children}
            </div>
            {showFooter ? (
              <div className="border-t border-t-[#B7B7B7] px-5 pt-3">
                <div className="flex justify-end items-center gap-4">
                  {showCancel ? (
                    <OutlinedButton
                      className={twMerge(
                        "py-2 border border-[#B7B7B7] rounded-lg px-8",
                        childrenMaxheight
                          ? "max-h-[50vh] overflow-y-auto custom-scroll"
                          : "",
                        cancelTextClassName
                      )}
                      onClick={() => (onCancel ? onCancel() : onClose())}
                    >
                      {cancelText}
                    </OutlinedButton>
                  ) : (
                    ""
                  )}
                  {showConfirm ? (
                    <FilledButton
                      className={twMerge(
                        "py-2 bg-theme-purple h-10 rounded-lg px-8 disabled:bg-gray-300 disabled:cursor-not-allowed",
                        confirmTextClassName
                      )}
                      onClick={() => (onConfirm ? onConfirm() : onClose())}
                      disabled={isLoading || disableConfirm}
                    >
                      {confirmLoading ? <Buttonloader /> : confirmText}
                    </FilledButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>,
        document.getElementById("root")
      )
    : "";
}
