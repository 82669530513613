import {
  ApplicantsIcon,
  BoostIcon,
  ExportIcon,
  InfoIcon,
  QuotaIcon,
  SuitableIcon,
  ViewsIcon,
} from "assets/icons";
import { FilledButton, OutlinedButton } from "common/Button";
import Divider from "common/Divider";
import Select from "common/Select";
import React, { useState } from "react";
import StudentStat from "./StudentStat";
import Table from "common/Table";

function InsightDetails({ title, value, icon, background = "#F29423" }) {
  return (
    <>
      <div
        className="h-32 w-72 p-3 rounded"
        style={{
          background:
            "radial-gradient(104.95% 80.3% at 86.6% 18.01%, rgba(0, 78, 153, 0.76) 0%, #004E99 100%)",
        }}
      >
        <div
          className="w-8 h-8 flex items-center justify-center rounded bg-theme-yellow"
          style={{ background }}
        >
          {icon}
        </div>
        <h1 className="text-5xl text-white font-medium mt-4">
          {value} <span className="text-xl font-thin">{title}</span>
        </h1>
      </div>
    </>
  );
}

export function YearsShow({ value, active = false, onClick, initial }) {
  return (
    <>
      <div
        className={`${
          active ? "bg-theme-purple" : "bg-[#DEE2FB]"
        } py-1 px-4 rounded-lg cursor-pointer transition-all duration-500 ease-in-out`}
        onClick={onClick}
      >
        <h2
          className={`${
            active ? "text-white" : "text-theme-purple"
          } font-semibold transition-all duration-500 ease-in-out`}
        >
          {initial?.toUpperCase()}{value}
        </h2>
      </div>
    </>
  );
}

export default function InSightTab({ majorData }) {
  const year = new Date().getFullYear();
  const [currentYear, setCurrentYear] = useState(1);
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <FilledButton className="py-1 px-8">
            <BoostIcon /> Boost Major
          </FilledButton>
          <Select options={[{ value: year, label: year }]} value={year} />
        </div>
        <div className="flex items-center gap-4">
          <OutlinedButton className="py-1 px-4 border-theme-blue text-theme-blue rounded font-normal">
            Boost Major
            <ExportIcon />
          </OutlinedButton>
          <OutlinedButton className="py-1 px-4 border-theme-blue text-theme-blue rounded font-normal">
            Costumize Dashboard
          </OutlinedButton>
        </div>
      </div>
      <div className="my-6 flex items-center gap-4">
        <InsightDetails
          title={"Views"}
          value={majorData.views}
          icon={<ViewsIcon />}
          background="#F29423"
        />
        <InsightDetails
          title={"Applicants"}
          value={majorData.views}
          icon={<ApplicantsIcon />}
          background="#DEE2FB"
        />
        <InsightDetails
          title={"Suited"}
          value={0}
          icon={<QuotaIcon />}
          background="#FFFFFF"
        />
        <InsightDetails
          title={"Quota"}
          value={majorData.maximum_quota}
          icon={<SuitableIcon />}
          background="#BB6BD9"
        />
      </div>
      <Divider className="bg-[#EBEDFD] h-[2px]" />
      <div className="flex items-center gap-1.5 my-4">
        {new Array(majorData?.duration?.value ?? 4).fill(0).map((item, index) => (
          <YearsShow
            value={index + 1}
            key={index}
            active={currentYear === index + 1}
            onClick={() => setCurrentYear(index + 1)}
            initial={majorData?.duration.type[0]}
          />
        ))}
      </div>
      <div className="flex items-start gap-5">
        <div className="flex-1">
          <h3 className="text-theme-blue text-lg mb-1">Students statistics</h3>
          <div className="border border-theme-gray rounded-md shadow-md">
            <div className="flex items-center gap-3 p-4">
              <div className="flex-1 px-4 py-2">
                <div className="flex items-center justify-between">
                  <h2 className="text-theme-blue font-semibold"># Of Students</h2>
                  <InfoIcon fill="#F29423" />
                </div>
                <div className="flex items-center justify-between mt-2">
                  <h2 className="text-gray-700 font-semibold">-</h2>
                  <p className="text-gray-700 font-light">Avg.-</p>
                </div>
              </div>
              <div className="flex-1 px-4 py-2 border-l-2 border-l-theme-gray">
                <div className="flex items-center justify-between">
                  <h2 className="text-theme-yellow font-semibold">Delayd</h2>
                  <InfoIcon fill="#F29423" />
                </div>
                <div className="flex items-center justify-between mt-2">
                  <h2 className="text-gray-700 font-semibold">-</h2>
                  <p className="text-gray-700 font-light">Avg.-</p>
                </div>
              </div>
              <div className="flex-1 px-4 py-2 border-l-2 border-l-theme-gray">
                <div className="flex items-center justify-between">
                  <h2 className="text-red-400 font-semibold">Drop-outs</h2>
                  <InfoIcon fill="#F29423" />
                </div>
                <div className="flex items-center justify-between mt-2">
                  <h2 className="text-gray-700 font-semibold">-</h2>
                  <p className="text-gray-700 font-light">Avg.-</p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center gap-3">
                <p className="font-semibold underline text-theme-blue">Age</p>
                <p className="font-semibold text-gray-300">Ethnicity</p>
              </div>
              <div className="flex items-center gap-3">
                <p className="font-light text-gray-600">Male</p>
                <p className="font-light text-gray-600">Female</p>
              </div>
            </div>
            <StudentStat />
          </div>
        </div>
        <div className="flex-1">
          <h3 className="text-theme-blue text-lg mb-1">Students statistics</h3>
          <div className="p-4 bg-theme-gray rounded-md shadow-md">
            <div className="flex items-center gap-4 mb-3">
              <div className="flex items-end justify-start gap-4 bg-theme-purple rounded-md p-3 flex-1">
                <h1 className="font-light text-white text-4xl">0</h1>
                <p className="font-semibold text-white text-sm">Frontally</p>
              </div>
              <div className="flex items-end justify-start gap-4 bg-theme-purple rounded-md p-3 flex-1">
                <h1 className="font-light text-white text-4xl">0</h1>
                <p className="font-semibold text-white text-sm">Hybrid</p>
              </div>
              <div className="flex items-end justify-start gap-4 bg-theme-purple rounded-md p-3 flex-1">
                <h1 className="font-light text-white text-4xl">0</h1>
                <p className="font-semibold text-white text-sm">Remote</p>
              </div>
            </div>
            <Table
              keys={["Course", "#", "Professor", "Avg. Score", "All Time Avg"]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
