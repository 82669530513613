export default function EditIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.198"
        height="18.198"
        viewBox="0 0 18.198 18.198"
      >
        <g
          id="vuesax_linear_edit-2"
          data-name="vuesax/linear/edit-2"
          transform="translate(-684 -252.556)"
        >
          <g id="edit-2" transform="translate(684 252.556)">
            <path
              id="Vector"
              d="M7.024,1.091.8,7.68A2.313,2.313,0,0,0,.291,8.765L.01,11.222a1.158,1.158,0,0,0,1.418,1.342l2.442-.417a2.177,2.177,0,0,0,1.054-.569l6.225-6.589c1.077-1.137,1.562-2.434-.114-4.019C9.367-.6,8.1-.046,7.024,1.091Z"
              transform="translate(3.031 1.639)"
              fill="none"
              stroke="#292d32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0A4.645,4.645,0,0,0,4.133,3.905"
              transform="translate(9.016 3.829)"
              fill="none"
              stroke="#292d32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0H13.649"
              transform="translate(2.275 16.682)"
              fill="none"
              stroke="#292d32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0H18.2V18.2H0Z"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
