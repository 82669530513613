import ChevronLeftIcon from "assets/icons/ChevronLeftIcon";
import React, { useState } from "react";

export default function Layout({ children }) {
  const [leftPanel, rightPanel] = children;
  const [menuFullWidth, setMenuFullWidth] = useState(false);
  return (
    <>
      <div className="flex items-start h-screen w-screen">
        <div className="w-20 relative h-full">
          <div
            className={`fixed top-0 left-0 bg-white w-full h-full py-4 z-10 transition-all duration-500 ease-in-out ${
              menuFullWidth ? "max-w-[240px]" : "max-w-[80px]"
            }  `}
            style={{ boxShadow: "-7px 0px 19px 3px rgba(0, 0, 0, 0.25)" }}
            onMouseEnter={() => setMenuFullWidth(true)}
            onMouseLeave={() => setMenuFullWidth(false)}
          >
            {React.cloneElement(leftPanel, {
              menuFullWidth: menuFullWidth,
            })}
          </div>
        </div>
        <div className=" bg-[#FAFAFF] flex-1 h-full py-7 px-10 overflow-y-auto custom-scroll">
          {rightPanel}
        </div>
      </div>
    </>
  );
}
