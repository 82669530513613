import { useMutation } from "@tanstack/react-query";
import { axios, useUtilities } from "helper";
import { v4 } from "uuid";

export default async function uploadFiles({ data, callback, onProgress }) {
  const formdata = new FormData();
  if (Array.isArray(data)) {
    data.forEach((file) => {
      formdata.append("images", file);
    });
  } else {
    formdata.append("images", data);
  }
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  const id = v4();

  return await axios
    .post("media/upload-files", formdata, {
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        onProgress &&
          onProgress({
            id,
            media: data,
            progress,
          });
      },
    })
    .then((res) => {
      callback && callback(res.data);
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useUploadFiles() {
  const { notification } = useUtilities();
  return useMutation((params) =>
    uploadFiles({
      ...params,
      onProgress: (data) => {
        if (data.progress === 100) {
          notification({
            type: "info",
            message: "Media Uploaded suecessfully and we are processing it,",
          });
        }
        notification({
          type: "mediaUpload",
          ...data,
        });
      },
    })
  );
}
