import { twMerge } from "tailwind-merge";

const SimpleLoader = ({ open = false, className = "" }) => {
  const loader = (
    <div className={twMerge("flex items-center justify-center ", className)}>
      <span className="loader !border-t-slate-700"></span>
    </div>
  );

  return open ? loader : null;
};

export default SimpleLoader;
