export default function ExportIcon({height, width, fill}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || "24"}
        height={height || "24"}
        viewBox="0 0 24 24"
      >
        <g
          id="vuesax_linear_export"
          data-name="vuesax/linear/export"
          transform="translate(-300 -508)"
        >
          <g id="export">
            <path
              id="Vector"
              d="M0,8.2,8.2,0"
              transform="translate(313 510.8)"
              fill="none"
              stroke={fill || "#004e99"}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M4.8,4.8V0H0"
              transform="translate(317.2 510)"
              fill="none"
              stroke={fill || "#004e99"}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11"
              transform="translate(302 510)"
              fill="none"
              stroke={fill || "#004e99"}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0H24V24H0Z"
              transform="translate(324 532) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
