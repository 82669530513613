export default function MajorIcon({ height = "18.055", width = "20" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 18.055"
    >
      <path
        id="majors_icon"
        data-name="majors icon"
        d="M10,11l9-5L10,1,1,6Zm0,0,6.159-3.422A11.969,11.969,0,0,1,17,12a12.083,12.083,0,0,1-.176,2.057,11.952,11.952,0,0,0-6.824,3,11.952,11.952,0,0,0-6.824-3,12.073,12.073,0,0,1,.665-6.479ZM6,17V9.5l4-2.222"
        fill="none"
        stroke="#004e99"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
}
