import React from "react";

const StarIcon = ({ fill, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "16.121"}
      height={height || "15.272"}
      viewBox="0 0 16.121 15.272"
    >
      <path
        id="star-Filled_1_"
        d="M317.141,2635.822l-2.642,2.539.624,3.581a1.546,1.546,0,0,1-.616,1.521,1.589,1.589,0,0,1-1.674.129l-3.272-1.694-3.272,1.694a1.586,1.586,0,0,1-1.674-.129,1.548,1.548,0,0,1-.616-1.521l.624-3.581-2.642-2.539a1.543,1.543,0,0,1-.4-1.6,1.563,1.563,0,0,1,1.28-1.072l3.656-.524,1.634-3.262a1.587,1.587,0,0,1,2.831,0l1.634,3.262,3.656.524a1.563,1.563,0,0,1,1.279,1.072A1.542,1.542,0,0,1,317.141,2635.822Z"
        transform="translate(-301.5 -2628.5)"
        fill={fill || "#f29423"}
      />
    </svg>
  );
};

export default StarIcon;
