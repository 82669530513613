import { useBatches } from "api/batches";
import {
  DownChevronFilled,
  CircleIcon,
  ChevronLeftIcon,
  UpSideDownIcon,
} from "assets/icons";
import { OutlinedButton } from "common/Button";
import Label from "common/Label";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BatchStatusStyle, BatchLevelStyle } from "./enum";
import { StatusButton } from "common/StatusButton";

export default function BatchesTable() {
  const { data, isLoading, isRefetching } = useBatches();
  const [selectRows, setSelectRows] = useState([]);
  const handleSelectRow = (id) => {
    setSelectRows((prev) => {
      if (prev.includes(id)) return prev.filter((item) => item !== id);
      return [...prev, id];
    });
  };

  if (isLoading || isRefetching) return <p className="py-4 text-center">Loading...</p>;

  return (
    <>
      <div>
        <div className="mt-6">
          <table className="w-full">
            <thead className="bg-[#F2F2F2] ">
              <tr>
                <th className="py-2 px-6 rounded-tl-3xl rounded-bl-3xl">
                  <input
                    type="checkbox"
                    className="bg-transparent"
                    checked={data.every((item) =>
                      selectRows.includes(item._id)
                    )}
                    onChange={() => {
                      if (data.every((item) => selectRows.includes(item._id))) {
                        setSelectRows([]);
                      } else {
                        setSelectRows(data.map((item) => item._id));
                      }
                    }}
                  />
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg">
                  ID No.
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg">
                  Batch Title
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg">
                  <div className="flex items-center gap-1.5">
                    <UpSideDownIcon /># of keys
                  </div>
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg">
                  <div className="flex items-center gap-1.5">
                    <UpSideDownIcon />
                    <Label
                      label={"Type"}
                      className=" text-[#595959] font-normal text-lg"
                      tooltipIcon={{
                        width: "20",
                        height: "20",
                        fill: "#595959",
                      }}
                    ></Label>
                  </div>
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg">
                  <div className="flex items-center gap-1.5">
                    <UpSideDownIcon />
                    Created date
                  </div>
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg">
                  <div className="flex items-center gap-1.5">
                    <UpSideDownIcon />
                    Valid until
                  </div>
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg">
                  Status
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg">
                  Created by
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg rounded-tr-3xl rounded-br-3xl">
                  <Label
                    label={"Actions"}
                    className=" text-[#595959] font-normal text-lg"
                    tooltipIcon={{
                      width: "20",
                      height: "20",
                      fill: "#595959",
                    }}
                  ></Label>
                </th>
              </tr>
            </thead>
            <tbody className="">
              {data?.map((item) => {
                return (
                  <tr className="">
                    <th className="py-4 px-6">
                      <input
                        type="checkbox"
                        checked={selectRows.includes(item._id)}
                        onChange={() => handleSelectRow(item._id)}
                      />
                    </th>
                    <td className="py-4 text-left text-[#828282]">
                      {item.batch_id}
                    </td>
                    <td className="py-4 text-left">{item.title}</td>
                    <td className="py-4 text-left">{item.number_of_keys}</td>
                    <td className="py-4 text-left">
                      <div className="flex items-center gap-2">
                        <CircleIcon
                          size="6"
                          fill={BatchLevelStyle[item.level].color}
                        />
                        <span style={BatchLevelStyle[item.level]}>
                          Level {item.level}
                        </span>
                      </div>
                    </td>
                    <td className="py-4 text-left text-[#828282]">
                      {new Date(item.startDate).toLocaleDateString()}
                    </td>
                    <td className="py-4 text-left text-[#828282]">
                      {new Date(item?.endDate).toLocaleDateString()}
                    </td>
                    <td className="py-4 text-left">
                      <StatusButton status={item?.status} style={BatchStatusStyle[item.status]} />
                    </td>
                    <td className="py-4 text-left">
                      <div className="flex items-center gap-2.5">
                        <div className="w-8 h-8 rounded-full bg-[#FFA7A7] flex items-center justify-center text-white font-medium text-sm uppercase">
                          {item?.created_by?.username
                            ?.split(" ")
                            ?.map((item) => item[0])
                            .join("")}
                        </div>
                        {item?.created_by?.username}
                      </div>
                    </td>
                    <td className="py-4 text-left">
                      <div className="flex items-center gap-4">
                        <Link to={`/keys/${item._id}`}>
                          <OutlinedButton
                            className={
                              "px-5 text-theme-blue text-lg font-medium rounded-md py-1"
                            }
                          >
                            Manage
                          </OutlinedButton>
                        </Link>
                        <OutlinedButton
                          className={
                            "px-5 text-theme-blue text-lg font-medium rounded-md py-1"
                          }
                        >
                          Actions
                          <DownChevronFilled />
                        </OutlinedButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data.length > 20 && (
            <div className="flex items-center justify-center gap-7 mt-10">
              <ChevronLeftIcon width="8" height="16" className="rotate-180" />
              <p className="text-[22px] text-[#333333]">1</p>
              <p className="text-[22px] text-[#333333]">2</p>
              <p className="text-2xl font-medium text-[#006ED8]">3</p>
              <p className="text-[22px] text-[#333333]">4</p>
              <p className="text-[22px] text-[#333333]">5</p>
              <ChevronLeftIcon width="8" height="16" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
