import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});
export default axiosInstance;

axiosInstance.interceptors.request.use(async (config) => {
  return {
    ...config,
    headers: {
      "x-tenant": "app",
      ...config.headers,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/user-auth/login";
    }
    return Promise.reject(error);
  }
);
