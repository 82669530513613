import React, { useRef } from "react";
import Label from "common/Label";
import { useState } from "react";
import { twJoin } from "tailwind-merge";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Modal from "common/Modal";
import { Input, TimeInput } from "common/Input";
import { useUtilities } from "helper";
import { useAddSchedule, useUpdateSchedule } from "api/schedule";
import { useAddDay, useUpdateDay } from "api/days";
import {
  useAddCareerCalender,
  useCloneCareerCalender,
  useRefreshCareerCalender,
  useUpdateCareerCalender,
} from "api/careerCalender";
import { BookSave, CircleAddIcon, CrossIcon } from "assets/icons";
import { FilledButton } from "common/Button";
import {
  useAddCalenderTemplate,
  useDeleteCalenderTemplate,
  useGetCalenderTemplate,
} from "api/calenderTemplate";
import Select from "common/Select";
import BlockLoader from "common/BlockLoader";
import { compareScheduleWithTemplate } from "helper/career";

const days = ["M", "T", "W", "T", "F"];

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

function SchduleModal({
  showModal,
  setShowModal,
  inputData,
  id,
  day,
  currentDay,
  calender,
  selectedTimeSlots = [],
}) {
  const { mutateAsync: addSchedule } = useAddSchedule();
  const { mutateAsync: updateSchedule } = useUpdateSchedule();
  const { mutate: AddDay, isLoading: addingDay } = useAddDay();
  const { mutate: updateDay, isLoading: updatingDay } = useUpdateDay();
  const { mutate: updateCalender, isLoading: updatingCalender } =
    useUpdateCareerCalender();
  const { mutate: refreshCalender, isLoading: refreshingCalender } =
    useRefreshCareerCalender();
  const { mutate: addCalender, addingCalender } = useAddCareerCalender();
  const { notification } = useUtilities();

  const [inputValues, setInputValues] = useState(
    inputData ?? {
      title: "",
      start: "",
      end: "",
    }
  );
 
  const onConfirm = () => {
    if (
      inputValues.title === "" ||
      inputValues.start === "" ||
      inputValues.end === ""
    ) {
      notification({
        message: "Please fill all fields",
        type: "error",
      });
      return;
    }
    if (new Date(`01/01/2000 ${inputValues.start}`) >= new Date(`01/01/2000 ${inputValues.end}`)) {
      notification({
        message: "End time should be greater than start time",
        type: "error",
      });
      return;
    }

    if (id) {
      updateSchedule({
        data: {
          ...inputValues,
          id,
        },
        callback: () => refreshCalender(calender._id),
      });
      setShowModal(false);
      return;
    }
    addSchedule({
      data: inputValues,
      callback: (scheduleID) => {
        if (day) {
          updateDay({
            data: {
              id: day._id,
              schedule: [...(day.schedule.map((_) => _._id) || []), scheduleID],
            },
            callback: () => refreshCalender(calender._id),
          });
        } else {
          AddDay({
            data: { day_of_week: currentDay, schedule: scheduleID },
            callback: (dayID) => {
              if (calender?._id) {
                let days = [];
                if (calender?.days?.length) {
                  days = [...calender.days.map((ele) => ele._id), dayID];
                } else {
                  days = [dayID];
                }
                updateCalender({
                  data: {
                    id: calender._id,
                    days,
                  },
                });
              } else {
                addCalender({
                  data: {
                    days: [dayID],
                    institution: calender?.institute,
                    suitable_to: calender?.suitable_to,
                  },
                });
              }
            },
          });
        }
      },
    });
    closeModal();
  };
  const closeModal = () => {
    setShowModal(false);
    setInputValues({
      title: "",
      start: "",
      end: "",
    });
  };
  return (
    <>
      <BlockLoader
        open={
          addingCalender ||
          updatingCalender ||
          addingDay ||
          updatingDay ||
          refreshingCalender
        }
      />
      <Modal
        open={showModal}
        onClose={closeModal}
        heading={id ? "Update Schedule" : "Add Event"}
        confirmText={id ? "Update" : "Add"}
        onConfirm={onConfirm}
      >
        <div className="flex flex-col gap-4">
          <TimeInput
            label="Start time"
            type="time"
            className="shadow-none"
            placeholder="Start Time"
            value={inputValues.start}
            onChange={(time, timeString) => {
              setInputValues({ ...inputValues, start: timeString });
            }}
            selectedTimeSlots={selectedTimeSlots}
          />
          <TimeInput
            label="End time"
            type="time"
            className="shadow-none"
            placeholder="End Time"
            value={inputValues.end}
            onChange={(time, timeString) =>
              setInputValues({ ...inputValues, end: timeString })
            }
            selectedTimeSlots={selectedTimeSlots}
          />
          <Input
            label="Title"
            className="shadow-none"
            placeholder="Schedule Title"
            value={inputValues.title}
            onChange={(e) =>
              setInputValues({ ...inputValues, title: e.target.value })
            }
          />
        </div>
      </Modal>
    </>
  );
}

function Schedule({
  currentDay,
  calender,
  schedule,
  onRemove,
  day,
  selectedTimeSlots,
}) {
  const { title, start, end } = schedule;
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <SchduleModal
        showModal={showModal}
        setShowModal={setShowModal}
        currentDay={currentDay}
        calender={calender}
        inputData={{ title, start, end }}
        id={schedule._id}
        day={day}
        selectedTimeSlots={selectedTimeSlots}
      />
      <div
        className="flex items-center justify-between bg-white px-3 py-2 my-2 border border-theme-gray w-full"
        onClick={() => setShowModal(true)}
      >
        <div className="w-fit flex items-center pr-2 border-r border-r-theme-gray">
          <p className="text-xs text-[#787878]">{tConvert(start)}</p>
          &nbsp;-&nbsp;
          <p className="text-xs text-[#787878]">{tConvert(end)}</p>
        </div>
        <div className="w-full">
          <h3 className="font-medium text-sm pl-2">{title}</h3>
        </div>
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
        >
          <CrossIcon fill="#545F71" />
        </div>
      </div>
    </>
  );
}

export default function DailySchedule({
  currentCarrier,
  calender,
  institute,
  majorId,
}) {
  const [currentDay, setCurrentDay] = useState(0);
  const sliderRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [calenderTemplate, setCalenderTemplate] = useState({
    title: "",
    showModal: false,
  });
  const [showDeleteCalenderTemplate, setShowDeleteCalenderTemplate] =
    useState(null);

  const { mutate: refreshCalender } = useRefreshCareerCalender();
  const { mutate: updateDay } = useUpdateDay();
  const {
    mutateAsync: addCalenderTemplate,
    isLoading: loadingCalenderTemplate,
  } = useAddCalenderTemplate();
  const { mutateAsync: cloneCalender } = useCloneCareerCalender();
  const { data: calenderTemplateList = [] } = useGetCalenderTemplate(institute);
  const {
    mutateAsync: deleteCalenderTemplate,
    isLoading: deletingCalenderTempate,
  } = useDeleteCalenderTemplate(institute);

  const daysDetails = calender?.days?.filter(
    (ele) => ele.day_of_week == currentDay
  )[0];

  let sortedSchedule =
    daysDetails?.schedule?.slice().sort((a, b) => {
      const aTime = a.start.split(":");
      const bTime = b.start.split(":");
      return aTime[0] - bTime[0] || aTime[1] - bTime[1];
    }) || [];

  const selectedTimeSlots = sortedSchedule.map((ele) => ({
    start: ele.start,
    end: ele.end,
  }));

  const pagination = {
    clickable: true,
  };

  const templateActionHandler = async () => {
    const templateTitle = calenderTemplate.title;
    setCalenderTemplate({ title: "", showModal: false });
    if (daysDetails?.schedule?.length !== 0) {
      let response = await addCalenderTemplate({
        title: templateTitle,
        calender: JSON.stringify(calender),
        institution: institute,
      });
      if (response.statusCode === 201) {
        refreshCalender(calender._id);
      }
    }
  };

  const applyTemplateCalender = async (templateId) => {
    if (templateId) {
      cloneCalender({
        data: {
          templateId: templateId,
          instituteId: institute,
          calenderId: calender._id,
        },
        callback: () => refreshCalender(calender._id),
      });
    }
  };

  return (
    <>
      <BlockLoader open={loadingCalenderTemplate || deletingCalenderTempate} />
      <Modal
        open={showDeleteCalenderTemplate}
        heading="Delete Template"
        onCancel={() => setShowDeleteCalenderTemplate(null)}
        onConfirm={() => {
          deleteCalenderTemplate(showDeleteCalenderTemplate?._id);
          setShowDeleteCalenderTemplate(null);
        }}
      >
        <p className="text-sm">
          Are you sure you want to delete calender template{" "}
          <span className="font-semibold">
            {showDeleteCalenderTemplate?.title}
          </span>
          ?
        </p>
      </Modal>
      <Modal
        heading="Add Template Title"
        open={calenderTemplate.showModal}
        onCancel={() => setCalenderTemplate({ title: "", showModal: false })}
        onConfirm={templateActionHandler}
      >
        <Input
          label="Template Title"
          className="shadow-none"
          placeholder="Template Title"
          value={calenderTemplate.title}
          onChange={(e) =>
            setCalenderTemplate({ ...calenderTemplate, title: e.target.value })
          }
        />
      </Modal>
      <SchduleModal
        showModal={showModal}
        setShowModal={setShowModal}
        currentDay={currentDay}
        calender={calender}
        day={daysDetails}
        selectedTimeSlots={selectedTimeSlots}
      />
      <div className="flex items-center justify-between">
        <Label label="Daily schedule" />
        <div className="flex items-center gap-1.5 my-4">
          {days.map((item, index) => (
            <div
              className={twJoin(
                "rounded-md w-6 h-7 cursor-pointer flex items-center justify-center text-sm font-bold mx-auto transition-all duration-200 ease-in-out",
                index === currentDay
                  ? "bg-theme-yellow text-white"
                  : "text-theme-purple bg-[#DEE2FB]"
              )}
              key={index}
              onClick={() => {
                setCurrentDay(index);
                sliderRef.current.swiper.slideTo(index);
              }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div>
        {calender?.calenderTemplate ? (
          <p className="mb-3 font-semibold text-lg">
            {calender?.calenderTemplate?.title}
          </p>
        ) : (
          <div className="mb-3">
            <Select
              options={calenderTemplateList?.map((ele) => ({
                label: ele.title,
                value: ele._id,
                rightElement: (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteCalenderTemplate(ele);
                    }}
                  >
                    <CrossIcon />
                  </div>
                ),
              }))}
              onChange={applyTemplateCalender}
            />
          </div>
        )}
        <Swiper
          ref={sliderRef}
          pagination={pagination}
          modules={[Pagination]}
          className="max-w-[23dvw] h-full my-4"
          onSlideChange={(swiper) => {
            setCurrentDay(swiper.activeIndex);
          }}
        >
          {days.map((item, index) => (
            <SwiperSlide className="pb-3">
              <div className="flex items-center justify-between">
                <button
                  className="text-white bg-theme-purple px-4 flex items-center gap-3 py-1.5 rounded-xl"
                  onClick={() => setShowModal(true)}
                  title="Add Event"
                >
                  <CircleAddIcon />
                  Add Event
                </button>
                {calender?.days?.length ? (
                  calender?.calenderTemplate ? (
                    compareScheduleWithTemplate(calender) ? (
                      <FilledButton
                        className="bg-theme-yellow py-1.5 rounded-xl px-4"
                        onClick={templateActionHandler}
                        title="Update template"
                      >
                        <BookSave />
                        Update
                      </FilledButton>
                    ) : (
                      ""
                    )
                  ) : (
                    <FilledButton
                      className="bg-theme-yellow py-1.5 rounded-xl px-4"
                      onClick={() => setCalenderTemplate({ showModal: true })}
                      title="Save as template"
                    >
                      <BookSave />
                      Save
                    </FilledButton>
                  )
                ) : (
                  ""
                )}
              </div>
              {sortedSchedule?.length
                ? sortedSchedule?.map((item) => {
                    return (
                      <Schedule
                        day={daysDetails}
                        currentDay={currentDay}
                        calender={calender}
                        schedule={item}
                        selectedTimeSlots={selectedTimeSlots}
                        onRemove={() => {
                          updateDay({
                            data: {
                              schedule: daysDetails.schedule
                                .filter((_) => _._id !== item._id)
                                .map((_) => _._id),
                              id: daysDetails._id,
                            },
                            callback: () => refreshCalender(calender._id),
                          });
                        }}
                      />
                    );
                  })
                : ""}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
