export default function SortIcon({ fill = "#004E99", size = 24 }) {
  return (
    <>
      <svg
        width={size || "24"}
        height={size || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 7H21"
          stroke={fill || "#004E99"}
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M6 12H18"
          stroke={fill || "#004E99"}
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M10 17H14"
          stroke={fill || "#004E99"}
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
}
