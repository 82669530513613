import {
  CallIcon,
  ChatIcon,
  EmailIcon,
  MedalStarIcon,
  RotateIcon,
  SimpleKeyIcon,
} from "assets/icons";
import CircleIcon from "assets/icons/CircleIcon";
import InstituteImage from "assets/images/institute/institute-bg.png";
import { FilledButton } from "common/Button";
import { Link } from "react-router-dom";

export default function SingleInstitute({ institution, onClick, active }) {
  const {
    institution_title,
    institution_initials,
    subscription_type,
    contact_name,
    contact_phone,
    contact_email,
    profile_image,
    cover_image,
    renewal_date,
  } = institution;

  return (
    <div
      className={`max-w-[620px] rounded-md shadow-lg bg-white w-full ${
        active ? "outline outline-theme-yellow" : ""
      }`}
      onClick={onClick}
    >
      <div
        style={{
          backgroundImage: `url(${cover_image ? cover_image : InstituteImage})`,
        }}
        className="w-full h-40 bg-cover bg-center rounded-t-md"
      ></div>
      <div className="pt-3 pb-5 px-3">
        <div className="flex gap-2">
          <div className="w-36 h-36 rounded-full bg-cover bg-center bg-slate-200 -mt-20">
            {profile_image ? (
              <img
                src={profile_image}
                alt="profile"
                className="w-full h-full rounded-full object-contain"
              />
            ) : (
              <div className="w-full h-full rounded-full flex items-center justify-center">
                <p className="text-white text-5xl font-semibold">
                  {contact_name?.[0]}
                </p>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <h1 className="text-xl font-bold text-theme-blue">
              {institution_title}
            </h1>
            <div className="flex gap-3">
              <p className="text-xl font-light">{institution_initials}</p>
              <div className="flex gap-3 items-center">
                <CircleIcon fill="#10C440" />
                <p className="text-base font-light">
                  {new Date(renewal_date).getTime() < new Date().getTime()
                    ? "Expired"
                    : "Active"}
                </p>
              </div>
              <div className="flex gap-3 items-center">
                <RotateIcon fill="#000000" stroke={"1"} />
                <p className="text-base font-light">
                  Renew{" "}
                  {new Date(renewal_date).toLocaleDateString("en-US", {
                    month: "numeric",
                    year: "numeric",
                    day: "numeric",
                  })}
                </p>
              </div>
              <div className="flex gap-3 items-center">
                <MedalStarIcon fill="#000000" />
                <p className="text-base font-light">
                  Level {subscription_type}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-7">
          <Link
            to={"/institutions/" + institution._id}
            className="self-end cursor-pointer"
          >
            <FilledButton
              className={
                "px-4 bg-theme-yellow rounded-full py-1 whitespace-nowrap"
              }
            >
              View Details
            </FilledButton>
          </Link>
          <div className="flex gap-5">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1.5">
                <SimpleKeyIcon />
                <p className="text-[19px]">Keys</p>
              </div>
              <p className="font-light text-5xl">{institution?.total_keys || 0}</p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-light text-3xl">
                1,702<span className="text-base font-normal ml-2">Used</span>
              </p>
              <p className="font-light text-3xl">
                812<span className="text-base font-normal ml-2">Available</span>
              </p>
            </div>
          </div>
          <div className="flex gap-2.5">
            <div className="w-16 h-16 border border-theme-blue rounded-full bg-cover bg-center bg-slate-100 text-lg font-semibold flex items-center justify-center">
              {contact_name?.[0]}
            </div>
            <div>
              <h3 className="text-[17px] truncate text-ellipsis ">
                {contact_name}
              </h3>
              <p className="text-[10px] -mt-1">Admin</p>
              <div className="flex items-center gap-2 mt-1">
                <Link to={`tel:${contact_phone ?? 0}`}>
                  <CallIcon />
                </Link>
                <Link to={`mailto:${contact_email ?? ""}`}>
                  <EmailIcon />
                </Link>
                <ChatIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
