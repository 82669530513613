import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  batches: [],
  loaded: false,
  institution: "",
  limit: 10,
  nextPage: 1,
  hasNextPage: false,
  totalDocs: 0,
  totalPages: 0,
};

const batchesSlice = createSlice({
  name: "batches",
  initialState,
  reducers: {
    setBatches: (state, action) => {
      const {
        institution,
        batches,
        limit,
        nextPage,
        hasNextPage,
        totalDocs,
        totalPages,
      } = action.payload;
      state.batches = batches;
      state.loaded = true;
      state.institution = institution;
      state.limit = limit;
      state.nextPage = nextPage;
      state.hasNextPage = hasNextPage;
      state.totalDocs = totalDocs;
      state.totalPages = totalPages;
    },
    addBatch: (state, action) => {
      state.batches.push(action.payload);
    },
    updateBatch: (state, action) => {
      state.batches = state.batches.map((batch) =>
        batch._id === action.payload._id ? action.payload : batch
      );
    },
    deleteBatch: (state, action) => {
      state.batches = state.batches.filter(
        (batch) => batch._id !== action.payload
      );
    },
  },
});

export const { setBatches, addBatch, updateBatch, deleteBatch } =
  batchesSlice.actions;

export default batchesSlice.reducer;
