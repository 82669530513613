export default function BookSave({ width = 30, height = 24, color = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9681 4.6697V16.7397C26.9681 17.6997 26.047 18.5997 24.9134 18.7197L24.5237 18.7597C21.9495 19.0497 17.9818 20.1597 15.7146 21.2197C15.4075 21.3697 14.8998 21.3697 14.581 21.2197L14.5337 21.1997C12.2665 20.1497 8.31068 19.0497 5.74824 18.7597L5.40574 18.7197C4.27213 18.5997 3.35107 17.6997 3.35107 16.7397V4.65969C3.35107 3.46969 4.49647 2.5697 5.90168 2.6697C8.38147 2.8397 12.1366 3.89973 14.2385 5.00973L14.5337 5.15969C14.8761 5.33969 15.443 5.33969 15.7855 5.15969L15.9862 5.04971C16.7301 4.65971 17.6748 4.2697 18.7021 3.9197V7.99972L21.0638 6.6697L23.4255 7.99972V2.77975C23.7444 2.72975 24.0514 2.69971 24.3348 2.67971H24.4057C25.8109 2.57971 26.9681 3.4697 26.9681 4.6697Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.1594 5.49023V20.4902"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.4253 2.78027V8.00024L21.0636 6.67023L18.7019 8.00024V3.92023C20.2488 3.40023 21.9729 2.98027 23.4253 2.78027Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
