import { axios, useUtilities } from "helper";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  addMajorAcademy,
  setMajorAcademy,
  updateSingleMajorAcademy,
} from "redux/reducers/majorAcademy";

async function getMajorAcademy() {
  return await axios
    .get("majorAcademy")
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetMajorAcademy() {
  return useQuery(["majorAcademy"], getMajorAcademy);
}

export function useMajorAcademy() {
  const { data, isLoading, isError, error } = useGetMajorAcademy();
  const {
    dispatch,
    majorAcademy: { majorAcademy, loaded },
  } = useUtilities();

  useEffect(() => {
    if (data && !loaded) {
      dispatch(setMajorAcademy(data.data));
    }
  }, [data]);

  return {
    data: majorAcademy,
    isLoading: isLoading || !loaded,
    isError,
    error,
  };
}

export async function createMajorAcademy({ data, callback }) {
  return await axios
    .post("majorAcademy", data)
    .then((res) => {
      callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useAddMajorAcademy() {
  const { notification, dispatch } = useUtilities();
  return useMutation(createMajorAcademy, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addMajorAcademy(data.data));
      } else {
        notification({
          type: "error",
          message: "Failed to add academy",
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: "Failed to add academy",
      });
    },
  });
}

export async function updateMajorAcademy({ majorAcademyId, data }) {
  let formData = new FormData();
  if (data?.images?.length) {
    data.images.map((image) => {
      formData.append("images", image);
    });
    formData.append("courses", data.courses || []);
    formData.append("qna", data.qna || []);
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  } else {
    formData = data;
  }
  return await axios
    .put("majorAcademy/" + majorAcademyId, formData)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateMajorAcademy() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateMajorAcademy, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleMajorAcademy(data.data));
      } else {
        notification({
          type: "error",
          message: "Failed to update major academy",
        });
      }
    },
    onError: () => {
      notification({
        type: "error",
        message: "Failed to update major academy",
      });
    },
  });
}

async function addCouresToMajorAcademy({ id, data }) {
  return await axios
    .put(`majorAcademy/${id}/add-course`, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddCouresToMajorAcademy() {
  const { dispatch, notification } = useUtilities();
  return useMutation(addCouresToMajorAcademy, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleMajorAcademy(data.data));
      } else {
        notification({
          type: "error",
          message: "Failed to add course",
        });
      }
    },
    onError: () => {
      notification({
        type: "error",
        message: "Failed to add course",
      });
    },
  });
}

async function removeCouresFromMajorAcademy({ id, data }) {
  return await axios
    .put(`majorAcademy/${id}/remove-course`, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useRemoveCouresFromMajorAcademy() {
  const { dispatch, notification } = useUtilities();
  return useMutation(removeCouresFromMajorAcademy, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleMajorAcademy(data.data));
      } else {
        notification({
          type: "error",
          message: "Failed to remove course",
        });
      }
    },
    onError: () => {
      notification({
        type: "error",
        message: "Failed to remove course",
      });
    },
  });
}

export async function getSingleMajorAcademy({ id }) {
  return await axios
    .get(`majorAcademy/${id}`)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}
