import { twMerge } from "tailwind-merge";
import { InfoIcon } from "assets/icons";
import Tooltip from "./ToolTip";

export default function Label({
  label,
  className = "",
  tooltip = "",
  showTooltip = true,
  labelExtra,
  tooltipIcon = {
    width: "21",
    height: "27",
    fill: "#F29423",
  },
  required = false,
}) {
  return (
    <div className="flex items-center justify-between">
      <label
        className={twMerge(
          "flex items-center text-theme-blue text-lg font-normal mb-1",
          className
        )}
      >
        <span className="capitalize">{label}&nbsp;&nbsp;{required ? <span className="text-red-400 text-sm">*</span> : ""}</span>
        {showTooltip && (
          <Tooltip message={tooltip || label}>
            <InfoIcon {...tooltipIcon} />
          </Tooltip>
        )}
      </label>
      {labelExtra}
    </div>
  );
}
