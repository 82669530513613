import React from "react";

const ChevronRightIcon = ({ fill, width, height }) => {
  return (
    <svg
      width={width ?? "11"}
      height={height ?? "20"}
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99984 17.9201L2.47984 11.4001C1.70984 10.6301 1.70984 9.37008 2.47984 8.60008L8.99984 2.08008"
        stroke={fill ?? "#004E99"}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChevronRightIcon;
