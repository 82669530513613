import { EmptyGraphIcon } from "assets/icons";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

function StudentStat({data=[]}) {
  const [state] = useState({
    series: [
      ...data,
    //   {
    //     name: "PRODUCT A",
    //     data: [44, 55, 41, 67, 22, 43],
    //     color: "#43729E",
    // },
    // {
    //     name: "PRODUCT B",
    //     data: [13, 23, 20, 8, 13, 27],
    //     color: "#38E59E",
    //   },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            // legend: {
            //   position: "bottom",
            //   offsetX: -10,
            //   offsetY: 0,
            // },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  return (
    <div >
      {
        data.length === 0 ? (
          <div className="flex items-center justify-center flex-col gap-3 h-40 my-7">
            <EmptyGraphIcon />
            <p className="text-gray-600 text-sm">No data to show yet</p>
          </div>
        ) :
        <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
        />
      }
    </div>
  );
}

export default StudentStat;
