import { combineReducers } from "@reduxjs/toolkit";
import major from "./major";
import skills from "./skills";
import qna from "./qna";
import majorAcademy from "./majorAcademy";
import courses from "./courses";
import careers from "./careers";
import careerCalender from "./careerCalender";
import days from "./days";
import schedule from "./schedule";
import institutions from "./institutions";
import batches from "./batches";
import batchDetails from "./batchDetails";

const rootReducer = combineReducers({
  major,
  skills,
  qna,
  majorAcademy,
  courses,
  careers,
  careerCalender,
  days,
  schedule,
  institutions,
  batches,
  batchDetails,
});

export default rootReducer;
