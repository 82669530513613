import isEqual from "lodash/isEqual";

export function compareScheduleWithTemplate(schedule) {
    const { calenderTemplate, days } = schedule;
    const { days: templateDays } = JSON.parse(calenderTemplate.calender);
    if (days.length !== templateDays.length) return false;
    const daysNew = days.map((ele) => ({
      day_of_week: ele.day_of_week,
      schedule: ele.schedule.map((ele) => ({
        color: ele.color,
        title: ele.title,
        start: ele.start,
        end: ele.end,
      })),
    }));
  
    const templateDaysNew = templateDays.map((ele) => ({
      day_of_week: ele.day_of_week,
      schedule: ele.schedule.map((ele) => ({
        color: ele.color,
        title: ele.title,
        start: ele.start,
        end: ele.end,
      })),
    }));
    return !isEqual(daysNew, templateDaysNew);
  }
  