export default function SuitableIcon({ width = "15.583", height = "15.583" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15.583 15.583"
    >
      <g
        id="Group_139"
        data-name="Group 139"
        transform="translate(-15.333 -16.833)"
      >
        <g
          id="vuesax_linear_element-3"
          data-name="vuesax/linear/element-3"
          transform="translate(15.333 16.833)"
        >
          <g id="element-3">
            <path
              id="Vector"
              d="M3.835,5.737H1.185c-1.3,0-1.935-.58-1.935-1.773V1.023C-.75-.17-.117-.75,1.185-.75H3.808c1.3,0,1.935.58,1.935,1.773V3.97a1.7,1.7,0,0,1-.492,1.342A2.012,2.012,0,0,1,3.835,5.737ZM1.185.224c-.866,0-.961.256-.961.8V3.964c0,.555.091.8.961.8h2.65A1.2,1.2,0,0,0,4.6,4.589c.042-.038.17-.153.17-.618V1.023c0-.543-.095-.8-.961-.8Z"
              transform="translate(9.029 1.562)"
              fill="#292d32"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M3.808,5.743H1.185A1.7,1.7,0,0,1-.75,3.808V1.185A1.7,1.7,0,0,1,1.185-.75H3.808A1.7,1.7,0,0,1,5.743,1.185V3.808A1.7,1.7,0,0,1,3.808,5.743ZM1.185.224c-.763,0-.961.2-.961.961V3.808c0,.763.2.961.961.961H3.808c.763,0,.961-.2.961-.961V1.185c0-.763-.2-.961-.961-.961Z"
              transform="translate(9.029 9.029)"
              fill="#292d32"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M3.835,5.737H1.185c-1.3,0-1.935-.58-1.935-1.773V1.023C-.75-.17-.117-.75,1.185-.75H3.808c1.3,0,1.935.58,1.935,1.773V3.97a1.7,1.7,0,0,1-.492,1.342A2.012,2.012,0,0,1,3.835,5.737ZM1.185.224c-.866,0-.961.256-.961.8V3.964c0,.555.091.8.961.8h2.65A1.2,1.2,0,0,0,4.6,4.589c.042-.038.17-.153.17-.618V1.023c0-.543-.095-.8-.961-.8Z"
              transform="translate(1.562 1.562)"
              fill="#292d32"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M3.808,5.743H1.185A1.7,1.7,0,0,1-.75,3.808V1.185A1.7,1.7,0,0,1,1.185-.75H3.808A1.7,1.7,0,0,1,5.743,1.185V3.808A1.7,1.7,0,0,1,3.808,5.743ZM1.185.224c-.763,0-.961.2-.961.961V3.808c0,.763.2.961.961.961H3.808c.763,0,.961-.2.961-.961V1.185c0-.763-.2-.961-.961-.961Z"
              transform="translate(1.562 9.029)"
              fill="#292d32"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M0,0H15.583V15.583H0Z"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
