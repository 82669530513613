import { CustomRadio } from "common/Input";
import { useState, cloneElement, useMemo, useEffect } from "react";
import {
  CountrySelect,
  CustomPhoneInput,
  InstituteInput,
  InstituteSelect,
} from "../CustomInput";
import Divider from "common/Divider";
import { twMerge } from "tailwind-merge";
import { useUtilities, imgLink } from "helper";
import {
  useGetInstituteStaticData,
  generateAdminKey,
  useInstituteInitialAvailable,
} from "api/institution";
import { useParams } from "react-router-dom";

const requiredFields = [
  "account_information",
  "institution_type",
  "institution_title",
  "institution_initials",
  // "admin_key",
  "subscription_type",
  "contact_name",
  "contact_email",
  "contact_phone",
  "website",
  "country",
  "city",
  "address",
  "apply_url",
];

export default function BasicInformation({ footer, updateData, institution }) {
  const { institutionId } = useParams();
  const { notification } = useUtilities();
  const { data } = useGetInstituteStaticData();
  const [inputValues, setInputValues] = useState(
    institutionId !== "add"
      ? {
          account_information: institution?.account_information,
          institution_type: institution?.institution_type,
          institution_title: institution?.institution_title,
          institution_initials: institution?.institution_initials,
          admin_key: institution?.admin_key,
          subscription_type: institution?.subscription_type,
          contact_name: institution?.contact_name,
          contact_email: institution?.contact_email,
          contact_phone: institution?.contact_phone,
          website: institution?.website,
          facebook_page: institution?.facebook_page,
          instagram_page: institution?.instagram_page,
          linkedin_page: institution?.linkedin_page,
          country: institution?.country,
          city: institution?.city,
          address: institution?.address,
          postal_code: institution?.postal_code,
          prefer_language: institution?.prefer_language,
          institution_size: institution?.institution_size,
          default_currency: institution?.default_currency[0] ?? "",
          cover_image: institution?.cover_image,
          profile_image: institution?.profile_image,
          apply_url: institution?.apply_url,
        }
      : {
          account_information: "public",
          institution_type: "",
          institution_title: "",
          institution_initials: "",
          admin_key: "",
          subscription_type: "",
          contact_name: "",
          contact_email: "",
          contact_phone: "",
          website: "",
          facebook_page: "",
          instagram_page: "",
          linkedin_page: "",
          country: "",
          city: "",
          address: "",
          postal_code: "",
          prefer_language: [],
          institution_size: "",
          default_currency: "usd",
          cover_image: "",
          profile_image: "",
          apply_url: "",
        }
  );

  const [coverImageUrl, setCoverImageUrl] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);

  useEffect(() => {
    if (inputValues.cover_image) {
      const url = imgLink(inputValues.cover_image);
      setCoverImageUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [inputValues.cover_image]);

  useEffect(() => {
    if (inputValues.profile_image) {
      const url = imgLink(inputValues.profile_image);
      setProfileImageUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [inputValues.profile_image]);

  const { data: isInitialavailable } = useInstituteInitialAvailable(
    inputValues?.institution_initials
  );

  const handleChangeInput = (name, value) => {
    setInputValues(pre => ({ ...pre, [name]: value }));
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    for (const key in inputValues) {
      if (requiredFields.includes(key) && inputValues[key] === "") {
        return notification({
          type: "error",
          message: `Please fill ${key.split("_").join(" ")} field`,
        });
      }
    }
    updateData(inputValues);
  };

  const disabledNextButton = () => {
    for (const key in inputValues) {
      if (requiredFields.includes(key) && inputValues[key] === "") {
        return true;
      }
    }
    return false;
  };

  const getAdminKey = () => {
    generateAdminKey({
      initials: inputValues.institution_initials,
    }).then((res) => {
      if (res?.statusCode === 201) {
        handleChangeInput("admin_key", res.data);
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmitData}>
        <label
          htmlFor="cover_image"
          className=" max-w-4xl w-full flex items-center justify-center h-40 border border-[#BBC4FF] bg-white"
          style={{
            backgroundImage: `url(${
              coverImageUrl ||
              require("assets/images/institute/institute-bg.png")
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {!coverImageUrl && (
            <p className="text-[#818181] font-light">Choose Cover Image</p>
          )}
        </label>
        <input
          type="file"
          className="hidden"
          id="cover_image"
          onChange={(e) => handleChangeInput("cover_image", e.target.files[0])}
        />
        <input
          type="file"
          className="hidden"
          id="profil_image"
          onChange={(e) =>
            handleChangeInput("profile_image", e.target.files[0])
          }
        />
        <label
          htmlFor="profil_image"
          className="flex items-center justify-center w-32 rounded-full h-32 border border-[#BBC4FF] -mt-20 ml-6 bg-white"
          style={{
            backgroundImage: `url(${
              profileImageUrl ||
              require("assets/images/institute/institute-logo.png")
            })`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {!inputValues.profile_image && (
            <p className="text-[#818181] max-w-[100px] text-sm font-light text-center">
              Choose Profile Image
            </p>
          )}
        </label>
        <div className="my-5">
          <h2 className="font-medium">Account Information</h2>
          <div className="flex items-center gap-5 my-5">
            <CustomRadio
              label="Public"
              name="account"
              value="public"
              checked={inputValues.account_information === "public"}
              onChange={(e) =>
                handleChangeInput("account_information", e.target.value)
              }
              id="public"
            />
            <CustomRadio
              label="Private"
              name="account"
              checked={inputValues.account_information === "private"}
              onChange={(e) =>
                handleChangeInput("account_information", "private")
              }
              id="private"
              value="private"
            />
            <CustomRadio
              label="Other"
              name="account"
              checked={inputValues.account_information === "other"}
              onChange={(e) =>
                handleChangeInput("account_information", "other")
              }
              id="other"
              value="other"
            />
          </div>
          <div className="container flex flex-wrap gap-6">
            <InstituteSelect
              tittle="Institution Type"
              required
              options={Object.keys(data?.institution_type ?? {}).map((key) => ({
                label: data?.institution_type[key],
                value: key,
              }))}
              value={inputValues.institution_type}
              onChange={(value) => handleChangeInput("institution_type", value)}
            />
            <InstituteInput
              label="Institution Title"
              value={inputValues.institution_title}
              onChange={(e) =>
                handleChangeInput("institution_title", e.target.value)
              }
              required
            />
            <InstituteInput
              label="Institution Initials"
              value={inputValues.institution_initials}
              onChange={(e) => {
                handleChangeInput(
                  "institution_initials",
                  e.target.value.trim().split(" ").join("")
                  );
                  if (inputValues.admin_key) {
                    handleChangeInput("admin_key", "");
                  }
                }
              }
              labelExtra={
                <p
                  className={twMerge(
                    "text-[8px] font-bold text-white bg-[#A9A9A9] px-2 rounded py-1 mb-1 transition-all duration-300 ease-in-out",
                    isInitialavailable && "bg-theme-yellow cursor-pointer"
                  )}
                  onClick={getAdminKey}
                >
                  Generate Admin Key
                </p>
              }
              showTooltip
              tooltipIcon={{
                width: "18",
                height: "18",
                fill: "#828282",
              }}
              required
            />
            <InstituteInput
              label="Admin Key"
              placeholder="Auto Generated"
              className="placeholder:text-sm placeholder:font-light"
              disabled
              value={inputValues.admin_key}
              labelExtra={
                <p
                  className={twMerge(
                    "text-[8px] font-bold text-white bg-[#A9A9A9] px-2 rounded py-1 mb-1 transition-all duration-300 ease-in-out",
                    inputValues.admin_key && "bg-theme-yellow cursor-pointer"
                  )}
                  onClick={() => {
                    navigator.clipboard.writeText(inputValues.admin_key);
                    notification({
                      type: "success",
                      message: "Copied to clipboard",
                    });
                  }}
                >
                  Copy
                </p>
              }
            />
            <InstituteSelect
              tittle="Subscription Type"
              required
              options={Object.keys(data?.subscription_type ?? {}).map(
                (key) => ({
                  label: `Level ${data?.subscription_type[key]}`,
                  value: key,
                })
              )}
              value={inputValues.subscription_type}
              onChange={(value) =>
                handleChangeInput("subscription_type", value)
              }
            />
            <InstituteInput
              label="Contact Name"
              value={inputValues.contact_name}
              onChange={(e) =>
                handleChangeInput("contact_name", e.target.value)
              }
              required
            />
            <InstituteInput
              label="Contact Email"
              value={inputValues.contact_email}
              onChange={(e) =>
                handleChangeInput("contact_email", e.target.value)
              }
              type="email"
              required
            />
            <CustomPhoneInput
              label="Contact Phone"
              placeholder="XXX-XXXX-XXXX"
              className="placeholder:text-sm placeholder:font-light"
              containerClass="gf"
              dropdownClass="min-w-[240px] max-w-[243px] w-full custom-scroll"
              inputClass="shadow-none border !border-[#828282] !py-[2.5px] min-w-[240px] max-w-[243px] w-full"
              buttonStyle={{
                height: "35px",
              }}
              value={inputValues.contact_phone}
              onChange={(value) => handleChangeInput("contact_phone", value)}
              required
            />
          </div>
          <Divider className="h-[1px] bg-[#E0E0E0] mt-3" />
        </div>
        <h2 className="font-medium">Social Links</h2>
        <div className="container flex flex-wrap gap-6 my-5">
          <InstituteInput
            label="Website"
            value={inputValues.website}
            onChange={(e) => handleChangeInput("website", e.target.value)}
            required
          />
          <InstituteInput
            label="Facebook Page"
            value={inputValues.facebook_page}
            onChange={(e) => handleChangeInput("facebook_page", e.target.value)}
          />
          <InstituteInput
            label="Instagram Page"
            value={inputValues.instagram_page}
            onChange={(e) =>
              handleChangeInput("instagram_page", e.target.value)
            }
          />
          <InstituteInput
            label="LinkedIn Page"
            value={inputValues.linkedin_page}
            onChange={(e) => handleChangeInput("linkedin_page", e.target.value)}
          />
        </div>
        <Divider className="h-[1px] bg-[#E0E0E0] mt-3 mb-5" />
        <h2 className="font-medium">Physical Address</h2>
        <div className="container flex flex-wrap gap-6 my-5">
          <CountrySelect
            value={inputValues.country}
            onChange={(value) => handleChangeInput("country", value)}
            required
          />
          <InstituteInput
            label="City"
            value={inputValues.city}
            onChange={(e) => handleChangeInput("city", e.target.value)}
            required
          />
          <InstituteInput
            label="Address"
            value={inputValues.address}
            onChange={(e) => handleChangeInput("address", e.target.value)}
            required
          />
          <InstituteInput
            label="Postal Code"
            value={inputValues.postal_code}
            onChange={(e) => handleChangeInput("postal_code", e.target.value)}
          />
        </div>
        <Divider className="h-[1px] bg-[#E0E0E0] mt-3 mb-5" />
        <h2 className="font-medium">Other</h2>
        <div className="container flex flex-wrap gap-6 my-5">
          <InstituteSelect
            tittle="Prefer Languages"
            label="Choose Language"
            options={Object.keys(data?.preferred_languages ?? {}).map(
              (key) => ({
                label: data?.preferred_languages[key],
                value: key,
              })
            )}
            value={inputValues.prefer_language}
            onChange={(value) => {
              if (inputValues.prefer_language.includes(value)) {
                handleChangeInput(
                  "prefer_language",
                  inputValues.prefer_language.filter((ele) => ele !== value)
                );
              } else {
                handleChangeInput("prefer_language", [...inputValues.prefer_language, value]);
              } 
            }}
            multipleSelect
            onAllClear={() => handleChangeInput("prefer_language", [])}
          />
          <InstituteSelect
            tittle="Institution Size"
            options={Object.keys(data?.institution_size ?? {}).map((key) => ({
              label: data?.institution_size[key],
              value: key,
            }))}
            value={inputValues.institution_size}
            onChange={(value) => handleChangeInput("institution_size", value)}
          />
          <InstituteSelect
            tittle="Default Currency"
            label="Choose Currency"
            options={Object.keys(data?.currency ?? {}).map((key) => ({
              label: data?.currency[key],
              value: key,
            }))}
            value={inputValues.default_currency}
            onChange={(value) => handleChangeInput("default_currency", value)}
          />
          <InstituteInput
            label="Apply URL"
            value={inputValues.apply_url}
            onChange={(e) => handleChangeInput("apply_url", e.target.value)}
            required
          />
        </div>
        {cloneElement(footer, {
          disabledNextButton: disabledNextButton(),
        })}
      </form>
    </>
  );
}
