export function printError(str = "") {
  return str || "Something went wrong, please try again later";
}

export function getMediaName(media="") {
  return media?.split("?")[0].split("/").pop();
}

export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}