import { useState } from "react";
import { UploadIcon } from "assets/icons";
import { twMerge } from "tailwind-merge";
import { useUtilities } from "helper";

export default function MediaInput({
  id,
  onChange,
  multiple,
  allowedTypes = [],
  className,
  text = "Drag and drop or browse file (JPG, JPEG, PNG)",
  iconSize = "48.811",
  ...rest
}) {
  const [dragging, setDragging] = useState(false);
  const { notification } = useUtilities();

  const handleOnChange = (e) => {
    const input = e.target;
    if (!input.files) return;
    if (allowedTypes.length) {
      const files = Array.from(input.files);
      const allowed = files.filter((file) => {
        if (allowedTypes.includes(file.name.split(".").pop().toLowerCase())) {
          return true;
        } else {
          notification({
            message: `File type ${file.name
              .split(".")
              .pop()
              .toLowerCase()} is not allowed`,
            type: "error",
          });
          return false;
        }
      });
      if (allowed.length) {
        onChange(multiple ? allowed : allowed[0]);
      }
    } else {
      onChange(multiple ? input.files : input.files[0]);
    }
    input.value = "";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    if (allowedTypes.length) {
      const allowed = files.filter((file) =>
        allowedTypes.includes(file.name.split(".").pop().toLowerCase())
      );
      if (allowed.length) {
        onChange(multiple ? allowed : allowed[0]);
      }
    } else {
      onChange(multiple ? files : files[0]);
    }
  };

  return (
    <>
      <div className="shadow p-3 w-fit rounded-3xl  mt-4 bg-white">
        <div
          className={twMerge(
            `p-4 bg-white rounded-2xl max-w-sm w-full ${
              dragging
                ? "border-2 border-blue-500"
                : "border border-dashed border-[#707070]"
            }
        `,
            className || ""
          )}
        >
          <label
            htmlFor={id ?? "media-input"}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="w-full rounded-2xl bg-white flex flex-col py-6 items-center justify-center">
              <UploadIcon size={iconSize} />
              <label className="text-center text-[10px] mt-2">{text}</label>
            </div>
          </label>
          <input
            id={id ?? "media-input"}
            type="file"
            multiple={!!multiple}
            className="hidden"
            hidden
            onChange={handleOnChange}
            {...rest}
          />
        </div>
      </div>
    </>
  );
}
