import { CrossIcon } from "assets/icons";
import { twJoin } from "tailwind-merge";

export default function MediaPreview({ url, className, onRemove, ...rest }) {
  return (
    <>
      <div className="relative bg-white">
        <div
          className="absolute -top-2 -left-2 w-fit h-fit bg-white border border-black rounded-full p-1 cursor-pointer"
          onClick={onRemove}
        >
          <CrossIcon />
        </div>
        <img
          src={url}
          alt="media"
          className={twJoin("w-24 h-24 border border-theme-gray rounded-lg object-cover", className || "")}
          {...rest}
        />
      </div>
    </>
  );
}
