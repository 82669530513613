import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useUserAuth } from "context/authProvider";
import UserAuth from "pages/UserAuth/index";
import "@fontsource-variable/heebo";
import Maintinance from "pages/Maintinance";
import Dashboard from "./pages/Home";
import { clarity } from "react-microsoft-clarity";

function App() {
  const { user } = useUserAuth();
  clarity.init(process.env.REACT_APP_CLARITY_ID);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={user ? <Dashboard /> : <Navigate to="/user-auth/login" />}
        />
        <Route
          path="/user-auth/*"
          element={user ? <Navigate to="/" /> : <UserAuth />}
        />
        <Route path="/maintinance" element={<Maintinance />} />
        <Route
          path="/*"
          element={user ? <Dashboard /> : <Navigate to="/user-auth/login" />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
