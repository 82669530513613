import { axios, useUtilities } from "helper";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  addCareerCalender,
  setCareerCalender,
  updateSingleCareerCalender,
} from "redux/reducers/careerCalender";

async function getCareerCalender(instituteId) {
  return axios
    .get("career-calander", {
      params: {
        instituteId,
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetCareerCalender(instituteId) {
  return useQuery(["career-calander", instituteId], () =>
    getCareerCalender(instituteId)
  );
}

export function useCareerCalender(instituteId) {
  const { data, isLoading, isError, error } = useGetCareerCalender(instituteId);
  const {
    dispatch,
    careerCalender: { careerCalender, institute },
  } = useUtilities();
  useEffect(() => {
    if (data && instituteId !== institute) {
      dispatch(
        setCareerCalender({
          careerCalender: data.data,
          institute: instituteId,
        })
      );
    }
  }, [data, instituteId]);
  return {
    data: careerCalender,
    isLoading: isLoading,
    isError,
    error,
  };
}

async function createCareerCalender({ data, callback }) {
  return await axios
    .post("career-calander", data)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useAddCareerCalender() {
  const { notification, dispatch } = useUtilities();
  return useMutation(createCareerCalender, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addCareerCalender(data.data));
        notification({
          message: "CareerCalender added successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to add careerCalender",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to add careerCalender",
        type: "error",
      });
    },
  });
}

async function cloneCareerCalender({ data, callback }) {
  return await axios
    .post("career-calander/clone", data)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useCloneCareerCalender() {
  const { notification, dispatch } = useUtilities();
  return useMutation(cloneCareerCalender, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addCareerCalender(data.data));
        notification({
          message: "CareerCalender added successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to add careerCalender",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to add careerCalender",
        type: "error",
      });
    },
  });
}

async function updateCareerCalender({ data, callback }) {
  const { id, ...rest } = data;
  return await axios
    .put(`career-calander/${id}`, rest)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useUpdateCareerCalender() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateCareerCalender, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleCareerCalender(data.data));
        notification({
          message: "CareerCalender updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to update careerCalender",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to update careerCalender",
        type: "error",
      });
    },
  });
}

async function refreshCareerCalender(id) {
  return await axios
    .get(`career-calander/${id}`)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useRefreshCareerCalender() {
  const { notification, dispatch } = useUtilities();
  return useMutation(refreshCareerCalender, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleCareerCalender(data.data));
        notification({
          message: "CareerCalender updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to update careerCalender",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to update careerCalender",
        type: "error",
      });
    },
  });
}
