export default function KeySquareIcon({
  height = "25",
  width = "24",
  fill = "white",
  className = "",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 22.5H15C20 22.5 22 20.5 22 15.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2802 14.1106C15.1502 15.2406 13.5302 15.5906 12.1002 15.1406L9.5102 17.7206C9.3302 17.9106 8.9602 18.0306 8.6902 17.9906L7.4902 17.8306C7.0902 17.7806 6.7302 17.4006 6.6702 17.0106L6.5102 15.8106C6.4702 15.5506 6.6002 15.1806 6.7802 14.9906L9.3602 12.4106C8.9202 10.9806 9.2602 9.36063 10.3902 8.23063C12.0102 6.61063 14.6502 6.61063 16.2802 8.23063C17.9002 9.84063 17.9002 12.4806 16.2802 14.1106Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.4496 16.7799L9.59961 15.9199"
        stroke={fill}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3949 11.1992H13.4039"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
