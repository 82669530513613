export default function AddIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g
          id="vuesax_linear_add-circle"
          data-name="vuesax/linear/add-circle"
          transform="translate(-108 -252)"
        >
          <g id="add-circle">
            <path
              id="Vector"
              d="M10,20A10,10,0,1,0,0,10,10.029,10.029,0,0,0,10,20Z"
              transform="translate(110 254)"
              fill="#fff"
              stroke="#f29423"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H8"
              transform="translate(116 264)"
              fill="#fff"
              stroke="#f29423"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,8V0"
              transform="translate(120 260)"
              fill="#fff"
              stroke="#f29423"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0H24V24H0Z"
              transform="translate(108 252)"
              fill="#fff"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
