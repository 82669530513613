import { axios, useUtilities } from "helper";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import { createInstitution, editInstitution, setInstitutions } from "redux/reducers/institutions";
import { useEffect } from "react";

async function getInstituteStaticData() {
  return axios
    .get("staticData", {
      params: {
        dataIDs: JSON.stringify([
          "institution_type",
          "subscription_type",
          "institution_size",
          "duplicated_majors",
          "duplicated_careers",
          "personal_ai",
          "preferred_languages",
          "institue_terms_of_use",
          "institue_privacy_policy",
          "currency",
        ]),
      },
    })
    .then((res) => {
      let data = {};
      res.data.forEach((item) => {
        data[item.dataID] = item.data;
      });
      return data;
    })
    .catch((err) => new Error(err));
}

export function useGetInstituteStaticData() {
  return useQuery(["staticData"], getInstituteStaticData);
}

export function generateAdminKey({ initials }) {
  return axios
    .post("institute/generateAdminKey", {
      initials,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useInstituteInitialAvailable(initials) {
  const debouncedInitials = useDebounce(initials, 1000);
  return useQuery(
    ["instituteInitialAvailable", debouncedInitials],
    () =>
      axios
        .get("institute/" + debouncedInitials)
        .then((res) => {
          if (res?.data?.data) {
            return false;
          }
          return true;
        })
        .catch((err) => {
          throw new Error(err);
        }),
    {
      enabled: Boolean(debouncedInitials),
    }
  );
}

async function addInstiute(data) {
  return axios
    .post("institute", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddInstitute() {
  const { notification, navigate, dispatch } = useUtilities();
  return useMutation(addInstiute, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(createInstitution(data.data));
        notification({
          type: "success",
          message: "Institute added successfully",
        });
        navigate("/institutions");
      } else {
        notification({
          type: "error",
          message: data.message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

async function updateInstitute({id, data}) {
  return axios
    .put("institute/" + id, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateInstitute() {
  const { notification, navigate, dispatch } = useUtilities();
  return useMutation(updateInstitute, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        notification({
          type: "success",
          message: "Institute updated successfully",
        });
        dispatch(editInstitution(data.data));
        navigate("/institutions");
      } else {
        notification({
          type: "error",
          message: data.message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

async function getInstitutes() {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  return axios
    .get("institute")
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetInstitutes() {
  return useQuery(["institutes"], getInstitutes);
}

export function useInstitutions() {
  const { data, isLoading, isError, error } = useGetInstitutes();
  const { institutions : { institutions, loaded }, dispatch } = useUtilities();

  useEffect(() => {
    if (loaded) return;
    if (!isLoading && !isError && data?.data) {
      dispatch(setInstitutions(data?.data));
    }
  }, [data, isLoading, isError, loaded]);

  return {
    data: institutions,
    isLoading: isLoading || !loaded,
    isError,
    error,
  };
}
