export default function CrossIcon({ height, width, fill, ...rest }) {
  return (
    <>
      <svg
        width={width || "11"}
        height={height || "10"}
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M1.29871 0.201292C1.15653 0.0688116 0.968487 -0.00331137 0.774186 0.000116847C0.579884 0.00354506 0.394499 0.0822568 0.257086 0.21967C0.119673 0.357083 0.0409614 0.542468 0.0375332 0.736769C0.034105 0.93107 0.106228 1.11912 0.238708 1.26129L3.95871 4.98129L0.238708 8.70129C0.165021 8.76995 0.105919 8.85275 0.064927 8.94475C0.023935 9.03675 0.00189351 9.13607 0.000116722 9.23677C-0.00166006 9.33747 0.0168643 9.4375 0.0545854 9.53089C0.0923064 9.62428 0.148451 9.70911 0.21967 9.78033C0.290888 9.85155 0.375722 9.90769 0.46911 9.94541C0.562499 9.98314 0.662528 10.0017 0.763231 9.99988C0.863934 9.99811 0.963247 9.97606 1.05525 9.93507C1.14725 9.89408 1.23005 9.83498 1.29871 9.76129L5.01871 6.04129L8.73871 9.76129C8.80737 9.83498 8.89017 9.89408 8.98217 9.93507C9.07417 9.97606 9.17348 9.99811 9.27419 9.99988C9.37489 10.0017 9.47492 9.98314 9.56831 9.94541C9.66169 9.90769 9.74653 9.85155 9.81775 9.78033C9.88896 9.70911 9.94511 9.62428 9.98283 9.53089C10.0206 9.4375 10.0391 9.33747 10.0373 9.23677C10.0355 9.13607 10.0135 9.03675 9.97249 8.94475C9.9315 8.85275 9.87239 8.76995 9.79871 8.70129L6.07871 4.98129L9.79871 1.26129C9.93119 1.11912 10.0033 0.93107 9.99988 0.736769C9.99646 0.542468 9.91774 0.357083 9.78033 0.21967C9.64292 0.0822568 9.45753 0.00354506 9.26323 0.000116847C9.06893 -0.00331137 8.88088 0.0688116 8.73871 0.201292L5.01871 3.92129L1.29871 0.201292Z"
          fill={fill || "black"}
        />
      </svg>
    </>
  );
}
