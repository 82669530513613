import { useUtilities } from "helper";
import { useUser } from "./useUser";

export const useSelectedInstitution = () => {
  const { data } = useUser();
  const {
    institutions: { selectedInstitution },
  } = useUtilities();
  return selectedInstitution || data?.institution;
};
