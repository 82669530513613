import Label from "common/Label";
import React, { forwardRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import emailAvatar from "assets/images/email-avatar.png";
import { CrossIcon } from "assets/icons";

function Input(
  {
    className = "",
    note = "",
    label = "",
    labelClass = "",
    type = "text",
    value,
    limit = 1000,
    showTooltip = true,
    labelExtra,
    tooltipIcon,
    required = false,
    onChange,
    ...rest
  },
  ref
) {
  const [inputFocused, setInputFocused] = useState(false);
  return (
    <div>
      {label && (
        <Label
          className={labelClass}
          label={label}
          showTooltip={showTooltip}
          labelExtra={labelExtra}
          tooltipIcon={tooltipIcon}
          required={required}
        />
      )}
      {type === "textarea" ? (
        <textarea
          ref={ref}
          value={value}
          className={twMerge(
            `shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline`,
            className
          )}
          {...rest}
          onChange={(e) => {
            if (limit && e.target.value.length > limit) return;
            onChange(e);
          }}
          required={required}
        />
      ) : type === "emaillist" ? (
        <div
          className="border border-[#828282] rounded flex gap-2 flex-wrap max-h-20 overflow-y-auto"
          onFocus={() => setInputFocused(true)}
          onFocusCapture={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
        >
          {/* {inputFocused ? (
            <>
            {
              value.split(",").filter(email => isValidEmail(email)).map((email) => (<UserCard mail={email} />))
            }
            </>
          ) : (
            ""
          )} */}
          <input
            ref={ref}
            value={value}
            type={type}
            className={`${twMerge(
              `shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline`,
              className
            )} border-none`}
            required={required}
            {...rest}
            onChange={(e) => {
              if (limit && e.target.value.length > limit) return;
              onChange(e);
            }}
          />
        </div>
      ) : (
        <input
          ref={ref}
          value={value}
          type={type}
          className={twMerge(
            `shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline`,
            className
          )}
          required={required}
          {...rest}
          onChange={(e) => {
            if (limit && e.target.value.length > limit) return;
            onChange(e);
          }}
        />
      )}
      {type === "textarea" && (
        <p className="text-sm text-right">
          {value?.length || 0}/{limit}
        </p>
      )}
      {note && <p className="text-xs text-gray-600 italic">{note}</p>}
    </div>
  );
}

Input.propTypes = {
  className: String,
  note: String,
};

export default forwardRef(Input);

const UserCard = ({ mail }) => {
  return (
    <div className="flex rounded-full items-center gap-2 border border-gray-600 px-1 w-full h-7 my-auto max-w-44">
      <img src={emailAvatar} alt="avatar" className="rounded-full size-5" />
      <p className="text-sm">{mail}</p>
      <CrossIcon className="cursor-pointer" width={12} height={12}/>
    </div>
  );
};

function isValidEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}