import { useUpdateMajor } from "api/major";
import Select from "common/Select";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { categories } from "section/major/allMajors/Header";

export default function Categories({ majorData }) {
  const { majorId } = useParams();
  const { mutate: updateMajor, isLoading: isUpdatingMajor } =
    useUpdateMajor(majorId);

  var [categoriesList, setCategoriesList] = useState(
    majorData?.related_categories || []
  );

  useEffect(() => {
    if (categoriesList.length === 0) {
      setCategoriesList(majorData?.related_categories || []);
    }
  }, [majorData?.related_categories]);


  const updateCategories = (value) => {
    updateMajor({ related_categories: value });
  }

  return (
    <>
      <Select
        tittle="Categories"
        label="Categories"
        showTooltip
        placeholder="Select Categories"
        options={categories}
        value={categoriesList}
        onChange={(value) => {
          if (categoriesList.includes(value)) {
            setCategoriesList(categoriesList.filter((item) => item !== value));
          } else {
            setCategoriesList([...categoriesList, value]);
          }
        }}
        multipleSelect
        className="shadow-none rounded-none py-1.5"
        onBlur={updateCategories}
        onAllClear={() => setCategoriesList([])}
        disabled={isUpdatingMajor}
      />
    </>
  );
}
