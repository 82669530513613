export default function CheckIcon({ width, height, fill }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || "15.644"}
        height={height || "12.292"}
        viewBox="0 0 15.644 12.292"
      >
        <path
          id="Vector"
          d="M4.278,11.542a1.671,1.671,0,0,1-1.185-.491L-.259,7.7a1.676,1.676,0,0,1,2.37-2.37L4.278,7.5,12.033-.259a1.676,1.676,0,1,1,2.37,2.37L5.464,11.051A1.671,1.671,0,0,1,4.278,11.542Z"
          transform="translate(0.75 0.75)"
          fill={fill || "#489900"}
        />
      </svg>
    </>
  );
}
