import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  qna: [],
  loaded: false,
};

const qnaSlice = createSlice({
  name: "qna",
  initialState,
  reducers: {
    setQna: (state, action) => {
      state.qna = action.payload;
      state.loaded = true;
    },
    addQna: (state, action) => {
      state.qna.push(action.payload);
    },
    updateSingleQna: (state, action) => {
      state.qna = state.qna.map((qna) => {
        if (qna._id === action.payload._id) {
          return action.payload;
        }
        return qna;
      });
    },
  },
});


export const { setQna, addQna, updateSingleQna } = qnaSlice.actions;

export default qnaSlice.reducer;