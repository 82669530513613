import BasicInformation from "./BasicInformation";
import Modifications from "./Modifications";
import Create from "./Create";
import SimpleLoader from "common/SimpleLoader";
import { useParams } from "react-router-dom";

export default function AddInstituteSteps({ step, footer, updateData, institution }) {
  const { institutionId } = useParams();
  if (!institution && institutionId !== 'add') return <SimpleLoader />;
  switch (step) {
    case 0:
      return <BasicInformation footer={footer} updateData={updateData} institution={institution} />;
    case 1:
      return <Modifications footer={footer} updateData={updateData} institution={institution} />;
    case 2:
      return <Create footer={footer} updateData={updateData} institution={institution} />;
    default:
      return <BasicInformation footer={footer} updateData={updateData} institution={institution} />;
  }
}
