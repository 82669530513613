import {
  ApplicantsIcon,
  BoostIcon,
  QuotaIcon,
  SuitableIcon,
  ViewsIcon,
  StarIcon,
  EyeIcon,
  CrossEye,
  ArchieveIcon,
  CheckCircle,
  DeleteIcon,
} from "assets/icons";
import ChevronLeftIcon from "assets/icons/ChevronLeftIcon";
import { FilledButton, OutlinedButton } from "common/Button";
import Divider from "common/Divider";
import { useUtilities } from "helper";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRemoveMajor, useUpdateMajor } from "api/major";
import { twJoin } from "tailwind-merge";
import { motion } from "framer-motion";
import Buttonloader from "common/Buttonloader";

function ArchieveMajor({
  onCanceled,
  onConfirmed,
  title,
  isArchieving,
  is_archived,
  is_deleted,
}) {
  return (
    <>
      <motion.div
        animate={{
          opacity: 1,
          start: {
            opacity: 0,
          },
        }}
        className="shadow w-full transition-opacity rounded-md shadow-[#CAE6F4] bg-[#CAE6F4] px-16 flex items-center justify-between"
      >
        <div className="flex flex-col gap-2">
          <h1 className="text-theme-blue font-bold text-3xl">
            {is_deleted ? "Delete" : is_archived ? "Unarchive" : "Archive"}{" "}
            <span className=" capitalize">{title}</span>?
          </h1>
          <p className="text-theme-blue text-xl">
            {is_deleted
              ? "This will delete major prementaly"
              : is_archived
              ? "Users could see this major"
              : "Users could not see this major Major will not be deleted"}
          </p>
          <div className="flex items-center gap-4 mt-10">
            <FilledButton
              className="bg-[#F29423]"
              onClick={onConfirmed}
              disabled={isArchieving}
            >
              {isArchieving ? (
                <Buttonloader />
              ) : (
                <span className="flex items-center gap-2">
                  <CheckCircle /> Confirm
                </span>
              )}
            </FilledButton>
            <FilledButton
              className="bg-[#A9A9A9]"
              onClick={onCanceled}
              disabled={isArchieving}
            >
              Cancel
            </FilledButton>
          </div>
        </div>
        <img
          src={require(`assets/images/${
            is_deleted ? "delete_major" : "archive-major"
          }.png`)}
          alt="archieve"
        />
      </motion.div>
    </>
  );
}

export default function MajorItem({
  title,
  active,
  id,
  applications,
  views,
  createdAt,
  is_deleted,
  quota,
  related_categories,
  cover_image,
  is_archived,
  subtitle,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const { navigate } = useUtilities();
  const { mutateAsync: updateMajor, isLoading: isUpdatingMajor } =
    useUpdateMajor(id);
  const removeMajor = useRemoveMajor();
  const [archieveMajor, setArchieveMajor] = useState(false);
  const [deleteMajor, setDeleteMajor] = useState(false);

  const handleConfirm = () => {
    if (deleteMajor) {
      updateMajor({ is_deleted: !is_deleted }).then(() => {
        removeMajor(id);
      });
      return;
    }
    updateMajor({ is_archived: !is_archived });
  };
  return (
    <>
      {archieveMajor || deleteMajor ? (
        <ArchieveMajor
          onCanceled={() => {
            setArchieveMajor(false);
            setDeleteMajor(false);
          }}
          onConfirmed={handleConfirm}
          title={title}
          isArchieving={isUpdatingMajor}
          is_archived={is_archived}
          is_deleted={deleteMajor}
        />
      ) : (
        <div
          className={`shadow w-full rounded-md px-3 pb-3 border ${
            active
              ? "border-theme-gray bg-white"
              : "border-[#D84646] bg-[#F0F0F0]"
          }`}
          aria-disabled={!active || is_archived}
        >
          <div
            className={twJoin(
              "flex justify-end gap-5 items-center mt-1",
              !active || is_archived ? "grayscale" : ""
            )}
          >
            <p className="font-light text-xs text-theme-blue">Default</p>
            <div className="flex items-center gap-2">
              <StarIcon />
              <p className="font-medium text-xs text-theme-purple">Top 5</p>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => updateMajor({ active: !active })}
            >
              {active ? (
                <EyeIcon fill="#004E99" />
              ) : (
                <CrossEye fill="#004E99" />
              )}
            </div>
          </div>
          <div
            className={twJoin(
              "flex gap-8",
              !active || is_archived ? "grayscale" : ""
            )}
          >
            <div
              style={{
                backgroundImage: `url(${
                  cover_image
                    ? cover_image
                    : require("assets/images/major-img.png")
                })`,
              }}
              className={` transition-all duration-300 ease-in-out ${
                showDetails ? "h-80" : "h-32"
              } w-60 min-w-[240px] object-cover rounded-2xl bg-no-repeat bg-top`}
            />
            <div className="flex flex-col w-full">
              <div className="flex gap-2 items-center">
                <h2 className="text-theme-blue text-2xl font-medium capitalize">
                  {title}
                </h2>
                {is_archived ? (
                  <FilledButton
                    className="bg-theme-blue text-white py-2"
                    onClick={() => setArchieveMajor(true)}
                  >
                    Unarchive
                  </FilledButton>
                ) : (
                  <OutlinedButton
                    className="border-theme-blue text-theme-blue"
                    onClick={() => navigate(`/majors/${id}`)}
                  >
                    View more
                  </OutlinedButton>
                )}
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    is_archived ? setDeleteMajor(true) : setArchieveMajor(true)
                  }
                >
                  {is_archived ? <DeleteIcon /> : <ArchieveIcon />}
                </div>
              </div>
              <p className="text-sm font-light text-theme-blue">{subtitle}</p>
              <p className="text-lg font-light text-theme-blue capitalize">
                {related_categories.join(", ")}
              </p>
              <div
                className={`my-auto transition-all duration-500 ease-in-out overflow-hidden origin-top ${
                  showDetails ? "h-fit scale-y-100" : "h-0 scale-y-0"
                }`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-6 py-2">
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-1 items-center">
                        <ViewsIcon />
                        <p className="font-light">Views</p>
                      </div>
                      <p className="text-5xl font-thin">{views}</p>
                    </div>
                    <Divider orientation="vertical" className="h-16 w-[2px]" />
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-1 items-center">
                        <ApplicantsIcon />
                        <p className="font-light">Applicants</p>
                      </div>
                      <p className="text-5xl font-thin">
                        {applications.length}
                      </p>
                    </div>
                    <Divider orientation="vertical" className="h-16 w-[2px]" />
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-1 items-center">
                        <SuitableIcon />
                        <p className="font-light">Suitable</p>
                      </div>
                      <p className="text-5xl font-thin">0</p>
                    </div>
                    <Divider orientation="vertical" className="h-16 w-[2px]" />
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-1 items-center">
                        <QuotaIcon />
                        <p className="font-light">Quota</p>
                      </div>
                      <p className="text-5xl font-thin">{quota}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <FilledButton className="bg-theme-purple py-2">
                      <BoostIcon /> Boost Major (3)
                    </FilledButton>
                    <Link
                      to={`/majors/${id}`}
                      className="font-semibold text-xl underline text-theme-blue"
                    >
                      View Full Insights
                    </Link>
                  </div>
                </div>
                <p className="text-sm font-light">
                  Since -{" "}
                  {new Date(createdAt).toLocaleDateString("en-US", {
                    month: "numeric",
                    year: "numeric",
                  })}
                </p>
              </div>
              <div className="flex gap-10 items-center py-4 mt-auto w-full border-t border-t-theme-gray">
                <div className="flex items-center gap-2">
                  <p className="text-theme-yellow font-semibold">60%</p>
                  <p className="font-light">Academy Complete</p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-theme-yellow font-semibold">60%</p>
                  <p className="font-light">Career Complete</p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-theme-yellow font-semibold">60%</p>
                  <p className="font-light">Apply Compatible</p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-col justify-end h-full">
                <div
                  className="w-8 h-8 min-w-[30px] min-h-[30px] border-2 border-[#919191] rounded-full flex items-center justify-center cursor-pointer"
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <ChevronLeftIcon
                    fill="#919191"
                    width="8"
                    className={`transition-all duration-500 ease-in-out ${
                      showDetails ? "-rotate-90" : "rotate-90"
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
