import { axios, useUtilities } from "helper";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";

import {
  addSchedule,
  setSchedule,
  updateSingleSchedule,
} from "redux/reducers/schedule";

async function getSchedule() {
  return axios
    .get("schedule")
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetSchedule() {
  return useQuery(["schedule"], getSchedule);
}

export function useSchedule() {
  const { data, isLoading, isError, error } = useGetSchedule();
  const {
    dispatch,
    schedule: { schedule, loaded },
  } = useUtilities();
  useEffect(() => {
    if (data && !loaded) {
      dispatch(setSchedule(data.data));
    }
  }, [data]);
  return {
    data: schedule,
    isLoading: isLoading || !loaded,
    isError,
    error,
  };
}

async function createSchedule({ data, callback }) {
  return await axios
    .post("schedule", data)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useAddSchedule() {
  const { notification, dispatch } = useUtilities();
  return useMutation(createSchedule, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addSchedule(data.data));
        notification({
          message: "Schedule added successfully",
          type: "success",
        });
      } else {
        notification({
          message: data.message,
          type: "error",
        });
      }
    },
  });
}

async function updateSchedule({ data, callback }) {
  const { id, ...rest } = data;
  return await axios
    .put(`schedule/${id}`, rest)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useUpdateSchedule() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateSchedule, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleSchedule(data.data));
        notification({
          message: "Schedule updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: data.message,
          type: "error",
        });
      }
    },
  });
}
