export default function DashboardIcon({ height = "19.5", width = "19.5" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19.5 19.5"
    >
      <path
        id="dashboard_icon"
        data-name="dashboard icon"
        d="M18.417,15.167H1.083A1.083,1.083,0,0,1,0,14.083v-3.25A3.254,3.254,0,0,1,.713,8.8L5.046,3.387a3.322,3.322,0,0,1,4.488-.568l1.814,1.359,2.367-2.956A3.248,3.248,0,0,1,19.5,3.25V14.083a1.083,1.083,0,0,1-1.083,1.083ZM2.167,13H17.333V3.25A1.082,1.082,0,0,0,15.4,2.574L12.382,6.349a1.083,1.083,0,0,1-1.5.191L8.233,4.55a1.105,1.105,0,0,0-1.5.191L2.4,10.157a1.076,1.076,0,0,0-.237.676Zm16.25,6.5H1.083a1.083,1.083,0,1,1,0-2.167H18.417a1.083,1.083,0,1,1,0,2.167Z"
        fill="#004e99"
      />
    </svg>
  );
}
