export default function UsersIcon({ width = "22", height = "18" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 18"
    >
      <path
        id="users_icon"
        data-name="users icon"
        d="M16,17h5V15a3,3,0,0,0-5.356-1.857M16,17H6m10,0V15a4.987,4.987,0,0,0-.356-1.857M6,17H1V15a3,3,0,0,1,5.356-1.857M6,17V15a4.986,4.986,0,0,1,.356-1.857m0,0a5,5,0,0,1,9.288,0M14,4a3,3,0,1,1-3-3A3,3,0,0,1,14,4Zm6,3a2,2,0,1,1-2-2A2,2,0,0,1,20,7ZM6,7A2,2,0,1,1,4,5,2,2,0,0,1,6,7Z"
        fill="none"
        stroke="#004e99"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
}
