export default function EmailIcon({
  width = "20",
  height = "21",
  fill = "#000000",
  stroke = "1.5",
}) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.208 17.4131H5.79101C3.26601 17.4131 1.58301 16.1381 1.58301 13.1611V7.20708C1.58301 4.23108 3.26601 2.95508 5.79101 2.95508H14.208C16.733 2.95508 18.416 4.23108 18.416 7.20708V13.1601C18.416 16.1381 16.733 17.4131 14.208 17.4131Z"
          stroke={fill}
          stroke-width={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.959 7.74023L11.481 9.71923C11.0507 10.0356 10.5306 10.2063 9.99649 10.2063C9.4624 10.2063 8.94229 10.0356 8.51199 9.71923L6.04199 7.74023"
          stroke={fill}
          stroke-width={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
