export default function CrossEye() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27.739"
        height="23.69"
        viewBox="0 0 27.739 23.69"
      >
        <g id="eye-hide-line" transform="translate(0)">
          <path
            id="Path_721"
            data-name="Path 721"
            d="M23.971,19.117a5.841,5.841,0,0,0,.37-2.067,5.91,5.91,0,0,0-5.91-5.91,5.849,5.849,0,0,0-2.042.37l1.387,1.43a4.118,4.118,0,0,1,.637-.052,4.2,4.2,0,0,1,4.2,4.161,4.126,4.126,0,0,1-.052.637Z"
            transform="translate(-4.321 -5.239)"
            fill="#9b1f1f"
          />
          <path
            id="Path_722"
            data-name="Path 722"
            d="M31.372,16.144c-2.9-5.367-7.994-8.614-13.628-8.614a14.49,14.49,0,0,0-4.514.732l1.387,1.4a12.732,12.732,0,0,1,3.127-.4,13.755,13.755,0,0,1,11.888,7.262,15.291,15.291,0,0,1-3.765,4.393l1.223,1.223A17.169,17.169,0,0,0,31.4,16.963l.224-.413Z"
            transform="translate(-3.883 -4.739)"
            fill="#9b1f1f"
          />
          <path
            id="Path_723"
            data-name="Path 723"
            d="M4.525,5.574,8.367,9.416A16.816,16.816,0,0,0,2.6,15.7l-.224.4.224.413c2.9,5.367,7.994,8.614,13.628,8.614a14.581,14.581,0,0,0,6.346-1.455l4.31,4.306L28.4,26.688,6,4.29Zm8.4,8.4L18.653,19.7a4.144,4.144,0,0,1-2.154.62A4.2,4.2,0,0,1,12.3,16.1a4.144,4.144,0,0,1,.62-2.128Zm-1.249-1.249A5.9,5.9,0,0,0,19.9,20.95l1.378,1.378a12.844,12.844,0,0,1-5.048,1.034A13.755,13.755,0,0,1,4.344,16.1a14.894,14.894,0,0,1,5.272-5.427Z"
            transform="translate(-2.38 -4.29)"
            fill="#9b1f1f"
          />
        </g>
      </svg>
    </>
  );
}
