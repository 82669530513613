export default function DownChevronFilled({
  width = "8",
  height = "8",
  fill = "#006ED8",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9995 0.5H5.557C7.48783 0.5 8.28117 1.87083 7.31283 3.545L6.53117 4.8925L5.7495 6.24C4.78117 7.91417 3.20033 7.91417 2.232 6.24L1.45033 4.8925L0.668667 3.545C-0.282166 1.87083 0.505334 0.5 2.442 0.5H3.9995Z"
        fill={fill}
      />
    </svg>
  );
}
