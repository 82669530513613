import {
  StarIcon,
  EyeIcon,
  DeleteIcon,
  CheckIcon,
  CrossEye,
  ChevronRightIcon,
} from "assets/icons";
import { MajorTabConstants } from "constants/major";
import React, { useEffect, useState } from "react";
import InSightTab from "./InSightTab";
import { useMajors } from "api/major";
import { useParams } from "react-router";
import MajorTab from "./MajorTab";
import CareerTab from "./CareerTab";
import { Link, useSearchParams } from "react-router-dom";
import ApplyTab from "./ApplyTab";
import { useUpdateMajor } from "api/major";
import Modal from "common/Modal";
import { useUtilities } from "helper";
import SimpleLoader from "common/SimpleLoader";
import BreadCrumbs from "common/BreadCrumbs";
import { Input } from "common/Input";

export default function Header() {
  const { majorId } = useParams();
  const [majorData, setMajorData] = useState({});
  const { data: major, isLoading } = useMajors();
  let [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(
    searchParams.get("tab") || MajorTabConstants.INSIGHTS
  );
  const [deleteMajor, setDeleteMajor] = useState(false);
  const { mutateAsync: updateMajor, isLoading: isUpdatingMajor } =
    useUpdateMajor(majorId);
  const { navigate } = useUtilities();

  useEffect(() => {
    if (major) {
      setMajorData(major.filter((item) => item._id === majorId)[0] || {});
    }
  }, [major]);

  const getTab = () => {
    let temp = searchParams.get("tab") || MajorTabConstants.INSIGHTS;
    let data = null;
    switch (temp) {
      case MajorTabConstants.INSIGHTS:
        data = <InSightTab majorData={majorData} />;
        break;
      case MajorTabConstants.MAJOR:
        data = <MajorTab majorData={majorData} />;
        break;
      case MajorTabConstants.CAREER:
        data = <CareerTab majorData={majorData} />;
        break;
      case MajorTabConstants.APPLY:
        data = <ApplyTab majorData={majorData} />;
        break;
      default:
        data = <InSightTab majorData={majorData} />;
        break;
    }
    return data;
  };

  const handleConfirmDelete = () => {
    updateMajor({ is_deleted: !majorData?.is_deleted }).then(() => {
      navigate("/majors");
    });
  };

  return (
    <>
      <Modal
        onConfirm={handleConfirmDelete}
        heading="Are you sure you want to delete this major?"
        open={deleteMajor}
        onClose={() => setDeleteMajor(false)}
        confirmText="Delete"
        confirmLoading={isUpdatingMajor}
        confirmTextClassName="bg-red-500"
      >
        <p className="text-lg">
          This will delete the major from the system. You can not undo this
          action.
        </p>
      </Modal>
      {Object.keys(majorData).length !== 0 ? (
        <BreadCrumbs
          data={[
            {
              label: "Majors",
              link: "/majors",
              style: { color: "#BBC4FF" },
            },
            {
              label: majorData.title,
              link: `/majors/${majorId}`,
            },
          ]}
        />
      ) : (
        ""
      )}
      {Object.keys(majorData).length === 0 ? (
        <SimpleLoader open />
      ) : majorData.is_deleted ? (
        <p>This major has been deleted</p>
      ) : (
        <div>
          <div className="flex items-start justify-between">
            <div className="w-5/6">
              <Input
                className="text-theme-blue text-3xl py-0 px-0 font-semibold capitalize shadow-none border-none bg-transparent w-full max-w-none"
                value={majorData.title}
                onChange={(e) =>
                  setMajorData({ ...majorData, title: e.target.value })
                }
                onBlur={(e) => {
                  if (majorData?.title === "") return;
                  updateMajor({ title: majorData?.title });
                }}
              />
              <Input
                className="text-theme-blue text-lg py-0 px-0 capitalize shadow-none border-none bg-transparent w-full max-w-none"
                value={majorData.subtitle}
                onChange={(e) =>
                  setMajorData({ ...majorData, subtitle: e.target.value })
                }
                onBlur={(e) => {
                  if (majorData?.subtitle === "") return;
                  updateMajor({ subtitle: majorData?.subtitle });
                }}
              />
            </div>

            <div className="flex justify-end gap-5 items-center mt-1">
              <div className="flex items-center gap-2">
                <StarIcon />
                <p className="font-medium text-xs text-theme-purple">Top 5</p>
              </div>
              {majorData.is_default ? (
                <p className="font-light text-xs text-theme-blue">Default</p>
              ) : (
                ""
              )}
              <div
                className="cursor-pointer"
                onClick={() => updateMajor({ active: !majorData?.active })}
              >
                {majorData?.active ? (
                  <EyeIcon fill="#004E99" />
                ) : (
                  <CrossEye fill="#D84646" />
                )}
              </div>
              <div
                className="cursor-pointer"
                onClick={() => setDeleteMajor(true)}
              >
                <DeleteIcon />
              </div>
            </div>
          </div>
          <div className="flex items-center mt-3 ">
            {["Insights", "Major", "Career", "Apply"].map((item) => (
              <button
                onClick={() => {
                  setTab(item);
                  setSearchParams({ tab: item });
                }}
                className={` transition-all duration-300 ease-in-out py-2 px-6 text-lg first:pl-0 hover:underline underline-offset-8 flex items-center gap-1
              ${
                tab === item
                  ? "text-black font-medium decoration-theme-yellow underline"
                  : "text-[#919191] decoration-theme-blue"
              }
              `}
              >
                {item}&nbsp;
                <span className="text-theme-yellow text-xs">
                  <CheckIcon />
                </span>
              </button>
            ))}
          </div>
          <div className="mt-4">{getTab()}</div>
        </div>
      )}
    </>
  );
}
