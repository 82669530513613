export default function AddCircleIcon({ fill = "white", size = 24 }) {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22.5C13.9778 22.5 15.9112 21.9135 17.5557 20.8147C19.2002 19.7159 20.4819 18.1541 21.2388 16.3268C21.9957 14.4996 22.1937 12.4889 21.8079 10.5491C21.422 8.60929 20.4696 6.82746 19.0711 5.42894C17.6725 4.03041 15.8907 3.078 13.9509 2.69215C12.0111 2.3063 10.0004 2.50433 8.17317 3.26121C6.3459 4.01809 4.78412 5.29981 3.6853 6.9443C2.58649 8.58879 2 10.5222 2 12.5C2.00765 15.1498 3.06368 17.6889 4.93738 19.5626C6.81109 21.4363 9.35019 22.4924 12 22.5Z"
          stroke={fill}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 12.5H16"
          stroke={fill}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 16.5V8.5"
          stroke={fill}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
