import { axios, useUtilities } from "helper";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  addMajor,
  setMajor,
  updateSingleMajor,
  deleteMajor,
} from "redux/reducers/major";
import { useUserInstitute } from "./user";

async function getAllMajor(institutionId) {
  if (!institutionId) {
    return Promise.reject(new Error("Institution id is required"));
  }
  return await axios
    .get("major", {
      params: {
        institutionId,
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetAllMajor(institutionId) {
  return useQuery(["major", institutionId], () => getAllMajor(institutionId));
}

export function useMajors() {
  const {
    major: { major, institutionId: majorInstitutionId },
    dispatch,
  } = useUtilities();
  const institutionId = useUserInstitute();
  const { data, isLoading, isError, error } = useGetAllMajor(institutionId);

  useEffect(() => {
    if (data && majorInstitutionId !== institutionId) {
      dispatch(
        setMajor({
          institutionId,
          major: data.data,
        })
      );
    }
  }, [data, isLoading, isError, error]);
  return {
    data: major,
    isLoading: isLoading || majorInstitutionId !== institutionId,
    isError,
    error,
  };
}

async function createMajor(data) {
  return await axios
    .post("major", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddMajor() {
  const { notification, dispatch, navigate } = useUtilities();
  return useMutation(createMajor, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addMajor(data.data));
        notification({
          message: "Major added successfully",
          type: "success",
        });
        navigate("/majors/" + data.data._id);
      } else {
        notification({
          message: "Failed to Major failed",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to Major failed",
        type: "error",
      });
    },
  });
}

async function updateMajor(majorId, data) {
  return await axios
    .put("major/" + majorId, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateMajor(majorId) {
  const { notification, dispatch, navigate } = useUtilities();

  return useMutation((payload) => updateMajor(majorId, payload), {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleMajor(data.data));
        notification({
          message: "Major updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to Major failed",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to Major failed",
        type: "error",
      });
    },
  });
}

export function useRemoveMajor() {
  const { dispatch } = useUtilities();
  return (majorId) => dispatch(deleteMajor(majorId));
}

async function getSingleMajor(majorId) {
  return await axios
    .get("major/" + majorId)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetSingleMajor() {
  const { dispatch } = useUtilities();
  return (majorId) => {
    getSingleMajor(majorId).then((res) => {
      if (res.statusCode === 200) {
        dispatch(updateSingleMajor(res.data));
      }
    });
  };
}

async function addCareerToMajor({ data }) {
  const { id, ...rest } = data;
  return await axios
    .put("major/" + id + "/add-career", rest)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddCareerToMajor() {
  const { notification, dispatch } = useUtilities();
  return useMutation(addCareerToMajor, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleMajor(data.data));
        notification({
          message: "Career added to Major successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to add Career to Major",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to add Career to Major",
        type: "error",
      });
    },
  });
}

async function removeCareerFromMajor({ data }) {
  const { id, ...rest } = data;
  return await axios
    .put("major/" + id + "/remove-career", rest)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useRemoveCareerFromMajor() {
  const { notification, dispatch } = useUtilities();
  return useMutation(removeCareerFromMajor, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleMajor(data.data));
        notification({
          message: "Career removed from Major successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to remove Career from Major",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to remove Career from Major",
        type: "error",
      });
    },
  });
}

async function getMajorStaticData() {
  return axios
    .get("staticData", {
      params: {
        dataIDs: JSON.stringify(["degree_type"]),
      },
    })
    .then((res) => {
      let data = {};
      res.data.forEach((item) => {
        data[item.dataID] = item.data;
      });
      return data;
    })
    .catch((err) => new Error(err));
}

export function useGetMajorStaticData() {
  return useQuery(["staticData"], getMajorStaticData);
}
