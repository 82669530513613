export default function SkeletonInstitute() {
  return (
    <>
      <div className="max-w-[620px] rounded-md shadow-lg animate-pulse  w-full">
        <div className="w-full h-40 rounded-t-md animate-pulse bg-slate-200 "></div>
        <div className="pt-3 pb-5 px-3 w-full">
          <div className="flex gap-2 w-full">
            <div className="w-36 h-36 min-w-[144px] rounded-full bg-cover bg-center bg-slate-200 z-10 -mt-20 border border-white"></div>
            <div className="flex flex-col w-full">
              <div className="h-5 w-full bg-slate-200" />
              <div className="h-3 w-full bg-slate-200 my-3" />
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-6 w-full">
            <div className="h-4 w-full bg-slate-200" />
            <div className="h-4 w-full bg-slate-200" />
            <div className="h-4 w-full bg-slate-200" />
          </div>
        </div>
      </div>
    </>
  );
}
