
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    careers: [],
    loaded: false,
};

const careersSlice = createSlice({
    name: "careers",
    initialState,
    reducers: {
        setCareers: (state, action) => {
            state.careers = action.payload;
            state.loaded = true;
        },
        addCareer: (state, action) => {
            state.careers.push(action.payload);
        },
        updateSingleCareer: (state, action) => {
            const index = state.careers.findIndex(
                (career) => career._id === action.payload._id
            );
            if (index !== -1) {
                state.careers[index] = action.payload;
            }
        },
        deleteCareer: (state, action) => {
            state.careers = state.careers.filter(
                (career) => career._id !== action.payload
            );
        },
    },
});

export const { setCareers, addCareer, updateSingleCareer, deleteCareer } =
    careersSlice.actions;

export default careersSlice.reducer;