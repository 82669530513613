export default function QuestionIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g
          id="vuesax_linear_message-search"
          data-name="vuesax/linear/message-search"
          transform="translate(-172 -250)"
        >
          <g id="message-search">
            <g id="Group">
              <path
                id="Vector"
                d="M11.2,19.4a1.421,1.421,0,0,1-2.4,0l-1.5-2a1.149,1.149,0,0,0-.8-.4H6c-4,0-6-1-6-6V6Q0,0,6,0h8q6,0,6,6v5"
                transform="translate(174 251.97)"
                fill="none"
                stroke="#f29423"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
            <g id="Group-2" data-name="Group">
              <path
                id="Vector-2"
                data-name="Vector"
                d="M6.4,3.2A3.2,3.2,0,1,1,3.2,0,3.2,3.2,0,0,1,6.4,3.2Z"
                transform="translate(187 265)"
                fill="none"
                stroke="#f29423"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Vector-3"
                data-name="Vector"
                d="M1,1,0,0"
                transform="translate(193 271)"
                fill="none"
                stroke="#f29423"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0H24V24H0Z"
              transform="translate(172 250)"
              fill="none"
              opacity="0"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M.495.5H.5"
              transform="translate(187.502 260.5)"
              fill="none"
              stroke="#f29423"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Vector-6"
              data-name="Vector"
              d="M.495.5H.5"
              transform="translate(183.501 260.5)"
              fill="none"
              stroke="#f29423"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Vector-7"
              data-name="Vector"
              d="M.495.5H.5"
              transform="translate(179.5 260.5)"
              fill="none"
              stroke="#f29423"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
