import { twMerge } from "tailwind-merge";

export default function Progress({ value, max, color, className = "" }) {
    const percentage = Math.round((value / max) * 100);

    return (
        <div className={twMerge("relative h-2 bg-gray-200 rounded-full", className)}>
            <div
                className={`absolute top-0 left-0 h-full rounded-full`}
                style={{ width: `${percentage}%`, backgroundColor: color }}
            ></div>
        </div>
    );
}