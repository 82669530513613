import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  skills: [],
  loaded: false,
};

const skillsSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {
    setSkills: (state, action) => {
      state.skills = action.payload;
      state.loaded = true;
    },
    addSkill: (state, action) => {
      state.skills.push(action.payload);
    },
    updateSingleSkill: (state, action) => {
      state.skills = state.skills.map((skill) => {
        if (skill._id === action.payload._id) {
          return action.payload;
        }
        return skill;
      });
    },
  },
});

export const { setSkills, addSkill, updateSingleSkill } = skillsSlice.actions;

export default skillsSlice.reducer;
