import { useQuery, useMutation } from "@tanstack/react-query";
import { queryClient } from "client";
import { axios, useUtilities } from "helper";

async function createCalenderTemplate(data) {
  return await axios
    .post("calender_template", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddCalenderTemplate() {
  const { notification } = useUtilities();
  return useMutation(createCalenderTemplate, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        notification({
          message: "Calender Template added successfully",
          type: "success",
        });
      } else {
        notification({
          message: data.message,
          type: "error",
        });
      }
    },
    onError: (err) => {
      notification({
        message: err.message,
        type: "error",
      });
    },
  });
}

async function updateCalenderTemplate({ data, id }) {
  return await axios
    .put(`calender_template/${id}`, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateCalenderTemplate() {
  const { notification } = useUtilities();
  return useMutation(updateCalenderTemplate, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        notification({
          message: "Calender Template updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: data.message,
          type: "error",
        });
      }
    },
    onError: (err) => {
      notification({
        message: err.message,
        type: "error",
      });
    },
  });
}

async function getCalenderTemplate(instituteId) {
  return await axios
    .get(`calender_template`, {
      params: {
        instituteId,
      },
    })
    .then((res) => res.data?.data || [])
    .catch((err) => new Error(err));
}

export function useGetCalenderTemplate(instituteId) {
  return useQuery(["calender_template", instituteId], () =>
    getCalenderTemplate(instituteId)
  );
}

async function deleteCalenderTemplate(id) {
  return await axios
    .delete(`calender_template/${id}`)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useDeleteCalenderTemplate(instituteId) {
  const { notification } = useUtilities();
  return useMutation(deleteCalenderTemplate, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        queryClient.invalidateQueries(["calender_template", instituteId]);
        notification({
          message: "Calender Template deleted successfully",
          type: "success",
        });
      } else {
        notification({
          message: data.message,
          type: "error",
        });
      }
    },
    onError: (err) => {
      notification({
        message: err.message,
        type: "error",
      });
    },
  });
}
