import React from "react";

const CircleIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 16}
      height={size || 16}
      fill={fill || "#000"}
      viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" />
    </svg>
  );
};

export default CircleIcon;
