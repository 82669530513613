export default function QuotaIcon({ width = "17.333", height = "19.375" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17.333 19.375"
    >
      <path
        id="suited_icon"
        data-name="suited icon"
        d="M10.021,8.271V1.125L.833,12.354H7.979V19.5L17.167,8.271Z"
        transform="translate(-0.333 -0.625)"
        fill="none"
        stroke="#333"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  );
}
