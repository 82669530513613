import ChevronLeftIcon from "assets/icons/ChevronLeftIcon";

export default function FormSteps({ data, step }) {
  return (
    <>
      <div className="flex gap-4 my-3">
        {data.map(({ label, style }, index) => (
          <>
            <div key={index} className="flex gap-4" style={style}>
              <div className="flex gap-2 items-center">
                <div
                  className={`h-7 w-7 rounded-full border border-[#B4B4B4] flex items-center justify-center transition-all duration-300 ease-in-out ${
                    index === step
                      ? "bg-[#004E99] border-[#004E99] text-white"
                      : index < step
                      ? "bg-[#5A70E8] border-[#5A70E8] text-white"
                      : ""
                  }`}
                >
                  {index + 1}
                </div>
                <h2 className="capitalize text-xl">{label}</h2>
              </div>
            </div>
            {index < data.length - 1 && (
              <ChevronLeftIcon className="self-center" fill="#292D32" />
            )}
          </>
        ))}
      </div>
    </>
  );
}
