import React from "react";
import Loader from "common/Loader";
import OutlinedButton from "common/Button/OutlinedButton";
import { Input } from "common/Input";
import { useNavigate, useLocation } from "react-router-dom";

const VerifyEmail = ({ forgetPassword }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [timer, setTimer] = React.useState(30);
  const [inputVales, setInputValues] = React.useState({
    code: "",
    error: "",
    success: "",
    loading: false,
  });

  React.useEffect(() => {
    let timerVar = setInterval(() => {
      if (timer > 0) setTimer(timer - 1);
      else {
        clearInterval(timerVar);
      }
    }, 1000);
    return () => {
      clearInterval(timerVar);
    };
  }, [timer]);

  function handleSubmit() {
    setInputValues({ ...inputVales, loading: true });
    fetch(
      process.env.REACT_APP_SERVER_URL +
        `users/${forgetPassword ? "forget-password-verify-otp" : "verify-otp"}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          otp: inputVales.code,
          email: sessionStorage.getItem("email"),
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 200) {
          setInputValues({
            ...inputVales,
            error: "",
            loading: false,
            success: "",
          });
          sessionStorage.setItem("otp", inputVales.code);
          navigate(
            forgetPassword ? "/user-auth/new-password" : "/user-auth/add-key"
          );
        } else {
          setInputValues({
            ...inputVales,
            error: data.message,
            loading: false,
            success: "",
          });
        }
      })
      .catch((err) => {
        setInputValues({
          ...inputVales,
          error: err.message,
          loading: false,
          success: "",
        });
      });
  }

  function handleResendCode() {
    if (timer > 0) return;
    setInputValues({ ...inputVales, loading: true });
    fetch(
      process.env.REACT_APP_SERVER_URL +
        `users/${forgetPassword ? "forget-password-resend-otp" : "resend-otp"}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("email"),
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 200) {
          setInputValues({
            ...inputVales,
            error: "",
            success: data.message,
            loading: false,
          });
          setTimer(30);
        } else {
          setInputValues({
            ...inputVales,
            error: data.message,
            loading: false,
            success: "",
          });
        }
      })
      .catch((err) => {
        setInputValues({
          ...inputVales,
          error: err.message,
          loading: false,
          success: "",
        });
      });
  }

  return (
    <>
      <Loader open={inputVales.loading} />
      <div className="flex flex-col max-w-sm w-full">
        <div className="flex flex-col gap-4 mb-6">
          <p className="text-base text-gray-700 w-full text-center mb-6">
            Check&nbsp;
            {sessionStorage.getItem("email")
              ? sessionStorage.getItem("email")
              : state?.email ?? "email"}&nbsp;
            for code
          </p>
          {inputVales.error && (
            <p className="text-red-500 text-xs italic">
              {inputVales.error ?? "Something went wrong!"}
            </p>
          )}
          {inputVales.success && (
            <p className="text-green-500 text-xs italic">
              {inputVales.success ?? "Code sent successfully!"}
            </p>
          )}
          <Input
            id="verfiy-email"
            type="text"
            placeholder="XXX-XXX"
            value={inputVales.code}
            onChange={(e) =>
              setInputValues({ ...inputVales, code: e.target.value })
            }
          />
        </div>
        <div className="flex flex-col gap-3 items-center justify-center w-full">
          <OutlinedButton onClick={handleSubmit}>Verify</OutlinedButton>
          <button
            className={`text-gray-600 underline font-medium py-1 px-4 rounded-full focus:outline-none focus:shadow-outline text-sm ${
              timer > 0 ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            onClick={handleResendCode}
          >
            Send again in {timer} seconds
          </button>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
