import { FilledButton } from "common/Button";
import { twMerge } from "tailwind-merge";

export function StatusButton({ status, style, className, ...props }) {
  return (
    <FilledButton
      className={twMerge("px-3 py-1 font-normal text-lg rounded-md", className)}
      style={{ ...style }}
      {...props}
    >
      {status}
    </FilledButton>
  );
}
