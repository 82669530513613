import { CrossIcon } from "assets/icons";
import Modal from "common/Modal";
import { useState } from "react";

export default function RemoveCareer({ onRemove }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  return (
    <>
      <Modal
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        heading="Remove Career"
        action="Remove"
        onConfirm={() => {
          onRemove();
          setShowDeleteConfirmation(false);
        }}
      >
        <p>
          Are you sure you want to remove this career from this major? This will
          also remove all the sub-careers associated with this career from this
          major.
        </p>
      </Modal>
      <div
        className="border-2 border-black rounded-full p-1 absolute top-0 left-0 bg-white cursor-pointer"
        onClick={() => {
          setShowDeleteConfirmation(true);
        }}
      >
        <CrossIcon width={"8"} height={"7"} />
      </div>
    </>
  );
}
