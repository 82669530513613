
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    courses: [],
    loaded: false
};

const coursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {
        setCourses: (state, action) => {
            state.courses = action.payload;
            state.loaded = true;
        },
        addCourse: (state, action) => {
            state.courses.push(action.payload);
        },
        updateCourse: (state, action) => {
            const { _id, ...rest } = action.payload;
            const index = state.courses.findIndex((course) => course._id === _id);
            state.courses[index] = { ...state.courses[index], ...rest };
        }
    }
});

export const { setCourses, addCourse, updateCourse } = coursesSlice.actions; 
export default coursesSlice.reducer;