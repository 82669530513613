export const BatchStatusStyle = {
  assigned: {
    backgroundColor: "#6DE55A",
    color: "#21760C",
  },
  disabled: {
    backgroundColor: "#FFA7A7",
    color: "#760C0C",
  },
  expired: {
    backgroundColor: "#A7FFEF",
    color: "#0C7663",
  },
  pending: {
    backgroundColor: "#FFF1A7",
    color: "#76380C",
  },
  draft: {
    backgroundColor: "#E0E0E0",
    color: "#2E2E2E",
  },
};

export const BatchLevelStyle = {
  1: {
    color: "#5A70E8",
  },
  2: {
    color: "#E8965A",
  },
  3: {
    color: "#DD5AE8",
  },
};

export const KeyStatusStyle = {
  activated: {
    backgroundColor: "#6DE55A",
    color: "#21760C",
  },
  pending: {
    backgroundColor: "#FFF1A7",
    color: "#76380C",
  },
  disabled: {
    backgroundColor: "#FFA7A7",
    color: "#760C0C",
  },
};
