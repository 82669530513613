export default function DeleteIcon({
  fill = "#c3c4c9",
  width = "19.433",
  height = "20.512",
}) {
  return (
    <svg
      id="trash-empty"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "19.433"}
      height={height || "20.512"}
      viewBox="0 0 19.433 20.512"
    >
      <path
        id="Path_701"
        data-name="Path 701"
        d="M19.614,7.739V6.659A2.159,2.159,0,0,0,17.455,4.5H10.978A2.159,2.159,0,0,0,8.818,6.659v1.08H5.58a1.08,1.08,0,1,0,0,2.159h1.08V21.773A3.239,3.239,0,0,0,9.9,25.012h8.637a3.239,3.239,0,0,0,3.239-3.239V9.9h1.08a1.08,1.08,0,1,0,0-2.159Zm-2.159-1.08H10.978v1.08h6.478ZM19.614,9.9H8.818V21.773a1.08,1.08,0,0,0,1.08,1.08h8.637a1.08,1.08,0,0,0,1.08-1.08Z"
        transform="translate(-4.5 -4.5)"
        fill={fill || "#c3c4c9"}
        fill-rule="evenodd"
      />
    </svg>
  );
}
