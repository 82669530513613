import React from "react";
import { twMerge } from "tailwind-merge";

const OutlinedButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        "border text-gray-700 font-semibold py-1 px-10 rounded-full focus:outline-none focus:shadow-outline  flex items-center justify-center gap-3 cursor-pointer",
        className || ""
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default OutlinedButton;
