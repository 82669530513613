import { useUpdateMajor } from "api/major";
import { QuestionIcon } from "assets/icons";
import { Input, MediaInput } from "common/Input";
import MediaPreview from "common/Input/MediaPreview";
import Label from "common/Label";
import Modal from "common/Modal";
import { getMediaName, useUtilities } from "helper";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Delete, EditIcon } from "assets/icons";
import Select from "common/Select";
import { useAddQna, useQna, useUpdateQna } from "api/qna";
import {
  useAddMajorAcademy,
  useMajorAcademy,
  useUpdateMajorAcademy,
} from "api/majorAcademy";
import {useUploadFiles} from "api/uploadFiles";

export function QNA({ question, onEdit, onDelete }) {
  return (
    <>
      <div className="flex items-center justify-between w-full">
        <h3 className="font-medium text-sm bg-white border border-theme-gray rounded-lg px-3 py-2 w-10/12">
          {question}
        </h3>
        <div className="flex items-center gap-2">
          <div onClick={onEdit} className="cursor-pointer">
            <EditIcon />
          </div>
          <div onClick={onDelete} className="cursor-pointer">
            <Delete />
          </div>
        </div>
      </div>
    </>
  );
}

export default function QNASection({ majorData }) {
  const { mutateAsync: uploadFiles } = useUploadFiles();
  const { data: qna } = useQna();
  const { data: majorAcademy } = useMajorAcademy();
  const { notification } = useUtilities();
  const { majorId } = useParams();
  const { mutate: updateMajor, isLoading: isUpdatingMajor } =
    useUpdateMajor(majorId);
  const { mutate: addMajorAcademy } = useAddMajorAcademy();
  const { mutate: updateAcademy } = useUpdateMajorAcademy();
  const { mutate: addQna, isLoading: addingSkill } = useAddQna();
  const { mutate: updateQna, isLoading: updatingSkill } = useUpdateQna();
  const [showModal, setShowModal] = useState(false);
  const [selectedQna, setSelectedQna] = useState(-1);
  const academy = majorAcademy.find((_) => _._id === majorData?.academy?._id);
  const qnas = academy
    ? qna?.filter((item) => academy?.qna?.includes(item._id))
    : [];
  const otherQnas = academy
    ? qna?.filter((item) => !academy?.qna?.includes(item._id))
    : qna;
  const [qnaValue, setqnaValue] = useState({
    question: "",
    image: "",
    answer: "",
  });

  const handleConfirm = async () => {
    let qnaInput = { ...qnaValue };
    if (!qnaInput.question) {
      notification({ type: "error", message: "Please enter question" });
      return;
    }

    if (!qnaInput.answer) {
      notification({
        type: "error",
        message: "Please enter answer",
      });

      return;
    }

    if (typeof qnaInput.image !== "string" && qnaInput.image) {
      const img = await uploadFiles([qnaInput.image]);
      qnaInput.image = img.data[0];
    } else {
      qnaInput.image = getMediaName(qnaInput.image) || "";
    }

    if (qnaInput.id) {
      updateQna(qnaInput);
      setShowModal(false);
      setqnaValue({
        question: "",
        image: "",
        answer: "",
      });
      return;
    }
    if (majorData.academy) {
      addQna({
        data: {
          question: qnaInput.question,
          image: qnaInput.image,
          answer: qnaInput.answer,
        },
        callback: (skillId) => {
          updateAcademy({
            majorAcademyId: majorData.academy._id,
            data: {
              qna: [...(academy?.qna || []), skillId],
            },
          });
        },
      });
    } else {
      addQna({
        data: {
          question: qnaInput.question,
          image: qnaInput.image,
          answer: qnaInput.answer,
        },
        callback: (skillId) => {
          addMajorAcademy({
            data: {
              qna: [skillId],
            },
            callback: (majorAcademy) => {
              updateMajor({
                academy: majorAcademy,
              });
            },
          });
        },
      });
    }

    setShowModal(false);
    setqnaValue({
      question: "",
      image: "",
      answer: "",
    });
  };

  const handleAddQna = (skillId) => {
    if (majorData.academy) {
      updateAcademy({
        majorAcademyId: majorData.academy._id,
        data: {
          qna: [...(academy?.qna || []), skillId],
        },
      });
    } else {
      addMajorAcademy({
        data: {
          qna: [skillId],
        },
        callback: (majorAcademy) => {
          updateMajor({
            academy: majorAcademy,
          });
        },
      });
    }
    setSelectedQna(-1);
  };

  return (
    <>
      <Modal
        open={showModal}
        heading={`${qnaValue.id ? "Edit" : "Add"} Q&A`}
        onCancel={() => {
          setShowModal(false);
          setqnaValue({
            title: "",
            image: "",
            description: "",
          });
        }}
        onConfirm={handleConfirm}
        confirmText="Save"
      >
        <div className="flex flex-col gap-3">
          <Input
            value={qnaValue.question}
            onChange={(e) =>
              setqnaValue({
                ...qnaValue,
                question: e.target.value,
              })
            }
            placeholder="Enter Question here"
            className="shadow-none w-full"
          />
          <Input
            type="textarea"
            className="shadow-none w-full"
            placeholder="Enter Answer here"
            value={qnaValue.answer}
            onChange={(e) =>
              setqnaValue({
                ...qnaValue,
                answer: e.target.value,
              })
            }
          />

          {qnaValue.image ? (
            <MediaPreview
              url={URL.createObjectURL(qnaValue.image)}
              onRemove={() =>
                setqnaValue({
                  ...qnaValue,
                  image: "",
                })
              }
            />
          ) : (
            <MediaInput
              onChange={(media) =>
                setqnaValue({
                  ...qnaValue,
                  image: media,
                })
              }
              className="shadow-none w-full max-w-none"
              allowedTypes={["png", "jpg", "jpeg"]}
            />
          )}
        </div>
      </Modal>
      <Label
        label="Q&A"
        tooltip="Select or create questions and answers you think are most frequently asked by students."
      />
      <Select
        placeholder="Question"
        icon={<QuestionIcon />}
        options={[
          {
            label: "+ Add Qna",
            value: "",
          },
          ...otherQnas?.map((_) => ({
            label: _.question,
            value: _._id,
          })),
        ]}
        value={selectedQna}
        onChange={(value, index) => {
          if (index === 0) {
            setShowModal(true);
          } else {
            setSelectedQna(index);
            handleAddQna(value);
          }
        }}
        emptyMessageClass="hover:bg-slate-200 cursor-pointer"
        emptyMessage={
          <p
            className=" text-theme-blue underline"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Add new Q&A
          </p>
        }
      />
      <div className="flex gap-2 flex-wrap w-full my-3">
        {qnas?.map((item, index) => (
          <QNA
            question={item.question}
            onEdit={() => {
              setShowModal(true);
              setqnaValue({
                question: item.question,
                answer: item.answer,
                image: item.image,
                id: item._id,
              });
            }}
            onDelete={() =>
              updateAcademy({
                majorAcademyId: academy._id,
                data: {
                  qna: academy.qna.filter((_) => _ !== item._id),
                },
              })
            }
          />
        ))}
      </div>
    </>
  );
}
