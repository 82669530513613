import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  careerCalender: [],
  loaded: false,
  institute: '',
};

const careerCalenderSlice = createSlice({
  name: "careerCalender",
  initialState,
  reducers: {
    setCareerCalender(state, action) {
      const { institute, careerCalender } = action.payload;
      state.careerCalender = careerCalender;
      state.loaded = true;
      state.institute = institute;
    },
    addCareerCalender(state, action) {
      state.careerCalender.push(action.payload);
    },
    updateSingleCareerCalender(state, action) {
      const index = state.careerCalender.findIndex(
        (careerCalender) => careerCalender._id === action.payload._id
      );
      state.careerCalender[index] = action.payload;
    },
    deleteCareerCalender(state, action) {
      state.careerCalender = state.careerCalender.filter(
        (careerCalender) => careerCalender._id !== action.payload
      );
    },
  },
});

export const {
  setCareerCalender,
  addCareerCalender,
  updateSingleCareerCalender,
  deleteCareerCalender,
} = careerCalenderSlice.actions;

export default careerCalenderSlice.reducer;
