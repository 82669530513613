import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  days: [],
  loaded: false,
};

const daysSlice = createSlice({
  name: "days",
  initialState,
  reducers: {
    setDays(state, action) {
      state.days = action.payload;
      state.loaded = true;
    },
    addDay(state, action) {
      state.days.push(action.payload);
    },
    updateSingleDay(state, action) {
      const index = state.days.findIndex(
        (days) => days._id === action.payload._id
      );
      state.days[index] = action.payload;
    },
    deleteDay(state, action) {
      state.days = state.days.filter((days) => days._id !== action.payload);
    },
  },
});

export const { setDays, addDay, updateSingleDay, deleteDay } =
  daysSlice.actions;

export default daysSlice.reducer;
