export default function RotateIcon({ width = "19", height = "19", stroke="1.5" }) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0832 5.50033C10.0832 8.03033 8.02984 10.0837 5.49984 10.0837C2.96984 10.0837 1.42525 7.53533 1.42525 7.53533M1.42525 7.53533H3.49692M1.42525 7.53533V9.82699M0.916504 5.50033C0.916504 2.97033 2.9515 0.916992 5.49984 0.916992C8.55692 0.916992 10.0832 3.46533 10.0832 3.46533M10.0832 3.46533V1.17366M10.0832 3.46533H8.04817"
          stroke="#292D32"
          stroke-width={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
