import React from "react";
import { twMerge } from "tailwind-merge";

function CustomRadio({ id, name, label, value, checked, onChange, className }) {
  return (
    <div className="flex items-center gap-6">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <label htmlFor={id} className="cursor-pointer flex items-center gap-1.5">
        <div className="w-5 h-5 min-w-[20px] min-h-[20px] border border-[#707070] rounded-full flex items-center justify-center bg-theme-gray">
          {checked && (
            <div className="w-3 h-3 bg-theme-purple rounded-full"></div>
          )}
        </div>
        <span className={twMerge("ml-2", className)}>{label}</span>
      </label>
    </div>
  );
}

export default CustomRadio;
