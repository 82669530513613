export default function Delete() {
  return (
    <>
      <svg
        id="trash"
        xmlns="http://www.w3.org/2000/svg"
        width="17.866"
        height="17.866"
        viewBox="0 0 17.866 17.866"
      >
        <path
          id="Vector"
          d="M13.4.372C10.921.127,8.427,0,5.941,0A43.972,43.972,0,0,0,1.519.223L0,.372"
          transform="translate(2.233 4.079)"
          fill="none"
          stroke="#e8310c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,2.211l.164-.975C.283.529.372,0,1.63,0h1.95C4.839,0,4.936.558,5.047,1.243l.164.968"
          transform="translate(6.328 1.489)"
          fill="none"
          stroke="#e8310c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M10.2,0,9.715,7.5c-.082,1.169-.149,2.077-2.226,2.077H2.71C.633,9.573.566,8.665.484,7.5L0,0"
          transform="translate(3.834 6.804)"
          fill="none"
          stroke="#e8310c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H2.479"
          transform="translate(7.69 12.283)"
          fill="none"
          stroke="#e8310c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M0,0H3.722"
          transform="translate(7.072 9.305)"
          fill="none"
          stroke="#e8310c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Vector-6"
          data-name="Vector"
          d="M0,0H17.866V17.866H0Z"
          fill="none"
          opacity="0"
        />
      </svg>
    </>
  );
}
