import { useState, useEffect } from "react";
import { CrossIcon } from "assets/icons";
import { NotificationType } from "./constant";
import { imgLink } from "helper";

export default function Notification({ type, message, media, ...props }) {
  const [exit, setExit] = useState(false);
  const [width, setWidth] = useState(0);
  const [intervalID, setIntervalID] = useState(null);

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setWidth((prev) => {
        if (prev < 100) {
          return prev + 0.5;
        }

        clearInterval(id);
        return prev;
      });
    }, 20);

    setIntervalID(id);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalID);
  };

  const handleCloseNotification = () => {
    handlePauseTimer();
    setExit(true);
    if (type === NotificationType.MEDIA_UPLOAD) {
      if (props.progress === 100) {
        setTimeout(() => {
          props.dispatch({
            type: "REMOVE_NOTIFICATION",
            id: props.id,
          });
        }, 400);
      }
      return;
    } else {
      setTimeout(() => {
        props.dispatch({
          type: "REMOVE_NOTIFICATION",
          id: props.id,
        });
      }, 400);
    }
  };

  useEffect(() => {
    if (width === 100) {
      handleCloseNotification();
    }
  }, [width]);

  useEffect(() => {
    if (type === NotificationType.MEDIA_UPLOAD) {
      if (props.progress === 100) {
        handleCloseNotification();
      }
    } else {
      handleStartTimer();
    }
  }, [props.progress]);

  return (
    <>
      <div
        onMouseEnter={handlePauseTimer}
        onMouseLeave={() => {
          if (type === NotificationType.MEDIA_UPLOAD) {
            if (props.progress === 100) {
              handleCloseNotification();
            }
          } else {
            handleStartTimer();
          }
        }}
        className={`notification gap-3 ${
          exit ? "notification-hidden" : "notification-open"
        }`}
        style={{
          background:
            type === NotificationType.MEDIA_UPLOAD || type === NotificationType.INFO
              ? "#2196F3"
              : type.toLowerCase() !== NotificationType.SUCCESS
              ? "#F12424"
              : "#00D263",
        }}
      >
        {renderMedia(media)}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-10">
            <p className="text-base m-0 text-white">
              {type === NotificationType.MEDIA_UPLOAD
                ? Array.isArray(media) ? `Uploading ${media.length} media ${props.progress}%` : `Uploading... ${props.progress}%`
                : message}
            </p>
          </div>
          <div onClick={handleCloseNotification} className="cursor-pointer">
            <CrossIcon className="w-4 h-4 mr-1 min-w-4 min-h-4" fill="white" />
          </div>
        </div>
      </div>
    </>
  );
}

function renderMedia(media) {
  if (!media) return "";
  let singleMedia = Array.isArray(media) ? media[0] : media;
  return (
    <img
      className="w-full h-32 object-cover opacity-70 mx-auto"
      src={imgLink(singleMedia)}
      alt="media"
    />
  );
}
