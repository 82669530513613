import { useKeysByBatch } from "api/batches";
import { StatusButton } from "common/StatusButton";
import { useParams } from "react-router-dom";
import { BatchLevelStyle, BatchStatusStyle } from "../enum";
import { BackArrow } from "assets/icons";

export default function Details() {
  const { batchId } = useParams();
  const { data } = useKeysByBatch(batchId);
  const batchDetails = data?.[0]?.batch || {};

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <h3 className="text-3xl">{batchDetails.title}</h3>
          <div className="flex items-center gap-2.5">
            <div className="w-8 h-8 rounded-full bg-[#FFA7A7] flex items-center justify-center text-white font-medium text-sm uppercase">
              {batchDetails?.created_by?.username
                ?.split(" ")
                ?.map((batchDetails) => batchDetails[0])
                .join("")}
            </div>
            {batchDetails?.created_by?.username}
          </div>
        </div>
        <div className="flex gap-3 mt-5 flex-wrap items-center">
          <StatusButton
            status={batchDetails?.status}
            style={BatchStatusStyle[batchDetails.status]}
            className="py-0.5 rounded-xl capitalize"
          />
          <StatusButton
            status={`Level ${batchDetails?.level}`}
            style={{
              backgroundColor: BatchLevelStyle[batchDetails?.level].color,
            }}
            className="py-0.5 capitalize rounded-full"
          />
          <p>
            {new Date(batchDetails.startDate)
              .toLocaleDateString("en-US", {
                month: "numeric",
                year: "numeric",
                day: "numeric",
              })
              .split("/")
              .join("-")}
          </p>
          <BackArrow className="rotate-180" size="18" />
          <p>
            {new Date(batchDetails.endDate)
              .toLocaleDateString("en-US", {
                month: "numeric",
                year: "numeric",
                day: "numeric",
              })
              .split("/")
              .join("-")}
          </p>
        </div>
        <div className="mt-14">
          <h3 className="text-[#414141] text-xl">Description</h3>
          <div className="border border-[#C2C2C2] rounded-lg p-3 mt-5">
            {batchDetails.description || "Description not available"}
          </div>
        </div>
      </div>
    </>
  );
}
