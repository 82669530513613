import { FilledButton, OutlinedButton } from "common/Button";

export default function FormFooter({
  onBack,
  hideBack,
  hideNext,
  nextText,
  disabledNextButton = false,
}) {
  return (
    <div className="flex">
      {!hideBack && (
        <OutlinedButton className={"py-1 px-4 rounded"} onClick={onBack}>
          Back
        </OutlinedButton>
      )}
      {!hideNext && (
        <FilledButton
          className={`py-1 px-4 rounded ml-auto ${
            disabledNextButton ? "bg-gray-400 cursor-not-allowed" : ""
          }`}
          type="submit"
        >
          {nextText}
        </FilledButton>
      )}
    </div>
  );
}
