import { useUpdateMajor } from "api/major";
import { useAddSkill, useSkills, useUpdateSkill } from "api/skills";
import { useUploadFiles } from "api/uploadFiles";
import { CrossIcon } from "assets/icons";
import { Input, MediaInput, SearchInput } from "common/Input";
import MediaPreview from "common/Input/MediaPreview";
import Label from "common/Label";
import Modal from "common/Modal";
import { useUtilities } from "helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function TechnicalSkill({ image, title, onRemove, onEdit }) {
  return (
    <>
      <div
        className="w-[5.5rem] h-12 rounded-lg border-theme-gray border relative bg-white flex items-center justify-center"
        onClick={onEdit}
      >
        <div
          className="absolute -top-1.5 -left-1.5 cursor-pointer bg-white border border-black rounded-full p-1"
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
        >
          <CrossIcon width="8" height="7" />
        </div>
        <img src={image} alt={title} className="w-[inherit] h-[inherit] object-cover" />
      </div>
    </>
  );
}

export default function TechnicalSkills({ majorData }) {
  const { data: skills } = useSkills();
  const { notification } = useUtilities();
  const { majorId } = useParams();
  const { mutate: updateMajor, isLoading: isUpdatingMajor } =
    useUpdateMajor(majorId);
  const { mutate: addSkill, isLoading: addingSkill } = useAddSkill();
  const { mutate: updateSkill, isLoading: updatingSkill } = useUpdateSkill();
  const [technicalSkillSearch, settechnicalSkillSearch] = useState("");
  const [isTechnicalSkillFocused, setIsTechnicalSkillFocused] = useState(false);
  const [technicalSkillFilter, settechnicalSkillFilter] = useState([]);
  const { mutateAsync: uploadFiles } = useUploadFiles();
  const [showModal, setShowModal] = useState(false);
  const technicalSkills = skills?.filter(
    (item) =>
      majorData?.technical_skills?.includes(item._id) &&
      item.type === "technical"
  );
  const [technicalSkillValue, settechnicalSkillValue] = useState({
    title: "",
    image: "",
    description: "",
  });

  useEffect(() => {
    if (skills.length === 0) return;
    settechnicalSkillFilter(
      skills
        .filter(
          (item) =>
            item.title
              .toLowerCase()
              .includes(technicalSkillSearch.toLowerCase()) &&
            item.type === "technical" &&
            technicalSkills?.every((skill) => skill._id !== item._id)
        )
        .map((item, index) => (
          <p
            className=" px-3 hover:bg-slate-200 py-1 cursor-pointer"
            onClick={() => {
              if (majorData?.technical_skills.length >= 6) {
                notification({
                  type: "error",
                  message: "Maximum 6 skills allowed",
                });
                return;
              }
              updateMajor({
                technical_skills: [...majorData.technical_skills, item._id],
              });
              settechnicalSkillSearch("");
            }}
          >
            {item.title}
          </p>
        ))
    );
  }, [skills, technicalSkillSearch]);

  const handleConfirm = async () => {
    if (!technicalSkillValue.title) {
      notification({ type: "error", message: "Please enter title" });
      return;
    }

    if (!technicalSkillValue.image) {
      notification({ type: "error", message: "Please choose image" });
      return;
    }
    if (!technicalSkillValue.description) {
      notification({
        type: "error",
        message: "Please enter description",
      });

      return;
    }
    setShowModal(false);
    if (typeof technicalSkillValue.image !== "string") {
      const data = await uploadFiles({data: technicalSkillValue.image});
      if (data.statusCode !== 201) return;
      technicalSkillValue.image = data.data[0];
    }

    if (technicalSkillValue.id) {
      updateSkill(technicalSkillValue);
      setShowModal(false);
      settechnicalSkillValue({
        title: "",
        image: "",
        description: "",
      });
      return;
    }
    addSkill({
      data: {
        title: technicalSkillValue.title,
        image: technicalSkillValue.image,
        description: technicalSkillValue.description,
        type: "technical",
      },
      callback: (skillId) => {
        updateMajor({
          technical_skills: [...majorData.technical_skills, skillId],
        });
      },
    });

    setShowModal(false);
    settechnicalSkillValue({
      title: "",
      image: "",
      description: "",
    });
  };
  return (
    <>
      <Modal
        open={showModal}
        heading={`${technicalSkillValue.id ? "Edit" : "Add"} Technical skill`}
        onCancel={() => {
          setShowModal(false);
          settechnicalSkillValue({
            title: "",
            image: "",
            description: "",
          });
        }}
        onConfirm={handleConfirm}
        confirmText="Save"
      >
        <div className="flex flex-col gap-3">
          <Input
            value={technicalSkillValue.title}
            onChange={(e) =>
              settechnicalSkillValue({
                ...technicalSkillValue,
                title: e.target.value,
              })
            }
            placeholder="Enter Title here"
            className="shadow-none w-full"
          />
          {technicalSkillValue.image ? (
            <MediaPreview
              url={
                typeof technicalSkillValue.image === "string"
                  ? technicalSkillValue.image
                  : URL.createObjectURL(technicalSkillValue.image)
              }
              onRemove={() =>
                settechnicalSkillValue({
                  ...technicalSkillValue,
                  image: "",
                })
              }
            />
          ) : (
            <MediaInput
              onChange={(media) =>
                settechnicalSkillValue({
                  ...technicalSkillValue,
                  image: media,
                })
              }
              id={`technical-skill-image-${technicalSkillValue?.id}`}
              className="shadow-none w-full max-w-none"
              allowedTypes={["png", "jpg", "jpeg"]}
            />
          )}
          <Input
            type="textarea"
            className="shadow-none w-full"
            placeholder="Enter Description here"
            value={technicalSkillValue.description}
            onChange={(e) =>
              settechnicalSkillValue({
                ...technicalSkillValue,
                description: e.target.value,
              })
            }
          />
        </div>
      </Modal>
      <Label
        label="Technical Skills"
        tooltip="Select technical skills that students will acquire or should have when considering this Life Choice."
      />
      <div className="relative">
        <SearchInput
          placeholder="Search or add new"
          className="min-w-[200px] w-min"
          value={technicalSkillSearch}
          onChange={(e) => settechnicalSkillSearch(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (majorData?.technical_skills.length >= 6) {
                notification({
                  type: "error",
                  message: "Maximum 6 skills allowed",
                });
                return;
              }
              setShowModal(true);
              settechnicalSkillValue({
                ...technicalSkillValue,
                title: technicalSkillSearch,
              });
            }
          }}
          onFocus={() => setIsTechnicalSkillFocused(true)}
          onBlur={() =>
            setTimeout(() => setIsTechnicalSkillFocused(false), 200)
          }
        />
        {isTechnicalSkillFocused && technicalSkillSearch && (
          <div
            onClickOutside={() => {}}
            className="absolute top-full left-0 w-full bg-white rounded-lg shadow-lg py-3 z-10"
          >
            <div className="flex flex-col gap-2">
              <p
                className=" px-3 hover:bg-slate-200 py-1 cursor-pointer"
                onClick={() => {
                  if (majorData?.technical_skills.length >= 6) {
                    notification({
                      type: "error",
                      message: "Maximum 6 skills allowed",
                    });
                    return;
                  }
                  setShowModal(true);
                  settechnicalSkillValue({
                    ...technicalSkillValue,
                    title: technicalSkillSearch,
                  });
                }}
              >
                Add&nbsp;
                <span className="text-theme-blue underline">
                  {technicalSkillSearch}
                </span>
              </p>
              {technicalSkillFilter}
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-2 flex-wrap m-3">
        {technicalSkills?.map((item, index) => (
          <TechnicalSkill
            image={item.image}
            title={item.title}
            onRemove={() =>
              updateMajor({
                technical_skills: majorData.technical_skills.filter(
                  (skill) => skill !== item._id
                ),
              })
            }
            onEdit={() => {
              setShowModal(true);
              settechnicalSkillValue({
                title: item.title,
                image: item.image,
                description: item.description,
                id: item._id,
              });
            }}
          />
        ))}
      </div>
    </>
  );
}
