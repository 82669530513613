import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  batchDetails: [],
  loaded: false,
  batch: "",
  limit: 10,
  nextPage: 1,
  hasNextPage: false,
  totalDocs: 0,
  totalPages: 0,
  currentPage: 1,
  staled: true,
};

const batchDetailsSlice = createSlice({
  name: "batchDetails",
  initialState,
  reducers: {
    setBatcheDetailsAction: (state, action) => {
      const {
        batch,
        batchDetails,
        limit,
        nextPage,
        hasNextPage,
        totalDocs,
        totalPages,
        currentPage,
      } = action.payload;
      state.batchDetails = batchDetails;
      state.loaded = true;
      state.batch = batch;
      state.limit = limit;
      state.nextPage = nextPage;
      state.hasNextPage = hasNextPage;
      state.totalDocs = totalDocs;
      state.totalPages = totalPages;
      state.currentPage = currentPage;
      state.staled = false;
    },
    updateBatchDetailsAction: (state, action) => {
      state.batchDetails = action.payload;
    },
    setBatchDataStaled: (state, action) => {
      state.staled = action.payload;
    }
  },
});

export const { setBatcheDetailsAction, updateBatchDetailsAction, setBatchDataStaled } = batchDetailsSlice.actions;

export default batchDetailsSlice.reducer;
