import { axios, useUtilities } from "helper";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { setDays, addDay, updateSingleDay, deleteDay } from "redux/reducers/days";

async function getDay() {
  return axios
    .get("days")
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetDay() {
  return useQuery(["days"], getDay);
}

export function useDay() {
  const { data, isLoading, isError, error } = useGetDay();
  const {
    dispatch,
    days: { days, loaded },
  } = useUtilities();
  useEffect(() => {
    if (data && !loaded) {
      dispatch(setDays(data.data));
    }
  }, [data]);
  return {
    data: days,
    isLoading: isLoading || !loaded,
    isError,
    error,
  };
}

async function createDay({ data, callback }) {
  return await axios
    .post("days", data)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useAddDay() {
  const { notification, dispatch } = useUtilities();
  return useMutation(createDay, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addDay(data.data));
        notification({
          message: "Day added successfully",
          type: "success",
        });
      } else {
        notification({
          message: data.message,
          type: "error",
        });
      }
    },
    onError: (err) => {
      console.log(err);
      notification({
        message: err.message,
        type: "error",
      });
    },
  });
}

async function updateDay({ data, callback }) {
  const { id, ...rest } = data;
  return await axios
    .put(`days/${id}`, rest)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useUpdateDay() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateDay, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleDay(data.data));
        notification({
          message: "Day updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: data.message,
          type: "error",
        });
      }
    },
  });
}

async function removeDay({ id }) {
  return await axios
    .delete(`days/${id}`)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useDeleteDay() {
  const { notification, dispatch } = useUtilities();
  return useMutation(removeDay, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(deleteDay(data.data));
        notification({
          message: "Day deleted successfully",
          type: "success",
        });
      } else {
        notification({
          message: data.message,
          type: "error",
        });
      }
    },
  });
}
