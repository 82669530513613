import Label from "common/Label";
import { SearchInput } from "common/Input";
import { YearsShow } from "../../InSightTab";
import { useEffect, useState } from "react";
import Modal from "common/Modal";
import { Input, MediaPreview, MediaInput } from "common/Input";
import { useUtilities, search } from "helper";
import { useUploadFiles } from "api/uploadFiles";
import { useCourses, useCreateCourse } from "api/courses";
import { useUpdateMajor } from "api/major";
import {
  getSingleMajorAcademy,
  useAddCouresToMajorAcademy,
  useAddMajorAcademy,
} from "api/majorAcademy";
import { CheckIcon } from "assets/icons";
import EditCourse from "./EditCourse";

export default function CourseSection({ majorData }) {
  const [currentYear, setCurrentYear] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setinputValue] = useState({
    title: "",
    images: [],
    description: "",
    credits: 3,
    topics: [],
  });
  const { notification } = useUtilities();
  const { mutate: addCourse, isLoading: addingCourse } = useCreateCourse();

  const { mutateAsync: addMajorAcademy } = useAddMajorAcademy();
  const { mutate: updateMajor } = useUpdateMajor(majorData?._id);

  const { data: courses } = useCourses();

  const { mutateAsync: AddCourseToMajorAcademy } = useAddCouresToMajorAcademy();

  const [courseSearch, setcourseSearch] = useState("");
  const [courseFilter, setcourseFilter] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [MajorCourses, setMajorCourses] = useState([]);
  const [editCourse, setEditCourse] = useState();

  const {
    isLoading: uploadingFiles,
    error,
    data,
    mutate: uploadFiles,
  } = useUploadFiles();

  useEffect(() => {
    setcourseFilter(
      courses?.filter(
        (ele) =>
          search(ele.title, courseSearch) &&
          !MajorCourses?.some((item) => item.course[0]?._id === ele._id)
      )
    );
  }, [courseSearch]);

  useEffect(() => {
    if (majorData?.academy)
      getSingleMajorAcademy({
        id: majorData?.academy?._id,
      }).then((res) => {
        if (res.statusCode === 200) {
          setMajorCourses(res.data.courses);
        }
      });
  }, [majorData?.academy]);

  const handleConfirm = () => {
    if (!inputValue.title) {
      notification({ type: "error", message: "Please enter title" });
      return;
    }

    if (!inputValue.description) {
      notification({
        type: "error",
        message: "Please enter description",
      });

      return;
    }
    if (inputValue.id) {
      setShowModal(false);
      setinputValue({
        title: "",
        images: [],
        description: "",
      });
      return;
    }
    if (inputValue.images.length === 0) {
      notification({
        type: "error",
        message: "Please choose image",
      });

      return;
    }
    uploadFiles({
      data: inputValue.images,
      callback: (res) => {
        addCourse({
          data: {
            ...inputValue,
            images: [...res.data],
          },
          callback: (res) => {
            if (!majorData?.academy) {
              addMajorAcademy({
                data: {
                  courses: [
                    {
                      course: res.data._id,
                      year: currentYear,
                    },
                  ],
                },
                callback: (majorAcademyID) => {
                  updateMajor({
                    academy: majorAcademyID,
                  });
                },
              });
              return;
            }
            AddCourseToMajorAcademy({
              id: majorData?.academy._id,
              data: {
                course: res.data._id,
                year: currentYear,
              },
            }).then((res) => {
              if (res.statusCode === 200) {
                setMajorCourses(res.data.courses);
              }
            });
          },
        });
        setinputValue({
          title: "",
          images: [],
          description: "",
          credits: 3,
          topics: [],
        });
        setShowModal(false);
      },
    });
  };

  return (
    <>
      <Modal
        open={showModal}
        heading={`${inputValue.id ? "Edit" : "Add"} Course`}
        onCancel={() => {
          setShowModal(false);
          setinputValue({
            title: "",
            images: [],
            description: "",
            credits: 3,
            topics: [],
          });
        }}
        onConfirm={handleConfirm}
        isLoading={uploadingFiles || addingCourse}
        confirmText={uploadingFiles || addingCourse ? "Saving..." : "Save"}
      >
        <div className="flex flex-col gap-3">
          <Input
            value={inputValue.title}
            onChange={(e) =>
              setinputValue({
                ...inputValue,
                title: e.target.value,
              })
            }
            placeholder="Enter Title here"
            className="shadow-none w-full"
          />
          <Input
            value={inputValue.credits}
            type="number"
            min={0}
            max={100}
            onChange={(e) =>
              setinputValue({
                ...inputValue,
                credits: e.target.value,
              })
            }
            placeholder="Enter Credits here"
            className="shadow-none w-full"
          />
          <Input
            type="textarea"
            className="shadow-none w-full"
            placeholder="Enter Description here"
            value={inputValue.description}
            onChange={(e) =>
              setinputValue({
                ...inputValue,
                description: e.target.value,
              })
            }
          />
          <MediaInput
            id="course"
            onChange={(media) =>
              setinputValue({
                ...inputValue,
                images: [...inputValue.images, ...media],
              })
            }
            className="shadow-none w-full max-w-none"
            multiple={true}
            allowedTypes={["png", "jpg", "jpeg"]}
          />
          <div className="flex items-center gap-3">
            {inputValue.images.map((image) => (
              <MediaPreview
                url={URL.createObjectURL(image)}
                onRemove={() =>
                  setinputValue({
                    ...inputValue,
                    images: inputValue.images.filter((item) => item !== image),
                  })
                }
              />
            ))}
          </div>
        </div>
      </Modal>
      <EditCourse
        courseData={editCourse}
        setCourseData={setEditCourse}
        updateCourseState={(course) => {
          setMajorCourses((prev) => {
            const index = prev.findIndex(
              (item) => item.course?.[0]?._id === course._id
            );
            if (index === -1) return prev;
            let temp = [...prev];
            temp[index] = {
              ...prev[index],
              course: [course],
            };

            return [...temp];
          });
        }}
      />
      <div className="flex items-center justify-between">
        <Label
          label="Courses"
          tooltip="Select or create courses that students will go through while taking this Major."
        />
        <div className="flex items-center gap-1.5 my-4">
          {new Array(majorData?.duration?.value ?? 4)
            .fill(0)
            .map((item, index) => (
              <YearsShow
                value={index + 1}
                key={index}
                active={currentYear === index + 1}
                onClick={() => setCurrentYear(index + 1)}
                initial={majorData?.duration.type[0]}
              />
            ))}
        </div>
      </div>
      <div className="relative w-full">
        <SearchInput
          containerClass="w-full max-w-[100%]"
          placeholder="Search Course"
          className="min-w-[200px] w-full max-w-lg"
          value={courseSearch}
          onChange={(e) => setcourseSearch(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setShowModal(true);
              setinputValue({
                ...inputValue,
                title: courseSearch,
              });
            }
          }}
          onFocus={() => setInputFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setInputFocused(false);
            }, 500);
          }}
        />
        {inputFocused && courseSearch && (
          <div
            onClickOutside={() => {}}
            className="absolute top-full left-0 w-full bg-white rounded-lg shadow-lg py-3 z-10"
          >
            <div className="flex flex-col gap-2">
              <p
                className=" px-3 hover:bg-slate-200 py-1 cursor-pointer"
                onClick={() => {
                  setShowModal(true);
                  setinputValue({
                    ...inputValue,
                    title: courseSearch,
                  });
                }}
              >
                Add&nbsp;
                <span className="text-theme-blue underline">
                  {courseSearch}
                </span>
              </p>
              {courseFilter.map((ele) => (
                <p
                  className=" px-3 hover:bg-slate-200 py-1 cursor-pointer"
                  onClick={() => {
                    if (!majorData?.academy) {
                      addMajorAcademy({
                        data: {
                          courses: [
                            {
                              course: ele._id,
                              year: currentYear,
                            },
                          ],
                        },
                        callback: (majorAcademyID) => {
                          updateMajor({
                            academy: majorAcademyID,
                          });
                        },
                      });
                      return;
                    }
                    AddCourseToMajorAcademy({
                      id: majorData?.academy._id,
                      data: {
                        course: ele._id,
                        year: currentYear,
                      },
                    }).then((res) => {
                      if (res.statusCode === 200) {
                        setMajorCourses(res.data.courses);
                      }
                    });
                  }}
                >
                  <span className="text-gray-700">{ele.title}</span>
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
      {MajorCourses.filter((ele) => ele.year === currentYear).map(
        (item, index) => {
          let courseCalculation =
            Number(
              (item.course?.[0]?.title ? 1 : 0) +
                (item.course?.[0]?.credits ? 1 : 0) +
                (item.course?.[0]?.description ? 1 : 0) +
                (item.course?.[0]?.images?.length ? 1 : 0) +
                item.course?.[0]?.questions
                  ?.map((ele) => {
                    return (
                      (ele.question ? 1 : 0) +
                      (ele.subject ? 1 : 0) +
                      (ele.subject_image ? 1 : 0) +
                      (ele.options
                        ?.map((ele) => (ele.option ? 1 : 0))
                        .reduce((a, b) => a + b, 0) || 0)
                    );
                  })
                  .reduce((a, b) => a + b, 0)
            ) * 2.17391304348;
          return (
            <div
              className="flex items-center justify-between bg-white rounded-full border border-theme-gray px-4 py-2 my-2"
              onClick={() => setEditCourse(item.course[0])}
            >
              <div className="flex items-center gap-2 ">
                <h3 className="text-theme-yellow">{item.course?.[0]?.title}</h3>
                <p className="text-theme-yellow">
                  {courseCalculation === 100 ? (
                    <CheckIcon className="w-4 h-4 text-theme-green" />
                  ) : (
                    `${courseCalculation.toFixed(2)}%`
                  )}
                </p>
              </div>
              <p className="text-theme-purple text-sm">
                {item.course?.[0]?.credits}pt
              </p>
            </div>
          );
        }
      )}
    </>
  );
}
