import { MediaInput } from "common/Input";
import { InstituteSelect } from "../CustomInput";
import { useGetInstituteStaticData } from "api/institution";
import { cloneElement, useState } from "react";
import { CrossIcon } from "assets/icons";
import { getMediaName, useUtilities } from "helper";
import { useParams } from "react-router-dom";

function printFileName(file) {
  if (typeof file === "string") {
    return getMediaName(file);
  } else if (typeof file === "object") {
    return file.name;
  }
  return "";
}

export default function Create({ footer, updateData, institution }) {
  const { institutionId } = useParams();
  const { data } = useGetInstituteStaticData();
  const { notification } = useUtilities();
  const [inputValues, setInputValues] = useState(
    institutionId !== "add"
      ? {
          policy_template: institution?.policy_template ?? "",
          policy_file: institution?.policy_file ?? "",
          terms_template: institution?.terms_template ?? "",
          terms_file: institution?.terms_file ?? "",
        }
      : {
          policy_template: "",
          policy_file: "",
          terms_template: "",
          terms_file: "",
        }
  );
  const handleInputChange = (key, value) => {
    setInputValues((pre) => ({ ...pre, [key]: value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let errorMsg = "";
    if (inputValues.policy_template === "" && inputValues.policy_file === "") {
      errorMsg = "Please select/upload policy template";
    } else if (
      inputValues.terms_template === "" &&
      inputValues.terms_file === ""
    ) {
      errorMsg = "Please select/upload terms template";
    }
    if (errorMsg) {
      notification({
        type: "error",
        message: errorMsg,
      });
      return;
    }
    updateData(inputValues);
  };

  const disabledNextButton = () => {
    if (inputValues.policy_template === "" && inputValues.policy_file === "") {
      return true;
    } else if (
      inputValues.terms_template === "" &&
      inputValues.terms_file === ""
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className="flex mb-5 mt-10 gap-8">
          <div className="flex-1 bg-[#B3D8E8] shadow-md py-6 px-8 flex justify-between w-full">
            <div className="flex-[0.5]">
              <h2 className="text-[#1C1C70] text-xl font-medium">
                Privacy Policy
              </h2>
              <div className="mt-5">
                <InstituteSelect
                  tittle="Choose template"
                  label="Select type of template"
                  className="text-[#1C1C70]"
                  showTooltip
                  tooltipIcon={{
                    width: "18",
                    height: "18",
                    fill: "#1C1C70",
                  }}
                  options={Object.keys(data?.institue_privacy_policy ?? {}).map(
                    (key) => ({
                      label: data?.institue_privacy_policy[key],
                      value: key,
                    })
                  )}
                  value={inputValues.policy_template}
                  onChange={(value) =>
                    handleInputChange("policy_template", value)
                  }
                />
              </div>
              <div className="flex items-center my-4">
                <div className="h-[1px] w-full bg-[#818181]"></div>
                <h2 className="text-[#818181] text-[9px] font-medium px-4">
                  OR
                </h2>
                <div className="h-[1px] w-full bg-[#818181]"></div>
              </div>
              <div className="flex items-center justify-center flex-col gap-4">
                <MediaInput
                  className={"px-8 w-full mt-0 "}
                  iconSize="18"
                  text="Drag and drop or browse file (TXT)"
                  allowedTypes={["txt"]}
                  onChange={(value) => {
                    handleInputChange("policy_file", value);
                    handleInputChange("policy_template", "");
                  }}
                  id="policy_file"
                />
                {inputValues.policy_file && (
                  <div className="flex gap-2 items-center justify-center">
                    <div
                      className="cursor-pointer"
                      onClick={() => handleInputChange("policy_file", "")}
                    >
                      <CrossIcon fill={"black"} height={9} width={10} />
                    </div>
                    <p className="text-black text-sm font-medium">
                      {printFileName(inputValues.policy_file)}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="h-full flex items-center justify-end flex-1">
              <img
                src={require("assets/images/privacy-policy.png")}
                alt="privacy-policy"
                className="max-h-56"
              />
            </div>
          </div>
          <div className="flex-1 bg-[#B3D8E8] shadow-md py-6 px-8 flex justify-between w-full">
            <div className="flex-[0.5]">
              <h2 className="text-[#1C1C70] text-xl font-medium">
                Terms of Use
              </h2>
              <div className="mt-5">
                <InstituteSelect
                  tittle="Choose template"
                  label="Select type of template"
                  className="text-[#1C1C70]"
                  showTooltip
                  tooltipIcon={{
                    width: "18",
                    height: "18",
                    fill: "#1C1C70",
                  }}
                  options={Object.keys(data?.institue_terms_of_use ?? {}).map(
                    (key) => ({
                      label: data?.institue_terms_of_use[key],
                      value: key,
                    })
                  )}
                  value={inputValues.terms_template}
                  onChange={(value) =>
                    handleInputChange("terms_template", value)
                  }
                />
              </div>
              <div className="flex items-center my-4">
                <div className="h-[1px] w-full bg-[#818181]"></div>
                <h2 className="text-[#818181] text-[9px] font-medium px-4">
                  OR
                </h2>
                <div className="h-[1px] w-full bg-[#818181]"></div>
              </div>
              <div className="flex items-center justify-center flex-col gap-4">
                <MediaInput
                  className={"px-8 w-full mt-0 "}
                  iconSize="18"
                  text="Drag and drop or browse file (TXT)"
                  allowedTypes={["txt"]}
                  onChange={(value) => {
                    handleInputChange("terms_file", value);
                    handleInputChange("terms_template", "");
                  }}
                  id="terms_file"
                />
                {inputValues.terms_file && (
                  <div className="flex gap-2 items-center justify-center">
                    <div
                      className="cursor-pointer"
                      onClick={() => handleInputChange("terms_file", "")}
                    >
                      <CrossIcon fill={"black"} height={9} width={10} />
                    </div>
                    <p className="text-black text-sm font-medium">
                      {printFileName(inputValues.terms_file)}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="h-full flex items-center justify-end flex-1">
              <img
                src={require("assets/images/terms-of-use.png")}
                alt="privacy-policy"
                className="max-h-56"
              />
            </div>
          </div>
        </div>
        <div className="mt-20">
          {cloneElement(footer, {
            disabledNextButton: disabledNextButton(),
          })}
        </div>
      </form>
    </>
  );
}
