import { twMerge } from "tailwind-merge";
const FilledButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        "text-white font-semibold py-3 px-10 rounded-2xl focus:outline-none focus:shadow-outline bg-theme-blue flex items-center justify-center gap-3 disabled:bg-gray-400",
        className || ""
      )}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
};

export default FilledButton;
