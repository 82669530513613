import React, { useState } from "react";
import dayjs from "dayjs";
import { TimePicker } from "antd";
import Label from "common/Label";
const format = "HH:mm";

function TimeInput({
  selectedTimeSlots,
  value,
  onChange,
  label = "",
  labelClass = "",
  showTooltip = true,
  labelExtra,
  tooltipIcon,
  required = false,
}) {
  return (
    <>
      {label && (
        <Label
          className={labelClass}
          label={label}
          showTooltip={showTooltip}
          labelExtra={labelExtra}
          tooltipIcon={tooltipIcon}
          required={required}
        />
      )}
      <TimePicker
        defaultValue={dayjs("12:08", format)}
        format={format}
        allowClear={true}
        minuteStep={15}
        disabledTime={() => ({
          disabledHours: () => {
            const range = selectedTimeSlots
              ?.slice()
              .map((time) => [
                Number(time.start.split(":")[0]),
                Number(time.end.split(":")[0]),
              ]);
            const allHours = new Array(24).fill(0).map((_, i) => i);
            const disabledHours = allHours.filter((hour) => {
              return range.some((time) => {
                return hour > time[0] && hour < time[1];
              });
            });
            return disabledHours;
          },
          disabledMinutes: (selectedHour) => {
            const range = selectedTimeSlots?.slice().filter((time) => {
              let startHour = Number(time.start.split(":")[0]);
              let endHour = Number(time.end.split(":")[0]);
              if (
                startHour === selectedHour ||
                endHour === selectedHour ||
                (startHour < selectedHour && endHour > selectedHour)
              ) {
                return true;
              }
            });

            if (range.length > 0) {
              if (Number(range[0].start.split(":")[0]) === selectedHour) {
                const minute = Number(range[0].start.split(":")[1]);
                const disabledMinutes = [];
                for (let i = minute; i < 60; i++) {
                  disabledMinutes.push(i);
                }

                return disabledMinutes;
              } else if (Number(range[0].end.split(":")[0]) === selectedHour) {
                const minute = Number(range[0].end.split(":")[1]);
                const disabledMinutes = [];
                for (let i = 0; i <= minute; i++) {
                  disabledMinutes.push(i);
                }
                return disabledMinutes;
              } else if (
                Number(range[0].start.split(":")[0]) < selectedHour &&
                Number(range[0].end.split(":")[0]) > selectedHour
              ) {
                return new Array(60).fill(0).map((_, i) => i);
              }
              return [];
            }

            return [];
          },
        })}
        onChange={onChange}
        value={dayjs(value || "09:00", format)}
      />
    </>
  );
}

export default TimeInput;
