export default function Eye({ width, height, fill }) {
  return (
    <svg
      id="__TEMP__SVG__"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "25.13"}
      height={height || "19.461"}
      viewBox="0 0 25.13 19.461"
    >
      <path
        id="Path_713"
        data-name="Path 713"
        d="M20.649,16.325A4.325,4.325,0,1,1,16.325,12,4.325,4.325,0,0,1,20.649,16.325Zm-2.162,0a2.162,2.162,0,1,1-2.162-2.162A2.162,2.162,0,0,1,18.487,16.325Z"
        transform="translate(-3.759 -6.594)"
        fill={fill || "#004e99"}
        fill-rule="evenodd"
      />
      <path
        id="Path_714"
        data-name="Path 714"
        d="M13.132,4.5A12.98,12.98,0,0,1,25.7,14.23a12.978,12.978,0,0,1-25.13,0A12.978,12.978,0,0,1,13.132,4.5Zm0,17.3A10.816,10.816,0,0,1,2.815,14.23a10.816,10.816,0,0,1,20.634,0A10.818,10.818,0,0,1,13.132,21.8Z"
        transform="translate(-0.567 -4.5)"
        fill={fill || "#004e99"}
        fill-rule="evenodd"
      />
    </svg>
  );
}
