import { useState } from "react";
import OutsideClickHandler from "./OutsideClickHandler";
import { twJoin, twMerge } from "tailwind-merge";
import Label from "./Label";

export default function Select({
  label = "Select",
  options = [],
  value = null,
  onChange = () => {},
  className = "",
  tittle = "",
  tittleClass = "",
  icon = null,
  emptyMessage = "No options",
  onEmptyMessageClick = () => {},
  emptyMessageClass = "",
  optionClass = "",
  closeOnSelect = true,
  listOptionClass = "",
  multipleSelect = false,
  onAllClear = () => {},
  disabled = false,
  showTooltip = false,
  tooltipIcon,
  required = false,
  onBlur = () => {},
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div>
        {tittle && (
          <Label
            className={tittleClass}
            label={tittle}
            showTooltip={showTooltip}
            tooltipIcon={tooltipIcon}
            required={required}
          />
        )}
        <div className="relative">
          <button
            type="button"
            className={twMerge(
              "relative w-full cursor-pointer rounded-md bg-white py-1 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:cursor-default disabled:bg-gray-100 disabled:opacity-50",
              className
            )}
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            onClick={() => setOpen(!open)}
            disabled={disabled}
          >
            <span className="flex items-center gap-2">
              {icon}
              <span
                className={twJoin(
                  "block truncate",
                  value !== null ? "text-gray-900" : "text-gray-500"
                )}
              >
                {value !== null || value !== undefined
                  ? multipleSelect
                    ? value.length
                      ? `${options
                          .filter((_) => value.includes(_.value))
                          .map((_) => _.label)
                          .join(", ")}`
                      : label
                    : options?.filter((_) => _.value === value)[0]?.label ??
                      label
                  : label}
              </span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center mr-2">
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4498 6.71289L8.3748 11.6029C7.89355 12.1804 7.10605 12.1804 6.6248 11.6029L2.5498 6.71289"
                  stroke="#818181"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>
          {open && (
            <OutsideClickHandler
              onClickOutside={() => {
                setOpen(false);
                onBlur(value);
              }}
            >
              <ul
                className={twMerge(
                  "absolute z-10 mt-1 max-h-56 w-full overflow-auto custom-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                  optionClass
                )}
                tabIndex="-1"
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3"
              >
                {options.length > 0 ? (
                  options.map((option, index) => (
                    <li
                      className={twMerge(
                        `text-gray-900 relative select-none py-2 pl-3 pr-9 cursor-pointer  ${
                          value === index
                            ? "bg-theme-gray"
                            : "hover:bg-gray-200"
                        }`,
                        listOptionClass
                      )}
                      id="listbox-option-0"
                      role="option"
                      key={index}
                      onClick={() => {
                        onChange(option.value, index);
                        if (closeOnSelect && !multipleSelect) setOpen(false);
                      }}
                    >
                      <div className="flex items-center">
                        <span className="font-normal block">
                          {option.label}
                        </span>
                      </div>
                      {(multipleSelect
                        ? value.includes(option.value)
                        : value === option.value) && (
                        <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-3">
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                      {option.rightElement ? (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                          {option.rightElement}
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                  ))
                ) : (
                  <li
                    className={twJoin(
                      "text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9",
                      emptyMessageClass
                    )}
                    id="listbox-option-0"
                    role="option"
                    onClick={onEmptyMessageClick}
                  >
                    <div className="flex items-center">
                      <span className="font-normal ml-3 block truncate">
                        {emptyMessage}
                      </span>
                    </div>
                  </li>
                )}
                {multipleSelect && value.length > 0 && (
                  <>
                    <hr className="my-2" />
                    <li
                      className={twMerge(
                        `text-gray-900 relative select-none py-2 pl-3 pr-9 cursor-pointer hover:bg-gray-200 `,
                        listOptionClass
                      )}
                      id="listbox-option-0"
                      role="option"
                      key={"clear-all"}
                      onClick={() => {
                        onAllClear();
                      }}
                    >
                      <div className="flex items-center">
                        <span className="font-normal block">Clear All</span>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </OutsideClickHandler>
          )}
        </div>
      </div>
    </>
  );
}
