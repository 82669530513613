export default function ViewIcon({
  width = "20.32",
  height = "20.319",
  fill = "#333",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "20.32"}
      height={height || "20.319"}
      viewBox="0 0 20.32 20.319"
    >
      <path
        id="views_icon"
        data-name="views icon"
        d="M14.424,14.424l-2.14,5.349L8.005,8.005l11.767,4.279Zm0,0,5.349,5.349M6.067.772l.831,3.1M3.872,6.9l-3.1-.831M13.3,2.71,11.031,4.98M4.98,11.031,2.71,13.3"
        transform="translate(-0.16 -0.16)"
        fill="none"
        stroke={fill || "#333"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  );
}
