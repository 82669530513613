import { Routes, Route } from "react-router-dom";
import Dashboard from "section/dashboard";
import AllMajors from "section/major/allMajors";
import AddEditMajor from "section/major/addEditMajor";
import ArchivedMajors from "section/major/archivedMajors";
import Institutions from "section/institutions";
import AddInstitute from "section/institutions/add";
import Keys from "section/keys";
import KeyDetails from "section/keys/KeyDetails";

export default function RightPanel() {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route index element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route path="/majors">
          <Route index element={<AllMajors />} />
          <Route path="archived" element={<ArchivedMajors />} />
          <Route path=":majorId" element={<AddEditMajor />} />
        </Route>
        <Route path="/keys">
          <Route index element={<Keys />} />
          <Route path=":batchId" element={<KeyDetails />} />
        </Route>
        <Route path="/users" element={<h1>Users</h1>} />
        <Route path="/reports" element={<h1>Reports</h1>} />
        <Route path="/insights" element={<h1>Insights</h1>} />
        <Route path="/institutions">
          <Route index element={<Institutions />} />
          <Route path=":institutionId" element={<AddInstitute />} />
        </Route>
      </Routes>
    </>
  );
}
