import { axios } from "helper";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useUtilities } from "helper";
import {
  addCareer,
  setCareers,
  updateSingleCareer,
} from "redux/reducers/careers";

async function getCareer() {
  return axios
    .get("careers")
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetCareer() {
  return useQuery(["careers"], getCareer);
}

export function useCareers() {
  const { data, isLoading, isError, error } = useGetCareer();
  const {
    dispatch,
    careers: { careers, loaded },
  } = useUtilities();

  useEffect(() => {
    if (data && !loaded) {
      dispatch(setCareers(data.data));
    }
  }, [data]);

  return { data: careers, isLoading: isLoading || !loaded, isError, error };
}

async function createCareer({ data, callback }) {
  return await axios
    .post("careers", data)
    .then((res) => {
      res.data.data._id && callback && callback(res.data.data._id, res.data.data);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useAddCareer() {
  const { notification, dispatch } = useUtilities();
  return useMutation(createCareer, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addCareer(data.data));
        notification({
          message: "Career added successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to add career",
          type: "error",
        });
      }
    },
    onError: (err) => {
      notification({
        message: err.message,
        type: "error",
      });
    },
  });
}

async function updateCareer({ id, data }) {
  return await axios
    .put(`careers/${id}`, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateCareer() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateCareer, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleCareer(data.data));
        notification({
          message: "Career updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to update career",
          type: "error",
        });
      }
    },
    onError: (err) => {
      notification({
        message: err.message,
        type: "error",
      });
    },
  });
}

async function addNextLevelCareer({ id, data }) {
  return await axios
    .put(`careers/${id}/add-next-career`, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddNextLevelCareer() {
  const { notification, dispatch } = useUtilities();
  return useMutation(addNextLevelCareer, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleCareer(data.data));
        notification({
          message: "Career updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to update career",
          type: "error",
        });
      }
    },
    onError: (err) => {
      notification({
        message: err.message,
        type: "error",
      });
    },
  });
}

async function getCareersList(careers) {
  return axios
    .get("careers/list", {
      params: {
        careers: JSON.stringify(careers),
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}


export function useGetCareersList(majorID) {
  const { notification, dispatch } = useUtilities();
  return useMutation(getCareersList, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        console.log("major", majorID)
        console.log("data", data)
      } else { 
        notification({
          message: "Failed to get careers",
          type: "error",
        });
      }
    },
    onError: (err) => {
      notification({
        message: err.message,
        type: "error",
      });
    },
  });
}

async function removeNextLevelCareer({ id, data }) {
  return await axios
    .put(`careers/${id}/remove-next-career`, data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useRemoveNextLevelCareer() {
  const { notification, dispatch } = useUtilities();
  return useMutation(removeNextLevelCareer, {
    onSuccess: (data) => {
      if (data.statusCode === 200) {
        dispatch(updateSingleCareer(data.data));
        notification({
          message: "Career updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to update career",
          type: "error",
        });
      }
    },
    onError: (err) => {
      notification({
        message: err.message,
        type: "error",
      });
    },
  });
}