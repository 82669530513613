import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    institutions: [],
    loaded: false,
    selectedInstitution: null,
};

const institutionsSlice = createSlice({
    name: "institutions",
    initialState,
    reducers: {
        setInstitutions(state, action) {
            state.institutions = action.payload;
            state.loaded = true;
        },
        createInstitution(state, action) {
            state.institutions.push(action.payload);
        },
        editInstitution(state, action) {
            const institution = action.payload;
            const index = state.institutions.findIndex((inst) => inst.id === institution.id);
            state.institutions[index] = institution;
        },
        setSelectedInstitution(state, action) {
            state.selectedInstitution = action.payload;
        }
    },
});

export const { setInstitutions, createInstitution, setSelectedInstitution, editInstitution } = institutionsSlice.actions;

export default institutionsSlice.reducer;