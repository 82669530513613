import { Input, MediaInput, MediaPreview } from "common/Input";
import Label from "common/Label";
import Modal from "common/Modal";
import Select from "common/Select";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useEffect, useState, useRef } from "react";
import { useUpdateCourse } from "api/courses";
import { getMediaName, imgLink } from "helper";
import { useUploadFiles } from "api/uploadFiles";
import Loader from "common/Loader";
import { twMerge } from "tailwind-merge";

const courseInitialValue = {
  title: "",
  credits: 0,
  credit_unit: "pt",
  description: "",
  questions: [],
  images: [],
};

export default function EditCourse({
  courseData,
  setCourseData,
  updateCourseState,
}) {
  const { mutateAsync: uploadFiles } = useUploadFiles();
  const { mutateAsync: updateCourse } = useUpdateCourse();
  const [inputValue, setInputValue] = useState(courseInitialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = useRef();
  useEffect(() => {
    if (courseData) {
      setInputValue(courseData);
      setCurrentSlide(0);
    }
    return () => {
      setInputValue(courseInitialValue);
      setCurrentSlide(0);
    };
  }, [courseData]);

  const handleSaveCourse = async () => {
    const newInput = { ...inputValue };
    const questions = await Promise.allSettled(
      newInput.questions.map((question) => {
        if (typeof question.subject_image !== "string") {
          let subject_image = uploadFiles({ data: question.subject_image });
          question.subject_image = subject_image.data?.[0];
        } else {
          question.subject_image = getMediaName(question.subject_image);
        }
        return question;
      })
    );
    newInput.questions = questions?.map((question) => question.value);

    const newImages = newInput.images.filter((_) => typeof _ !== "string");
    if (newImages.length > 0) {
      let images = await uploadFiles({ data: newImages });
      newInput.images = [
        ...newInput.images
          .filter((_) => typeof _ === "string")
          .map((_) => getMediaName(_)),
        ...images.data,
      ];
    } else {
      newInput.images = newInput.images.map((_) => getMediaName(_));
    }

    updateCourse({
      data: newInput,
    })
      .then((res) => {
        setCourseData(null);
        setIsLoading(false);
        if (res.statusCode === 200) {
          updateCourseState(res.data);
        }
      })
      .catch((err) => {
        setCourseData(null);
        setIsLoading(false);
      });
  };

  if (!courseData) return null;

  return (
    <>
      <Loader open={isLoading} />
      <Modal
        open={courseData}
        showFooter={false}
        modalClass="rounded-2xl py-4 max-w-2xl bg-[#FAFAFF] shadow-lg"
        onClose={handleSaveCourse}
      >
        <Input
          className="font-medium text-2xl text-theme-purple capitalize bg-transparent shadow-none border-none pt-0"
          value={inputValue.title}
          onChange={(e) => {
            setInputValue((prev) => ({
              ...prev,
              title: e.target.value,
            }));
          }}
        />
        <div className="flex gap-4">
          <div className="flex-1 border-r-2 border-[#70707070] pr-4 min-w-[300px]">
            <Input
              label="Description"
              value={inputValue.description}
              type="textarea"
              rows={10}
              limit={1000}
              onChange={(e) => {
                setInputValue((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
            />
            <MediaInput
              label="Media"
              text="Drag and drop or browse file (JPG, JPEG, PNG)"
              className="p-[2px]"
              allowedTypes={["png", "jpg", "jpeg"]}
              id="course-media"
              onChange={(value) => {
                setInputValue((prev) => ({
                  ...prev,
                  images: [...prev.images, value],
                }));
              }}
            />
            {inputValue?.images?.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-2">
                {inputValue.images.map((image, index) => (
                  <MediaPreview
                    key={"image-" + index}
                    src={imgLink(image)}
                    className="w-[100px] h-[100px]"
                    onRemove={() => {
                      setInputValue((prev) => ({
                        ...prev,
                        images: prev.images.filter((_, i) => i !== index),
                      }));
                    }}
                  />
                ))}
              </div>
            )}
            <Label label="Credits" className="mt-2" />
            <div className="flex flex-row gap-2">
              <Input
                value={inputValue.credits}
                type="number"
                className="max-w-[80px] shadow-none"
                onChange={(e) => {
                  setInputValue((prev) => ({
                    ...prev,
                    credits: e.target.value,
                  }));
                }}
              />
              <Select
                value={inputValue.credit_unit}
                options={[
                  { value: "pt", label: "Pt" },
                  { value: "hr", label: "Hr" },
                ]}
                className="shadow-none rounded-none py-1.5"
                onChange={(value, index) => {
                  if (index === -1) return;
                  setInputValue((prev) => ({
                    ...prev,
                    credit_unit: value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="relative">
              <Swiper
                className="!w-full max-w-xs !h-fit !pb-5"
                pagination={{ clickable: true }}
                spaceBetween={30}
                modules={[Pagination]}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                onSlideChange={(swiper) => {
                  setCurrentSlide(swiper.activeIndex);
                }}
              >
                {inputValue.questions.map((question, index) => (
                  <SwiperSlide key={"question-" + index}>
                    <QuestionSlide
                      question={question}
                      index={index}
                      setValue={setInputValue}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className={twMerge(
                  "absolute left-0 -bottom-1 text-theme-blue text-sm z-10 cursor-pointer transition-all duration-300",
                  currentSlide === 0 ? " cursor-not-allowed opacity-50" : ""
                )}
                onClick={() => swiperRef.current.slidePrev()}
              >
                Previous question
              </div>
              <div
                className={twMerge(
                  "absolute right-0 -bottom-1 text-theme-blue text-sm z-10 cursor-pointer transition-all duration-300",
                  currentSlide + 1 === inputValue.questions.length
                    ? " cursor-not-allowed opacity-50"
                    : ""
                )}
                onClick={() => swiperRef.current.slideNext()}
              >
                Next question
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function QuestionSlide({ question = {}, setValue, index }) {
  return (
    <>
      <div className="flex flex-col gap-1 mt-2">
        <Input
          label="Subject"
          labelClass="w-max-xs"
          type="textarea"
          className="max-w-xs"
          rows={5}
          limit={500}
          value={question.subject}
          onChange={(e) => {
            setValue((prev) => {
              let questions = [...prev.questions];
              questions[index].subject = e.target.value;
              return {
                ...prev,
                questions,
              };
            });
          }}
        />
        {question.subject_image ? (
          <div className="mx-auto mb-3">
            <MediaPreview
              src={imgLink(question.subject_image)}
              className="!w-[100px] !h-[100px]"
              onRemove={() => {
                setValue((prev) => {
                  let questions = [...prev.questions];
                  questions[index].subject_image = "";
                  return {
                    ...prev,
                    questions,
                  };
                });
              }}
            />
          </div>
        ) : (
          <MediaInput
            id="course-subject-image"
            label="Media"
            iconSize={"30"}
            value={question.subject_image}
            text="Drag and drop or browse file (JPG, JPEG, PNG)"
            className="p-[2px] max-w-[220px] mx-auto mb-3"
            onChange={(value) => {
              setValue((prev) => {
                let questions = [...prev.questions];
                questions[index].subject_image = value;
                return {
                  ...prev,
                  questions,
                };
              });
            }}
            allowedTypes={["png", "jpg", "jpeg"]}
          />
        )}
        <Input
          placeholder="Type a question"
          className="shadow-none rounded-none text-sm mb-2 max-w-xs"
          value={question.question}
          onChange={(e) => {
            setValue((prev) => {
              let questions = [...prev.questions];
              questions[index].question = e.target.value;
              return {
                ...prev,
                questions,
              };
            });
          }}
        />
        {question.options?.map((option, idx) => (
          <Input
            placeholder={`${
              option.is_correct ? "Correct" : "Wrong"
            } answer here`}
            className={`shadow-none rounded-none text-sm border max-w-xs ${
              option.is_correct ? "border-[#94DAAE]" : "border-[#DA9B94]"
            } `}
            value={option.option}
            onChange={(e) => {
              setValue((prev) => {
                let questions = [...prev.questions];
                questions[index].options[idx].option = e.target.value;
                return {
                  ...prev,
                  questions,
                };
              });
            }}
          />
        ))}
      </div>
    </>
  );
}
