export default function EmptyGraphIcon() {
  return (
    <>
      <svg
        id="magicpen"
        xmlns="http://www.w3.org/2000/svg"
        width="164.5"
        height="164.5"
        viewBox="0 0 164.5 164.5"
      >
        <path
          id="Vector"
          d="M4.267,113.933a14.52,14.52,0,0,0,20.563,0l89.1-89.1A14.54,14.54,0,0,0,93.371,4.267l-89.1,89.1A14.52,14.52,0,0,0,4.267,113.933Z"
          transform="translate(19.723 26.577)"
          fill="none"
          stroke="#292d32"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M20.563,20.563,0,0"
          transform="translate(102.881 41.056)"
          fill="none"
          stroke="#292d32"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M10.281,3.016,20.563,0,17.547,10.281l3.016,10.281L10.281,17.547,0,20.563,3.016,10.281,0,0Z"
          transform="translate(47.979 13.708)"
          fill="none"
          stroke="#292d32"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M10.281,3.016,20.563,0,17.547,10.281l3.016,10.281L10.281,17.547,0,20.563,3.016,10.281,0,0Z"
          transform="translate(20.563 54.833)"
          fill="none"
          stroke="#292d32"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M10.281,3.016,20.563,0,17.547,10.281l3.016,10.281L10.281,17.547,0,20.563,3.016,10.281,0,0Z"
          transform="translate(123.375 89.104)"
          fill="none"
          stroke="#292d32"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Vector-6"
          data-name="Vector"
          d="M0,0H164.5V164.5H0Z"
          fill="none"
          opacity="0"
        />
      </svg>
    </>
  );
}
