import { Input } from "common/Input";
import Select from "common/Select";
import { twMerge } from "tailwind-merge";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Label from "common/Label";
import { forwardRef, useEffect, useState } from "react";

export const InstituteInput = forwardRef(
  ({ showTooltip = false, className, labelClass, ...props }, ref) => (
    <Input
      ref={ref}
      showTooltip={showTooltip}
      className={twMerge(
        "shadow-none border-[#828282] min-w-[240px] max-w-[243px] w-full py-[4.5px]",
        className
      )}
      labelClass={twMerge("text-[#818181] text-sm font-light", labelClass)}
      {...props}
    ></Input>
  )
);

export const InstituteSelect = ({
  showTooltip = false,
  className,
  label = "Choose option",
  ...props
}) => (
  <Select
    label={label}
    className={twMerge(
      "shadow-none ring-[#828282] rounded py-[3.5px] min-w-[240px] max-w-[243px] w-full",
      className
    )}
    tittleClass={twMerge("text-[#818181] text-sm font-light", className)}
    showTooltip={showTooltip}
    {...props}
  ></Select>
);

export const CustomPhoneInput = ({
  enableSearch = true,
  country = "us",
  className,
  labelClass,
  label,
  required = false,
  ...props
}) => {
  return (
    <div className="flex flex-col gap-1">
      {label && (
        <Label
          label={label}
          className={twMerge(
            "text-[#818181] text-sm font-light mb-0",
            labelClass
          )}
          showTooltip={false}
          required={required}
        />
      )}
      <PhoneInput
        country={country}
        enableSearch={enableSearch}
        className={className}
        {...props}
      />
    </div>
  );
};

export const CountrySelect = ({ required, value, onChange }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        onChange(data.userSelectValue.value);
      });
  }, []);
  return (
    <InstituteSelect
      tittle="Country"
      required={required}
      options={countries}
      value={value}
      onChange={(selectedOption) => onChange(selectedOption)}
    />
  );
};
