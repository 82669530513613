export default function InstitutionsIcon({size="21", fill="#004E99"}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99973 19.2227H13.9997C17.5172 19.2227 18.1472 17.8139 18.331 16.0989L18.9872 9.09891C19.2235 6.96391 18.611 5.22266 14.8747 5.22266H6.12473C2.38848 5.22266 1.77598 6.96391 2.01223 9.09891L2.66848 16.0989C2.85223 17.8139 3.48223 19.2227 6.99973 19.2227Z"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 5.22266V4.52266C7 2.97391 7 1.72266 9.8 1.72266H11.2C14 1.72266 14 2.97391 14 4.52266V5.22266"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.25 11.3477V12.2402C12.25 13.1939 12.2413 13.9727 10.5 13.9727C8.75875 13.9727 8.75 13.2027 8.75 12.2489V11.3477C8.75 10.4727 8.75 10.4727 9.625 10.4727H11.375C12.25 10.4727 12.25 10.4727 12.25 11.3477Z"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9438 9.59766C16.9716 11.0299 14.6687 11.939 12.25 12.2402"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.29297 9.83398C4.21531 11.1555 6.43272 11.9848 8.75047 12.249"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
