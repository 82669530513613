import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "common/Loader";
import OutlinedButton from "common/Button/OutlinedButton";
import { Input } from "common/Input";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [inputVales, setInputValues] = React.useState({
    email: "",
    error: "",
    loading: false,
  });

  function handleSubmit() {
    setInputValues({ ...inputVales, loading: true });
    fetch(process.env.REACT_APP_SERVER_URL + "users/forget-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inputVales.email,
        platform: navigator?.userAgentData?.platform || "",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setInputValues({ ...inputVales, loading: false });
        if (data.statusCode === 200) {
          sessionStorage.setItem("email", inputVales.email);
          navigate("/user-auth/forget-password/verify")
        } else {
          setInputValues({ ...inputVales, error: data.message });
        }
      })
      .catch(() => {
        setInputValues({
          ...inputVales,
          error: "Something went wrong!",
          loading: false,
        });
      });
  }

  return (
    <>
      <Loader open={inputVales.loading} />
      <div className="flex flex-col max-w-sm w-full">
        <div className="flex flex-col gap-4 mb-6">
          {inputVales.error && (
            <p className="text-red-500 text-xs italic">
              {inputVales.error ?? "Something went wrong!"}
            </p>
          )}
          <Input
            id="email"
            type="email"
            placeholder="Email"
            value={inputVales.email}
            onChange={(e) =>
              setInputValues({ ...inputVales, email: e.target.value })
            }
          />
        </div>
        <div className="flex flex-col gap-3 items-center justify-center w-full">
          <OutlinedButton onClick={handleSubmit}>
            Forget Password
          </OutlinedButton>
          <Link
            className="text-gray-600 underline font-medium py-1 px-4 rounded-full focus:outline-none focus:shadow-outline text-sm"
            to="/user-auth/login"
          >
            Already have an account? Login
          </Link>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
