import Label from "common/Label";
import { InstituteInput, InstituteSelect } from "../CustomInput";
import { CustomRadio, MediaInput, MediaPreview } from "common/Input";
import { useState, cloneElement } from "react";
import { useGetInstituteStaticData } from "api/institution";
import { imgLink, useUtilities } from "helper";
import { useParams } from "react-router-dom";

const requiredFields = [
  "duplicated_majors",
  "duplicated_careers",
  "duplicated_tests",
  "images",
  "renewal_date",
];

export default function Modifications({ footer, updateData, institution }) {
  const { notification } = useUtilities();
  const { data } = useGetInstituteStaticData();
  const { institutionId } = useParams();
  const [inputValues, setInputValues] = useState(
    institutionId !== "add"
      ? {
          duplicated_majors: institution?.duplicated_majors ?? [],
          duplicated_careers: institution?.duplicated_careers ?? [],
          duplicated_tests: institution?.duplicated_tests ?? [],
          majors_career_reflection:
            institution?.majors_career_reflection ?? true,
          majors_reviews: institution?.majors_reviews ?? true,
          careers_video: institution?.careers_video ?? true,
          career_reviews: institution?.career_reviews ?? true,
          ai_consultant: institution?.ai_consultant[0] ?? true,
          easy_apply: institution?.easy_apply ?? true,
          auto_reject: institution?.auto_reject ?? true,
          suggested_applicants: institution?.suggested_applicants ?? true,
          images: institution?.images ?? [],
          renewal_date: institution?.renewal_date ?? "",
        }
      : {
          duplicated_majors: [],
          duplicated_careers: [],
          duplicated_tests: [],
          majors_career_reflection: true,
          majors_reviews: true,
          careers_video: true,
          career_reviews: true,
          ai_consultant: true,
          easy_apply: true,
          auto_reject: true,
          suggested_applicants: true,
          images: [],
          renewal_date: "",
        }
  );

  const handleChangeInput = (key, value) => {
    setInputValues({ ...inputValues, [key]: value });
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    for (const field of requiredFields) {
      if (field === "images") {
        if (!inputValues[field].length || inputValues[field].length < 6) {
          notification({
            type: "ERROR",
            message: "Please upload at least 6 images",
          });
          return;
        }
      }
      if (!inputValues[field]) {
        notification({
          type: "ERROR",
          message: `Please select ${field.split("_").join(" ")} field`,
        });
        return;
      }
    }
    updateData(inputValues);
  };

  const disabledNextButton = () => {
    for (const field of requiredFields) {
      if (field === "images") {
        if (!inputValues[field].length || inputValues[field].length < 6) {
          return true;
        }
      }
      if (!inputValues[field]) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <form onSubmit={handleSubmitData}>
        <div className="flex mb-5">
          <div className="flex-[1.5] border-r border-r-[#CFCFCF] pr-10">
            <div>
              <h2 className="font-medium">Majors Section</h2>
              <div className="flex items-center gap-16 my-5">
                <InstituteSelect
                  tittle="Duplicated Majors"
                  showTooltip
                  tooltipIcon={{
                    width: "18",
                    height: "18",
                    fill: "#828282",
                  }}
                  options={Object.keys(data?.duplicated_majors ?? {}).map(
                    (key) => ({
                      label: data?.duplicated_majors[key],
                      value: key,
                    })
                  )}
                  value={inputValues.duplicated_majors}
                  onChange={(value) => {
                    if (inputValues.duplicated_majors.includes(value)) {
                      handleChangeInput(
                        "duplicated_majors",
                        inputValues.duplicated_majors.filter(
                          (ele) => ele !== value
                        )
                      );
                      return;
                    }
                    handleChangeInput("duplicated_majors", [
                      value,
                      ...inputValues.duplicated_majors,
                    ]);
                  }}
                  multipleSelect
                  onAllClear={() => handleChangeInput("duplicated_majors", [])}
                />
                <div>
                  <Label
                    label="Major's Career Reflection"
                    className="text-[#818181] text-sm font-light"
                    tooltipIcon={{
                      width: "18",
                      height: "18",
                      fill: "#828282",
                    }}
                  />
                  <div className="flex items-center gap-10 mt-2">
                    <CustomRadio
                      label="Yes"
                      name="major-duplicate"
                      checked={inputValues.majors_career_reflection}
                      className="text-[#818181]"
                      id={"major-duplicate-yes"}
                      onChange={() =>
                        handleChangeInput("majors_career_reflection", true)
                      }
                    />
                    <CustomRadio
                      label="No"
                      name="major-duplicate"
                      className="text-[#818181]"
                      checked={!inputValues.majors_career_reflection}
                      id={"major-duplicate-no"}
                      onChange={() => {
                        handleChangeInput("majors_career_reflection", false);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Label
                    label="Major Reviews"
                    className="text-[#818181] text-sm font-light"
                    tooltipIcon={{
                      width: "18",
                      height: "18",
                      fill: "#828282",
                    }}
                  />
                  <div className="flex items-center gap-10 mt-2">
                    <CustomRadio
                      label="Yes"
                      name="major-duplicate"
                      checked={inputValues.majors_reviews}
                      className="text-[#818181]"
                      id={"major-reviews-yes"}
                      onChange={() => handleChangeInput("majors_reviews", true)}
                    />
                    <CustomRadio
                      label="No"
                      name="major-duplicate"
                      className="text-[#818181]"
                      checked={!inputValues.majors_reviews}
                      id={"major-reviews-no"}
                      onChange={() =>
                        handleChangeInput("majors_reviews", false)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <h2 className="font-medium">Careers</h2>
              <div className="flex items-center gap-16 my-5">
                <InstituteSelect
                  tittle="Duplicated Careers"
                  showTooltip
                  tooltipIcon={{
                    width: "18",
                    height: "18",
                    fill: "#828282",
                  }}
                  options={Object.keys(data?.duplicated_careers ?? {}).map(
                    (key) => ({
                      label: data?.duplicated_careers[key],
                      value: key,
                    })
                  )}
                  value={inputValues.duplicated_careers}
                  onChange={(value) => {
                    if (inputValues.duplicated_careers.includes(value)) {
                      handleChangeInput(
                        "duplicated_careers",
                        inputValues.duplicated_careers.filter(
                          (ele) => ele !== value
                        )
                      );
                      return;
                    }
                    handleChangeInput("duplicated_careers", [
                      value,
                      ...inputValues.duplicated_careers,
                    ]);
                  }}
                  multipleSelect
                  onAllClear={() => handleChangeInput("duplicated_careers", [])}
                />
                <div>
                  <Label
                    label="Careers Video"
                    className="text-[#818181] text-sm font-light"
                    tooltipIcon={{
                      width: "18",
                      height: "18",
                      fill: "#828282",
                    }}
                  />
                  <div className="flex items-center gap-10 mt-2">
                    <CustomRadio
                      label="Yes"
                      name="career-video"
                      checked={inputValues.careers_video}
                      className="text-[#818181]"
                      id={"career-video-yes"}
                      onChange={() => handleChangeInput("careers_video", true)}
                    />
                    <CustomRadio
                      label="No"
                      name="career-video"
                      className="text-[#818181]"
                      checked={!inputValues.careers_video}
                      id={"career-video-no"}
                      onChange={() => handleChangeInput("careers_video", false)}
                    />
                  </div>
                </div>
                <div>
                  <Label
                    label="Careers Reviews"
                    className="text-[#818181] text-sm font-light"
                    tooltipIcon={{
                      width: "18",
                      height: "18",
                      fill: "#828282",
                    }}
                  />
                  <div className="flex items-center gap-10 mt-2">
                    <CustomRadio
                      label="Yes"
                      name="career-reviews"
                      checked={inputValues.career_reviews}
                      className="text-[#818181]"
                      id={"career-reviews-yes"}
                      onChange={() => handleChangeInput("career_reviews", true)}
                    />
                    <CustomRadio
                      label="No"
                      name="career-reviews"
                      className="text-[#818181]"
                      checked={!inputValues.career_reviews}
                      id={"career-reviews-no"}
                      onChange={() =>
                        handleChangeInput("career_reviews", false)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <h2 className="font-medium">Personal AI</h2>
              <div className="flex items-center gap-16 my-5">
                <InstituteSelect
                  tittle="Duplicated Tests"
                  showTooltip
                  tooltipIcon={{
                    width: "18",
                    height: "18",
                    fill: "#828282",
                  }}
                  options={Object.keys(data?.personal_ai ?? {}).map((key) => ({
                    label: data?.personal_ai[key],
                    value: key,
                  }))}
                  value={inputValues.duplicated_tests}
                  onChange={(value) => {
                    if (inputValues.duplicated_tests.includes(value)) {
                      handleChangeInput(
                        "duplicated_tests",
                        inputValues.duplicated_tests.filter(
                          (ele) => ele !== value
                        )
                      );
                      return;
                    }
                    handleChangeInput("duplicated_tests", [
                      value,
                      ...inputValues.duplicated_tests,
                    ]);
                  }}
                  multipleSelect
                  onAllClear={() => handleChangeInput("duplicated_tests", [])}
                />
                <div>
                  <Label
                    label="AI Consultant"
                    className="text-[#818181] text-sm font-light"
                    tooltipIcon={{
                      width: "18",
                      height: "18",
                      fill: "#828282",
                    }}
                  />
                  <div className="flex items-center gap-10 mt-2">
                    <CustomRadio
                      label="Yes"
                      name="ai-consultant"
                      checked={inputValues.ai_consultant}
                      className="text-[#818181]"
                      id={"ai-consultant-yes"}
                      onChange={() => handleChangeInput("ai_consultant", true)}
                    />
                    <CustomRadio
                      label="No"
                      name="ai-consultant"
                      className="text-[#818181]"
                      checked={!inputValues.ai_consultant}
                      id={"ai-consultant-no"}
                      onChange={() => handleChangeInput("ai_consultant", false)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <h2 className="font-medium">Applications</h2>
              <div className="flex items-center gap-16 my-5">
                <div>
                  <Label
                    label="Easy Apply"
                    className="text-[#818181] text-sm font-light"
                    tooltipIcon={{
                      width: "18",
                      height: "18",
                      fill: "#828282",
                    }}
                  />
                  <div className="flex items-center gap-10 mt-2">
                    <CustomRadio
                      label="Yes"
                      name="easy-apply"
                      checked={inputValues.easy_apply}
                      className="text-[#818181]"
                      id={"easy-apply-yes"}
                      onChange={() => handleChangeInput("easy_apply", true)}
                    />
                    <CustomRadio
                      label="No"
                      name="easy-apply"
                      className="text-[#818181]"
                      checked={!inputValues.easy_apply}
                      id={"easy-apply-no"}
                      onChange={() => handleChangeInput("easy_apply", false)}
                    />
                  </div>
                </div>
                <div>
                  <Label
                    label="Auto Reject"
                    className="text-[#818181] text-sm font-light"
                    tooltipIcon={{
                      width: "18",
                      height: "18",
                      fill: "#828282",
                    }}
                  />
                  <div className="flex items-center gap-10 mt-2">
                    <CustomRadio
                      label="Yes"
                      name="auto-reject"
                      checked={inputValues.auto_reject}
                      className="text-[#818181]"
                      id={"auto-reject-yes"}
                      onChange={() => handleChangeInput("auto_reject", true)}
                    />
                    <CustomRadio
                      label="No"
                      name="auto-reject"
                      className="text-[#818181]"
                      checked={!inputValues.auto_reject}
                      id={"auto-reject-no"}
                      onChange={() => handleChangeInput("auto_reject", false)}
                    />
                  </div>
                </div>
                <div>
                  <Label
                    label="Suggested Applicants"
                    className="text-[#818181] text-sm font-light"
                    tooltipIcon={{
                      width: "18",
                      height: "18",
                      fill: "#828282",
                    }}
                  />
                  <div className="flex items-center gap-10 mt-2">
                    <CustomRadio
                      label="Yes"
                      name="suggested-applicants"
                      checked={inputValues.suggested_applicants}
                      className="text-[#818181]"
                      id={"suggested-applicants-yes"}
                      onChange={() =>
                        handleChangeInput("suggested_applicants", true)
                      }
                    />
                    <CustomRadio
                      label="No"
                      name="suggested-applicants"
                      className="text-[#818181]"
                      checked={!inputValues.suggested_applicants}
                      id={"suggested-applicants-no"}
                      onChange={() =>
                        handleChangeInput("suggested_applicants", false)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <h2 className="font-medium">Others</h2>
              <div className="my-5">
                <InstituteInput
                  label="Renew Date"
                  type={"date"}
                  value={inputValues.renewal_date?.split("T")[0] ?? ""}
                  onChange={(e) => {
                    let seletedDate = new Date(e.target.value);
                    if (seletedDate < new Date()) {
                      notification({
                        type: "ERROR",
                        message: "Please select a date in the future",
                      });
                      return;
                    }
                    handleChangeInput("renewal_date", e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex-1 pl-10">
            <div className="flex items-center justify-center">
              <MediaInput
                className={"px-6 w-full mt-0 "}
                multiple
                id={"institute-images"}
                allowedTypes={["png", "jpeg", "jpg"]}
                onChange={(value) =>
                  handleChangeInput("images", [...value, ...inputValues.images])
                }
              />
            </div>
            <div className=" flex gap-4 mt-8 justify-center flex-wrap">
              {inputValues.images.map((image) => (
                <MediaPreview
                  url={imgLink(image)}
                  alt="institute-image"
                  className="w-32 h-32"
                  onRemove={() => {
                    const images = inputValues.images.filter(
                      (img) => img !== image
                    );
                    handleChangeInput("images", images);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        {cloneElement(footer, {
          disabledNextButton: disabledNextButton(),
        })}
      </form>
    </>
  );
}
