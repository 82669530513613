import { AddIcon } from "assets/icons";
import { Input, SearchInput } from "common/Input";
import { twJoin } from "tailwind-merge";
import Select from "common/Select";
import Label from "common/Label";
import DailySchedule from "./DailySchedule";
import {
  useAddCareer,
  useAddNextLevelCareer,
  useCareers,
  useUpdateCareer,
  useGetCareersList,
  useRemoveNextLevelCareer,
} from "api/careers";
import { useEffect, useState } from "react";
import {
  useAddCareerToMajor,
  useGetSingleMajor,
  useRemoveCareerFromMajor,
} from "api/major";
import Modal from "common/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { search, useUtilities } from "helper";
import BlockLoader from "common/BlockLoader";
import { useAddCareerCalender, useCareerCalender } from "api/careerCalender";
import RemoveCareer from "./RemoveCareer";

const currencyList = [
  { label: "USD", value: "USD" },
  { label: "EURO", value: "EURO" },
];

const avgPositionDuration = [
  { label: "years", value: "years" },
  { label: "months", value: "months" },
  { label: "weeks", value: "weeks" },
];

export function Career({
  label,
  id,
  active = false,
  main = false,
  onClick = () => {},
  majorID = "",
  level = 0,
  opacity = 1,
  parent = null,
  careers = [],
  setCurrentCareer,
  setCurrentSlide,
  currentSlide,
  index,
  setSlideData,
  currentCarrier,
  slideData,
}) {
  const { notification } = useUtilities();
  const [inputValue, setInputValue] = useState(label);
  const { mutateAsync: updateCareer } = useUpdateCareer();
  const { mutate: addCareer } = useAddCareer();
  const [addNew, setAddNew] = useState("");
  const [newCareer, setNewCareer] = useState("");
  const { data: careersList, isLoading: careersLoading } = useCareers();
  const { mutateAsync: addCareerToMajor } = useAddCareerToMajor();
  const { mutateAsync: addNextlevelCareer } = useAddNextLevelCareer();
  const { mutateAsync: removeCareerFromMajor } =
    useRemoveCareerFromMajor(majorID);
  const { mutateAsync: removeNextLevelCareer } = useRemoveNextLevelCareer();
  const getMajor = useGetSingleMajor();

  useEffect(() => {
    setInputValue(label);
  }, [label]);

  const careerFilter = careersList
    ?.filter(
      (item) =>
        item?.title?.toLowerCase().includes(newCareer.toLowerCase()) &&
        id !== item._id &&
        !careers?.find((ele) => ele._id === item._id)
    )
    .map((item, index) => (
      <p
        className=" px-3 hover:bg-slate-200 py-1 cursor-pointer"
        onClick={() => {
          // TODO: check me
          if (!label) {
            addCareerToMajor({
              data: {
                id: majorID,
                career: item._id,
              },
            }).then(() => onClick(id, level));

            return;
          }
          if (addNew === "new") {
            addCareerToMajor({
              data: {
                id: majorID,
                career: item._id,
              },
            }).then(() => onClick(id, level));
          }
          if (addNew === "next") {
            if (level === 0) {
              addCareerToMajor({
                data: {
                  id: majorID,
                  career: item._id,
                },
              });
            } else {
              addNextlevelCareer({
                id: currentSlide[level - 1],
                data: {
                  career: item._id,
                },
              }).then((res) => {
                setSlideData((pre) => {
                  let tempData = pre.slice(0, level);
                  tempData[level] = [...res.data.next_level_career];
                  return tempData;
                });
              });
            }
          } else if (addNew === "after") {
            addNextlevelCareer({
              id: id,
              data: {
                career: item._id,
              },
            }).then((res) => {
              setSlideData((pre) => [...pre, [item]]);
              setCurrentSlide((pre) => [...pre, item._id]);
              setCurrentCareer(item);
            });
          }
          setAddNew("");
          setNewCareer("");
        }}
      >
        {item.title}
      </p>
    ));

  const handleConfirm = () => {
    if (!newCareer) {
      notification({
        message: "Please enter career title",
        type: "error",
      });
      return;
    }
    if (addNew === "update" && newCareer === label) {
      setAddNew("");
      return;
    }

    if (addNew === "update") {
      updateCareer({
        id: id,
        data: {
          title: newCareer,
        },
      }).then(() => getMajor(majorID));
      setAddNew("");
      return;
    }

    addCareer({
      data: {
        title: newCareer,
      },
      callback: (careerID, newCareerData) => {
        if (addNew === "next") {
          if (level === 0) {
            addCareerToMajor({
              data: {
                id: majorID,
                career: careerID,
              },
            });
          } else {
            addNextlevelCareer({
              id: currentSlide[level - 1],
              data: {
                career: careerID,
              },
            }).then((res) => {
              setSlideData((pre) => {
                let tempData = pre.slice(0, level);
                tempData[level] = [...res.data.next_level_career];
                return tempData;
              });
              // setCurrentSlide((pre) => {
              //   let tempData = pre.slice(0, level);
              //   tempData[level] = newCareerData._id;
              //   return tempData;
              // });
              // setCurrentCareer(newCareerData);
            });
          }
        } else if (addNew === "after") {
          addNextlevelCareer({
            id: id,
            data: {
              career: careerID,
            },
          }).then((res) => {
            setSlideData((pre) => [...pre, [newCareerData]]);
            setCurrentSlide((pre) => [...pre, newCareerData._id]);
            setCurrentCareer(newCareerData);
          });
          // addCareerToMajor({
          //   data: {
          //     id: majorID,
          //     career: careerID,
          //     parent: id,
          //   },
          // }).then(() => onClick(id, level));
        }
        setAddNew("");
        setNewCareer("");
      },
    });
    setAddNew("");
  };

  return (
    <>
      <Modal
        open={addNew !== ""}
        heading={
          addNew === "update" ? label : `What to add ${addNew} ${label ?? ""}?`
        }
        onClose={() => {
          setAddNew("");
          setNewCareer("");
        }}
        onConfirm={handleConfirm}
        disableConfirm={!newCareer}
      >
        <div className="relative">
          <SearchInput
            placeholder="Search or create career"
            value={newCareer}
            onChange={(e) => setNewCareer(e.target.value)}
          />
          {newCareer && addNew !== "update" && (
            <div className="absolute top-full left-0 w-full bg-white rounded-lg shadow-lg py-3 z-10">
              <div className="flex flex-col gap-2 max-h-60 overflow-y-auto custom-scroll">
                {careerFilter.length === 0 ? (
                  <p
                    className=" px-3 hover:bg-slate-200 py-1 cursor-pointer"
                    onClick={handleConfirm}
                  >
                    Add{" "}
                    <span className="text-theme-blue underline">
                      {newCareer}
                    </span>
                  </p>
                ) : (
                  careerFilter
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <div
        className={twJoin(
          "w-20 h-20 rounded-full flex items-center justify-center text-sm text-white mx-auto cursor-pointer",
          active ? "bg-theme-yellow mt-4 " : "bg-[#004E99]"
        )}
        style={{
          scale: active ? "1.3" : "1",
          outline: active ? "8px solid white" : "none",
          opacity: opacity < 0.25 ? 0.25 : opacity,
        }}
        onClick={() => onClick(id)}
      >
        {id && active && opacity === 1 && (
          <RemoveCareer
            onRemove={() => {
              if (level === 0) {
                removeCareerFromMajor({
                  data: {
                    id: majorID,
                    career: id,
                  },
                }).then((res) => {
                  if (res.data.careers?.length === 0) {
                    setCurrentCareer({
                      title: "",
                      description: "",
                      video: "",
                      avg_position_duration: "",
                      avg_position_duration_unit: "",
                      avg_salary: "",
                      salary_currency: "",
                      career_calender: "",
                    });
                    return;
                  } else {
                    let tempData = [...slideData];
                    tempData[level] = tempData[level].filter(
                      (_) => _._id !== id
                    );
                    setCurrentCareer(tempData[level][0]);
                    setSlideData(tempData);
                    setCurrentSlide([tempData[level][0]?._id]);
                  }
                });
              } else {
                removeNextLevelCareer({
                  id: currentSlide[level - 1],
                  data: {
                    career: id,
                  },
                }).then((res) => {
                  if (res.statusCode === 200) {
                    let tempData = [...slideData];
                    tempData[level] = res.data.next_level_career;
                    setCurrentCareer(tempData[level][0]);
                    setSlideData(tempData);
                    setCurrentSlide((pre) => {
                      let tempData = pre.slice(0, level);
                      tempData[level] = res.data.next_level_career[0]._id;
                      return tempData;
                    });
                  }
                });
              }
            }}
          />
        )}
        {id && active && opacity === 1 && (
          <div
            className="absolute -bottom-2.5 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setAddNew("after");
            }}
          >
            <AddIcon />
          </div>
        )}
        {id && active && opacity === 1 && (
          <div
            className="absolute -right-3 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setAddNew("next");
            }}
          >
            <AddIcon />
          </div>
        )}
        <p
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Career Title"
          className={twJoin(
            "bg-transparent text-center w-[inherit] placeholder:text-white placeholder:text-sm truncate",
            main ? "text-2xl" : "text-sm"
          )}
        >
          {inputValue !== "" ? inputValue : "Search or create career"}
        </p>
      </div>
    </>
  );
}

function CareerSlider({
  career,
  majorID = "",
  setCurrentCareer,
  currentCarrier,
}) {
  const [slideData, setSlideData] = useState([career]);
  const [currentSlide, setCurrentSlide] = useState([career?.[0]._id]);
  const { mutateAsync: getCareersList } = useGetCareersList(majorID);

  useEffect(() => {
    setCurrentCareer(career?.[0] ?? {});
    career?.[0]._id && setCurrentSlide([career?.[0]?._id]);
  }, []);

  useEffect(() => {
    let careerData = [career];
    if (currentSlide.length) {
      currentSlide.forEach((item, index) => {
        let tempData = career?.filter((_) => _.parent === item);
        if (tempData.length) {
          careerData.push(tempData);
        }
      });
    }
    setSlideData(careerData);
  }, [career]);

  return (
    <>
      {slideData.map((item, index) => (
        <Swiper
          key={index}
          spaceBetween={50}
          slidesPerView={"auto"}
          className="max-h-[130px] overflow-x-hidden !max-w-[calc(50vw-40px)]"
          pagination={false}
          allowSlideNext
          centeredSlides
          slideToClickedSlide
        >
          {item.map((ele, i) => (
            <SwiperSlide className="w-fit" style={{ width: "fit-content" }}>
              <Career
                label={ele.title}
                id={ele._id}
                onClick={(careerID, level) => {
                  let careerId = careerID ? careerID : ele._id;
                  let dx = level ? level : level === 0 ? level : index;
                  if (dx >= 1) {
                    if (ele.next_level_career.length > 0) {
                      getCareersList(ele.next_level_career).then((res) => {
                        if (res.statusCode === 200) {
                          if (res.data.length > 0) {
                            setSlideData((pre) => {
                              let tempData = pre.slice(0, dx + 1);
                              tempData[dx + 1] = res.data;
                              return tempData;
                            });
                            setCurrentSlide((pre) => {
                              let tempData = pre.slice(0, dx + 1);
                              tempData[dx] = ele._id;
                              tempData[dx + 1] = res.data[0]._id;
                              return tempData;
                            });
                            setCurrentCareer(res.data[0]);
                          }
                        }
                      });
                    } else {
                      setCurrentCareer(ele);
                      setCurrentSlide(() => {
                        let tempData = currentSlide.slice(0, dx);
                        tempData[dx] = ele._id;
                        return tempData;
                      });
                      setSlideData(() => {
                        let tempData = slideData.slice(0, dx + 1);
                        return tempData;
                      });
                    }
                  } else {
                    if (dx === 0 && ele.next_level_career.length === 0) {
                      setCurrentCareer(ele);
                      setCurrentSlide([ele._id]);
                      setSlideData([career]);
                    } else if (dx === 0 && ele.next_level_career.length > 0) {
                      setSlideData([career, [...ele.next_level_career]]);
                      setCurrentSlide([ele._id, ele.next_level_career[0]._id]);
                      setCurrentCareer(ele.next_level_career[0] ?? {});
                    } else {
                      setSlideData((pre) => [
                        ...pre,
                        [...ele.next_level_career],
                      ]);
                      setCurrentSlide((pre) => [
                        ...pre,
                        ele.next_level_career[0]._id,
                      ]);
                      setCurrentCareer(ele.next_level_career[0] ?? {});
                    }
                  }
                }}
                setCurrentCareer={setCurrentCareer}
                setSlideData={setSlideData}
                setCurrentSlide={setCurrentSlide}
                currentCarrier={currentCarrier}
                currentSlide={currentSlide}
                active={currentSlide?.[index] === ele._id}
                opacity={(index + 1) / currentSlide.length}
                majorID={majorID}
                parent={ele._id}
                level={index}
                index={i}
                careers={career}
                next_level_career={ele?.next_level_career}
                getCareer={(key, idx) => {
                  return slideData[idx].filter(
                    (item) => item._id === currentSlide?.[index - 1]
                  )[0];
                }}
                slideData={slideData}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ))}
    </>
  );
}

export default function CareerTab({ majorData }) {
  const { mutateAsync: updateCareer, isLoading: updatingCareer } =
    useUpdateCareer();
  const { mutate: createCareerCalender, isLoading: creatingCareerCalender } =
    useAddCareerCalender();
  const getMajor = useGetSingleMajor();
  const { data: careerCalender } = useCareerCalender(majorData.institute);
  const { data: careersList, isLoading: careersLoading } = useCareers();
  const { mutateAsync: addCareerToMajor } = useAddCareerToMajor();
  const { mutate: addCareer, isLoading: addingCareer } = useAddCareer();

  const [currentCarrier, setCurrentCareer] = useState({
    title: "",
    description: "",
    video: "",
    avg_position_duration: "",
    avg_position_duration_unit: "",
    avg_salary: "",
    salary_currency: "",
    career_calender: "",
  });
  const calender = careerCalender?.filter(
    (ele) => ele._id == currentCarrier?.career_calender
  )[0];

  const [searchCareers, setSearchCareers] = useState([]);

  useEffect(() => {
    if (currentCarrier.title && !currentCarrier._id)
      setSearchCareers(
        careersList
          ?.filter((_) => search(_.title, currentCarrier.title))
          .map((item, index) => (
            <p
              className=" px-3 hover:bg-slate-200 py-1 cursor-pointer"
              onClick={() => {
                if (!currentCarrier._id) {
                  addCareerToMajor({
                    data: {
                      id: majorData._id,
                      career: item._id,
                    },
                  });
                }
              }}
            >
              {item.title}
            </p>
          ))
      );
  }, [currentCarrier.title]);

  return (
    <>
      <div className="flex w-full h-full my-4">
        <div className="flex-1 pr-4 border-r border-r-[#BEBEC0]">
          {majorData?.careers?.length > 0 ? (
            <CareerSlider
              career={majorData?.careers}
              majorID={majorData._id}
              setCurrentCareer={setCurrentCareer}
              currentCarrier={currentCarrier}
            />
          ) : (
            <Career label="" active majorID={majorData._id} careers={[]} />
          )}
        </div>
        <div className="flex-1 flex pl-4 gap-4 relative">
          <BlockLoader open={updatingCareer || creatingCareerCalender} />
          {Object.keys(currentCarrier).length ? (
            <>
              <div className="flex-1 flex flex-col gap-3">
                <div className="relative">
                  <Input
                    placeholder={
                      currentCarrier._id
                        ? "Career Name"
                        : "Search or create career"
                    }
                    label="Title"
                    className="shadow-none"
                    value={currentCarrier.title}
                    onChange={(e) =>
                      setCurrentCareer({
                        ...currentCarrier,
                        title: e.target.value,
                      })
                    }
                    onBlur={(e) => {
                      if (currentCarrier._id) {
                        updateCareer({
                          id: currentCarrier._id,
                          data: {
                            title: currentCarrier.title,
                          },
                        }).then(() => {
                          getMajor(majorData._id);
                        });
                      }
                    }}
                  />
                  {currentCarrier.title && !currentCarrier._id && (
                    <div className="absolute bg-white z-10 max-h-60 custom-scroll shadow w-full">
                      {searchCareers.length ? (
                        searchCareers
                      ) : (
                        <p
                          className=" px-3 hover:bg-slate-200 py-1 cursor-pointer w-full"
                          onClick={() => {
                            if (!currentCarrier._id) {
                              addCareer({
                                data: {
                                  title: currentCarrier.title,
                                },
                                callback: (careerID) => {
                                  addCareerToMajor({
                                    data: {
                                      id: majorData._id,
                                      career: careerID,
                                    },
                                  });
                                },
                              });
                            }
                          }}
                        >
                          +Add {currentCarrier.title}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <Input
                  placeholder="Description"
                  label="Description"
                  className="shadow-none"
                  type="textarea"
                  value={currentCarrier.description}
                  onChange={(e) =>
                    setCurrentCareer({
                      ...currentCarrier,
                      description: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    updateCareer({
                      id: currentCarrier._id,
                      data: {
                        description: currentCarrier.description,
                      },
                    }).then(() => {
                      getMajor(majorData._id);
                    });
                  }}
                  disabled={!currentCarrier._id}
                />
                <Input
                  placeholder="Video link"
                  label="Video"
                  className="shadow-none"
                  value={currentCarrier.video}
                  onChange={(e) =>
                    setCurrentCareer({
                      ...currentCarrier,
                      video: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    updateCareer({
                      id: currentCarrier._id,
                      data: {
                        video: currentCarrier.video,
                      },
                    }).then(() => {
                      getMajor(majorData._id);
                    });
                  }}
                  disabled={!currentCarrier._id}
                />
                <div>
                  <Label label="Duration" />
                  <div className="flex items-center gap-2 w-full">
                    <Input
                      placeholder="Enter Duration"
                      className="shadow-none rounded-none py-1"
                      type="number"
                      value={currentCarrier.avg_position_duration}
                      onChange={(e) =>
                        setCurrentCareer({
                          ...currentCarrier,
                          avg_position_duration: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        updateCareer({
                          id: currentCarrier._id,
                          data: {
                            avg_position_duration:
                              currentCarrier.avg_position_duration,
                          },
                        }).then(() => {
                          getMajor(majorData._id);
                        });
                      }}
                      disabled={!currentCarrier._id}
                    />
                    <Select
                      value={currentCarrier?.avg_position_duration_unit}
                      className="rounded-none"
                      options={avgPositionDuration}
                      onChange={(value, index) => {
                        setCurrentCareer({
                          ...currentCarrier,
                          avg_position_duration_unit: value,
                        });
                        updateCareer({
                          id: currentCarrier._id,
                          data: {
                            avg_position_duration_unit: value,
                          },
                        }).then(() => {
                          getMajor(majorData._id);
                        });
                      }}
                      disabled={!currentCarrier._id}
                    ></Select>
                  </div>
                </div>
                <div>
                  <Label label="Avg. Salary" />
                  <div className="flex items-center gap-2 w-full">
                    <Input
                      placeholder="Enter Salary"
                      className="shadow-none rounded-none py-1"
                      type="text"
                      value={Number(currentCarrier.avg_salary).toLocaleString()}
                      onChange={(e) => {
                        let value = e.target.value?.replace(/,/g, "");
                        if (value) {
                          value =
                            Number(value) ||
                            Number(
                              currentCarrier?.avg_salary?.replace(/,/g, "")
                            );
                        } else {
                          value = 0;
                        }
                        setCurrentCareer({
                          ...currentCarrier,
                          avg_salary: value,
                        });
                      }}
                      onBlur={(e) => {
                        updateCareer({
                          id: currentCarrier._id,
                          data: {
                            avg_salary: Number(currentCarrier.avg_salary),
                          },
                        }).then(() => {
                          getMajor(majorData._id);
                        });
                      }}
                      disabled={!currentCarrier._id}
                    />
                    <Select
                      value={currentCarrier?.salary_currency}
                      className="rounded-none"
                      options={currencyList}
                      onChange={(value, index) => {
                        setCurrentCareer({
                          ...currentCarrier,
                          salary_currency: value,
                        });
                        updateCareer({
                          id: currentCarrier._id,
                          data: {
                            salary_currency: value,
                          },
                        }).then(() => {
                          getMajor(majorData._id);
                        });
                      }}
                      disabled={!currentCarrier._id}
                    ></Select>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                {calender ? (
                  <DailySchedule
                    currentCarrier={currentCarrier}
                    calender={calender}
                    institute={majorData.institute}
                    majorId={majorData._id}
                  />
                ) : (
                  <button
                    className=" float-right text-theme-blue underline"
                    onClick={() => {
                      createCareerCalender({
                        data: {
                          institution: majorData.institute,
                          suitable_to: [majorData._id],
                        },
                        callback: (careerCalenderID) => {
                          updateCareer({
                            id: currentCarrier._id,
                            data: {
                              career_calender: careerCalenderID,
                            },
                          }).then(() => {
                            getMajor(majorData._id);
                          });
                          setCurrentCareer({
                            ...currentCarrier,
                            career_calender: careerCalenderID,
                          });
                        },
                      });
                    }}
                    disabled={!currentCarrier._id}
                  >
                    Add Calender
                  </button>
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
