export default function ArchieveIcon({ fill = "#004E99", size = 26 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8143 10.877V20.0235C20.8143 22.1074 20.293 23.1486 17.6891 23.1486H8.31364C5.70979 23.1473 5.18848 22.106 5.18848 20.0235V10.877"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.7085 2.3138H20.2917C20.7162 2.25327 21.149 2.29237 21.5558 2.42802C21.9625 2.56367 22.3321 2.79214 22.6353 3.09534C22.9386 3.39855 23.167 3.76816 23.3027 4.17493C23.4383 4.58171 23.4774 5.01447 23.4169 5.43897V7.52152C23.4774 7.94602 23.4383 8.37878 23.3027 8.78555C23.167 9.19233 22.9386 9.56194 22.6353 9.86515C22.3321 10.1683 21.9625 10.3968 21.5558 10.5325C21.149 10.6681 20.7162 10.7072 20.2917 10.6467H5.7085C5.284 10.7072 4.85124 10.6681 4.44447 10.5325C4.03769 10.3968 3.66808 10.1683 3.36487 9.86515C3.06167 9.56194 2.8332 9.19233 2.69755 8.78555C2.56191 8.37878 2.5228 7.94602 2.58333 7.52152V5.43897C2.5228 5.01447 2.56191 4.58171 2.69755 4.17493C2.8332 3.76816 3.06167 3.39855 3.36487 3.09534C3.66808 2.79214 4.03769 2.56367 4.44447 2.42802C4.85124 2.29237 5.284 2.25327 5.7085 2.3138Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.1045 14.8135H14.8965"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
