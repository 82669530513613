import { axios } from "helper";
import axiosInstance from "helper/axios";
import { useSelectedInstitution } from "hooks/useInstitute";
import { useUser } from "hooks/useUser";

export async function logout() {
  return axios.post("/users/logout").then((res) => res.data);
}

export async function switchSession({ id, session_id, token, tenantId }) {
  return fetch(process.env.REACT_APP_SERVER_URL + "users/switch-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      "x-tenant": tenantId,
    },
    body: JSON.stringify({ id, session_id }),
  }).then((res) => res.json());
}

export const useUserInstitute = () => {
  const selectedInstitution = useSelectedInstitution();
  const { data: userData } = useUser();
  const institutionId = selectedInstitution?._id || userData?.institution?._id;
  return institutionId;
};

export async function getProfile() {
  return axiosInstance.get(
    "/users/get-profile/" + localStorage.getItem("session")
  )
  .then(res => res.data)
  .catch(err =>  {throw Error(err.message)})
}
