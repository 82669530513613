import React from "react";
import ReactDOM from "react-dom";

const Loader = ({ open = false }) => {
  const loader = (
    <div className="fixed top-0 left-0 z-[60] h-screen w-screen flex items-center justify-center bg-slate-600 bg-opacity-80">
      <span className="loader"></span>
    </div>
  );

  return open
    ? ReactDOM.createPortal(loader, document.body)
    : null;
};

export default Loader;
