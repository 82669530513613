import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  schedule: [],
  loaded: false,
};

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    setSchedule(state, action) {
      state.schedule = action.payload;
      state.loaded = true;
    },
    addSchedule(state, action) {
      state.schedule.push(action.payload);
    },
    updateSingleSchedule(state, action) {
      const index = state.schedule.findIndex(
        (schedule) => schedule._id === action.payload._id
      );
      state.schedule[index] = action.payload;
    },
    deleteSchedule(state, action) {
      state.schedule = state.schedule.filter(
        (schedule) => schedule._id !== action.payload
      );
    },
  },
});

export const {
  setSchedule,
  addSchedule,
  updateSingleSchedule,
  deleteSchedule,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
