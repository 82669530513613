export default function MedalStarIcon({ size = "20", fill = "#292D32" }) {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8337 7.49935C15.8337 8.70768 15.4753 9.81603 14.8587 10.741C13.9587 12.0744 12.5337 13.016 10.8753 13.2577C10.592 13.3077 10.3003 13.3327 10.0003 13.3327C9.70033 13.3327 9.40866 13.3077 9.12533 13.2577C7.46699 13.016 6.04199 12.0744 5.14199 10.741C4.52533 9.81603 4.16699 8.70768 4.16699 7.49935C4.16699 4.27435 6.77533 1.66602 10.0003 1.66602C13.2253 1.66602 15.8337 4.27435 15.8337 7.49935Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.7082 15.3917L16.3332 15.7167C16.0249 15.7917 15.7832 16.025 15.7166 16.3333L15.4249 17.5583C15.2666 18.225 14.4166 18.425 13.9749 17.9L9.9999 13.3333L6.0249 17.9083C5.58324 18.4333 4.73324 18.2333 4.5749 17.5667L4.28324 16.3417C4.20824 16.0333 3.96657 15.7917 3.66657 15.725L2.29157 15.4C1.65824 15.25 1.43324 14.4583 1.89157 14L5.14157 10.75C6.04157 12.0833 7.46657 13.025 9.1249 13.2667C9.40824 13.3167 9.6999 13.3417 9.9999 13.3417C10.2999 13.3417 10.5916 13.3167 10.8749 13.2667C12.5332 13.025 13.9582 12.0833 14.8582 10.75L18.1082 14C18.5666 14.45 18.3416 15.2417 17.7082 15.3917Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.4836 4.98398L10.9753 5.96731C11.042 6.10064 11.217 6.23398 11.3753 6.25898L12.267 6.40898C12.8336 6.50064 12.967 6.91732 12.5586 7.32565L11.867 8.0173C11.7503 8.13397 11.6836 8.35898 11.7253 8.52565L11.9253 9.38398C12.0836 10.059 11.7253 10.3256 11.1253 9.9673L10.292 9.47564C10.142 9.38397 9.89197 9.38397 9.74197 9.47564L8.90864 9.9673C8.30864 10.3173 7.9503 10.059 8.10864 9.38398L8.30864 8.52565C8.34197 8.36732 8.28364 8.13397 8.16697 8.0173L7.4753 7.32565C7.06697 6.91732 7.2003 6.50898 7.76697 6.40898L8.65864 6.25898C8.80864 6.23398 8.98364 6.10064 9.0503 5.96731L9.54197 4.98398C9.78364 4.45065 10.217 4.45065 10.4836 4.98398Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
