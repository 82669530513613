export default function InfoIcon({ fill = "#F29423", width, height }) {
  return (
    <svg
      id="Group_253"
      data-name="Group 253"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "21"}
      height={height || "27"}
      viewBox="0 0 21 27"
    >
      <g
        id="Ellipse_1"
        data-name="Ellipse 1"
        transform="translate(0 2)"
        fill="none"
        stroke={fill || "#c2c2c2"}
        stroke-width="1"
      >
        <circle cx="10.5" cy="10.5" r="10.5" stroke="none" />
        <circle cx="10.5" cy="10.5" r="10" fill="none" />
      </g>
      <text
        id="i"
        transform="translate(8 19)"
        fill={fill || "#c2c2c2"}
        font-size="18"
        font-family="Heebo-Medium, Heebo"
        font-weight="500"
      >
        <tspan x="0" y="0">
          i
        </tspan>
      </text>
    </svg>
  );
}
