import BreadCrumbs from "common/BreadCrumbs";
import FormSteps from "./FormSteps";
import { useState } from "react";
import AddInstituteSteps from "./steps";
import FormFooter from "./steps/FormFooter";
import { useUploadFiles } from "api/uploadFiles";
import Loader from "common/Loader";
import {
  useAddInstitute,
  useInstitutions,
  useUpdateInstitute,
} from "api/institution";
import { useParams } from "react-router-dom";
import { getMediaName } from "helper";

export default function AddInstitute() {
  const { mutateAsync: uploadFiles } = useUploadFiles();
  const { mutateAsync: addInstituteApi, isLoading: isAdding } =
    useAddInstitute();
  const { mutateAsync: updateInstitute, isLoading: isUpdating } =
    useUpdateInstitute();
  const { institutionId } = useParams();
  const { data: institutions } = useInstitutions();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [inputValues, setInputValues] = useState({});
  const handleFormSubmit = async (data) => {
    const newInputValues = { ...inputValues, ...data };
    if (step < 2) {
      setStep(step + 1);
      setInputValues(newInputValues);
      return;
    }
    if (step === 2) {
      if (institutionId === "add") {
        setIsLoading(true);
        setInputValues(newInputValues);
        const [images, cover_image, profile_image] = await Promise.allSettled([
          uploadFiles({ data: newInputValues.images }),
          uploadFiles({ data: newInputValues.cover_image }),
          uploadFiles({ data: newInputValues.profile_image }),
        ]);
        images.value && (newInputValues.images = images.value.data);
        cover_image.value &&
          (newInputValues.cover_image = cover_image.value.data[0]);
        profile_image.value &&
          (newInputValues.profile_image = profile_image.value.data[0]);

        if (newInputValues?.terms_file) {
          const terms_file = await uploadFiles({
            data: newInputValues.terms_file,
          });
          newInputValues.terms_file = terms_file.data[0];
        }

        if (newInputValues?.policy_file) {
          const policy_file = await uploadFiles({
            data: newInputValues.policy_file,
          });
          newInputValues.policy_file = policy_file.data[0];
        }

        setIsLoading(false);
        addInstituteApi(newInputValues);
      } else {
        setIsLoading(true);
        if (typeof newInputValues.profile_image !== "string") {
          const profile_image = await uploadFiles({
            data: newInputValues.profile_image,
          });
          newInputValues.profile_image = profile_image.data[0];
        } else {
          newInputValues.profile_image = getMediaName(
            newInputValues.profile_image
          );
        }

        if (typeof newInputValues.cover_image !== "string") {
          const cover_image = await uploadFiles({
            data: newInputValues.cover_image,
          });
          newInputValues.cover_image = cover_image.data[0];
        } else {
          newInputValues.cover_image = getMediaName(newInputValues.cover_image);
        }

        let newImages = newInputValues.images?.filter(
          (ele) => typeof ele !== "string"
        );
        let oldImages = newInputValues.images
          ?.filter((ele) => typeof ele === "string")
          .map((ele) => getMediaName(ele));

        if (newImages?.length) {
          const images = await uploadFiles({
            data: newImages,
          });
          newInputValues.images = [...oldImages, ...images.data];
        } else {
          newInputValues.images = oldImages;
        }

        if (
          newInputValues?.terms_file &&
          typeof newInputValues.terms_file !== "string"
        ) {
          const terms_file = await uploadFiles({
            data: newInputValues.terms_file,
          });
          newInputValues.terms_file = terms_file.data[0];
        }

        if (
          newInputValues?.policy_file &&
          typeof newInputValues.policy_file !== "string"
        ) {
          const policy_file = await uploadFiles({
            data: newInputValues.policy_file,
          });
          newInputValues.policy_file = policy_file.data[0];
        }

        setIsLoading(false);
        updateInstitute({ id: institutionId, data: newInputValues });
      }
    }
  };
  return (
    <>
      <Loader open={isLoading || isAdding || isUpdating} />
      <BreadCrumbs
        data={[
          {
            link: "/institutions",
            label: "Institutions",
            style: { color: "#BBC4FF" },
          },
          {
            link: `/institutions/${
              institutionId !== "add" ? institutionId : "add"
            }`,
            label:
              institutionId !== "add"
                ? "Update institution"
                : "Create new institution",
          },
        ]}
      />
      <div className="my-8">
        <FormSteps
          data={[
            {
              label: "Basic Information",
            },
            {
              label: "Modifications",
            },
            {
              label: "Create",
            },
          ]}
          step={step}
        />
      </div>
      <AddInstituteSteps
        step={step}
        footer={
          <FormFooter
            hideBack={step === 0}
            onBack={() => setStep(step - 1)}
            nextText={
              step === 2
                ? institutionId !== "add"
                  ? "Save"
                  : "Create"
                : "Next"
            }
          />
        }
        updateData={handleFormSubmit}
        institution={institutions?.find((item) => item._id === institutionId)}
      />
    </>
  );
}
