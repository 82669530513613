export default function CallIcon({
  width = "20",
  height = "21",
  stroke = "1.5",
  fill = "#292D32",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6461 15.6686C18.6448 15.9958 18.5707 16.3186 18.4291 16.6136C18.2776 16.936 18.0787 17.234 17.8391 17.4976C17.4484 17.9399 16.9625 18.2879 16.4181 18.5156C15.882 18.7368 15.3071 18.8487 14.7271 18.8446C13.752 18.8265 12.7907 18.6111 11.9011 18.2116C10.8455 17.7541 9.8451 17.1784 8.91911 16.4956C7.91168 15.759 6.961 14.9477 6.07511 14.0686C5.19856 13.188 4.38992 12.2423 3.65611 11.2396C2.98216 10.3204 2.41203 9.32933 1.95611 8.28463C1.56089 7.39117 1.34904 6.42747 1.33311 5.45063C1.32931 4.87816 1.43521 4.31025 1.64511 3.77763C1.86662 3.2269 2.20826 2.73255 2.64511 2.33063C2.87551 2.08147 3.15349 1.88098 3.46264 1.741C3.7718 1.60103 4.10585 1.52439 4.44511 1.51563C4.68713 1.51502 4.92625 1.56831 5.14511 1.67163C5.38183 1.77638 5.58523 1.94415 5.73311 2.15663L7.74011 4.99063C7.8793 5.17857 7.99578 5.38232 8.08711 5.59763C8.16233 5.76412 8.20348 5.94399 8.20811 6.12663C8.20559 6.34453 8.14258 6.55745 8.02611 6.74163C7.8922 6.96735 7.72876 7.17418 7.54011 7.35663L6.88111 8.04163C6.83514 8.08667 6.79905 8.14078 6.77512 8.20051C6.75119 8.26025 6.73995 8.32431 6.74211 8.38863C6.74216 8.45614 6.7509 8.52335 6.76811 8.58863C6.79411 8.65763 6.82011 8.70963 6.83711 8.76163C7.06985 9.15612 7.33974 9.52747 7.64311 9.87063C8.03311 10.3216 8.44911 10.7806 8.90011 11.2396C9.36811 11.6986 9.81911 12.1236 10.2781 12.5136C10.6222 12.8182 10.9968 13.0863 11.3961 13.3136C11.4391 13.3306 11.4961 13.3566 11.5521 13.3826C11.6217 13.4076 11.6952 13.4194 11.7691 13.4176C11.8352 13.4191 11.9009 13.4068 11.9621 13.3815C12.0232 13.3561 12.0784 13.3184 12.1241 13.2706L12.7831 12.6206C12.9658 12.4288 13.1761 12.2653 13.4071 12.1356C13.5911 12.0179 13.8047 11.9548 14.0231 11.9536C14.2052 11.9561 14.3849 11.9945 14.5521 12.0666C14.7656 12.158 14.969 12.2712 15.1591 12.4046L18.0291 14.4416C18.2374 14.5778 18.4028 14.7702 18.5061 14.9966C18.596 15.2094 18.6435 15.4377 18.6461 15.6686Z"
        stroke={fill}
        stroke-width={stroke}
      />
    </svg>
  );
}
