import { SearchInput } from "./Input";
import { SortIcon } from "assets/icons";
import { useState } from "react";
import OutsideClickHandler from "./OutsideClickHandler";
import { FilledButton } from "./Button";

export default function SearchFilter({
  searchValue = "",
  onChangeSearchValue = () => {},
  searchResults = <></>,
  filterData = <></>,
  onFilter = () => {},
  onClear = () => {},
  placeholder = "Search",
  options = true,
}) {
  const [searchFocused, setSearchFocused] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  return (
    <>
      <div className="flex items-center gap-7">
        <div className="relative">
          <SearchInput
            placeholder={placeholder}
            value={searchValue}
            onChange={(e) => onChangeSearchValue(e.target.value)}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => setTimeout(() => setSearchFocused(false), 400)}
          />
          {searchFocused && searchValue.trim() && (
            <div className="absolute top-12 left-0 w-full bg-white shadow rounded-xl z-10 max-h-72 custom-scroll">
              {searchResults && searchResults.length > 0 ? (
                searchResults
              ) : (
                <div className="flex items-center justify-between px-4 py-2 border-b border-[#E4E4E4] cursor-pointer hover:bg-[#F7F7F7]">
                  <p className="text-base">No results found</p>
                </div>
              )}
            </div>
          )}
        </div>
        {options ? (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => setShowFilter(true)}
          >
            <SortIcon />
            <p className="font-bold text-theme-blue">Options</p>
          </div>
        ) : (
          ""
        )}
        <OutsideClickHandler
          className={`fixed h-screen max-w-sm w-full bg-white z-10 top-0 shadow-lg transition-all ease-in-out duration-500
            ${showFilter ? "right-0" : "-right-96"}`}
          coverOrigin={false}
          onClickOutside={() => setShowFilter(false)}
        >
          <div className="p-5">
            <div className="flex items-center justify-between">
              <p
                className="underline text-gray-400 cursor-pointer text-sm"
                onClick={() => setShowFilter(false)}
              >
                Cancel
              </p>
              <h3>Filters</h3>
              <FilledButton
                className="px-3 text-sm py-1 rounded"
                onClick={() => {
                  setShowFilter(false);
                  onFilter();
                }}
              >
                Apply
              </FilledButton>
            </div>
            <div className="flex flex-col gap-4 mt-4">{filterData}</div>
            <div className="flex items-center justify-end mt-4">
              <p
                className="underline text-gray-400 cursor-pointer text-sm"
                onClick={() => {
                  setShowFilter(false);
                  onClear();
                }}
              >
                Clear All
              </p>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
}
