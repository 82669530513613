export default function UploadIcon({ size = "48.811" }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size || "48.811"}
        height={size || "48.811"}
        viewBox="0 0 48.811 48.811"
      >
        <g
          id="vuesax_linear_send-square"
          data-name="vuesax/linear/send-square"
          transform="translate(-112 -448.186)"
          opacity="0.3"
        >
          <g id="send-square" transform="translate(112 448.186)">
            <path
              id="Vector"
              d="M14.236,40.675h12.2c10.169,0,14.236-4.068,14.236-14.236v-12.2C40.675,4.068,36.608,0,26.439,0h-12.2C4.068,0,0,4.068,0,14.236v12.2C0,36.608,4.068,40.675,14.236,40.675Z"
              transform="translate(4.068 4.068)"
              fill="none"
              stroke="#f29423"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <g id="Group" transform="translate(18.304 13.24)">
              <path
                id="Vector-2"
                data-name="Vector"
                d="M0,6.1,6.1,0l6.1,6.1"
                fill="none"
                stroke="#f29423"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Vector-3"
                data-name="Vector"
                d="M0,0V16.27"
                transform="translate(6.101)"
                fill="none"
                stroke="#f29423"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0A38.538,38.538,0,0,0,24.405,0"
              transform="translate(12.203 33.578)"
              fill="none"
              stroke="#f29423"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M0,0H48.811V48.811H0Z"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
