export default function ReportsIcon({ width = "16", height = "20" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 20"
    >
      <path
        id="reports_icon"
        data-name="reports icon"
        d="M5,3H3A2,2,0,0,0,1,5V17a2,2,0,0,0,2,2H13a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2H11M5,3A2,2,0,0,0,7,5H9a2,2,0,0,0,2-2M5,3A2,2,0,0,1,7,1H9a2,2,0,0,1,2,2M8,10h3M8,14h3M5,10h.01M5,14h.01"
        fill="none"
        stroke="#004e99"
        stroke-linecap="round"
        stroke-width="2"
      />
    </svg>
  );
}
